export enum LicenseClassFlags {
  NONE = 0,
  AM = 1 << 0, // 1
  A1 = AM | 1 << 1, // 3
  A2 = A1 | 1 << 2, // 7
  A = A2 | 1 << 3, // 15
  B1 = AM | 1 << 4, // 17
  B = B1 | 1 << 5  // 49
}

export class ClassesManager {
  static ALL: LicenseClassFlags = ~(~0 << 6);

  static isType(inputFlag: number, category: number): boolean {
    return (inputFlag & category) === inputFlag;
  }
}
