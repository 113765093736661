import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavParams, PopoverController,
  NavController, Platform, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


// BS modules
import { Zone} from './../../classes';

// BS service providers
import { SettingsmanagerService } from './../../services/settingsmanager.service';
import { ZoneService } from 'src/app/services/zone.service';

declare const google;


@Component({
  selector: 'app-zonedetail',
  templateUrl: './zonedetail.page.html',
  styleUrls: ['./zonedetail.page.scss'],
})
export class ZonedetailPage implements OnInit {


  public isSpinnerVisible  = false;

  public  zone: Zone = null;
  public zoneForm: FormGroup;
  public validation_messages = null;
  public currentZoneId = null;
  public currentCityId = null;
  public cities = null;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private navCtrl: NavController,
    private formBuilder: FormBuilder,
    private _zoneSvc: ZoneService,
    private toastController: ToastController,
    public alertController: AlertController,
    private popoverController: PopoverController
  ) {
    console.log('@contructor-ZONE DETAIL');
  }

  ngOnInit() {
    console.log('@ngOnInitZONE DETAIL');
    // Retrieving page parameters
    const zoneString = this.navParams.get('zone');
    const zoneCoordinatesString = this.navParams.get('zone_coordinates') || '';
    this.currentZoneId = this.navParams.get('zone_id');
    this.currentCityId = this.settingsManager.getCitySetting();
    this.cities = this.settingsManager.getCitiesOptions();

    // console.log('-->zoneString:', zoneString);

    // Creating the Zone Object
    if (this.currentZoneId) {
      this.zone = new Zone (JSON.parse(zoneString));
      console.log('this.currentZoneId this.zone: ', this.zone)
      this.zone.commandTypeId = this.zone.commandTypeId ? this.zone.commandTypeId : 0;
    } else {
      this.zone = new Zone();
      this.zone.coordinates = zoneCoordinatesString;
      this.zone.park_enabled = true;
      this.zone.free_unlock = false;
      this.zone.isVisible = false;
      this.zone.warning_chiusura_attivo = false;
    }

    let cityValue;
    if (this.currentCityId) {
      cityValue = Number(this.currentCityId) || '';
    } else {
      cityValue = Number(this.zone.cityId) || '';
    }

    console.log('-->zoneObject:', this.zone, cityValue, this.zone.area);

    // Calcolo l'area della zona se non è presente
    if ((!this.zone.area || (this.zone.area === 0)) && this.zone.coordinates) {
      const zoneArea = this.getZoneArea(this.zone.coordinates);
      this.zone.area = zoneArea ? zoneArea : 0;
    }


    // default values
    const free_unlock_string = this.zone.free_unlock ? 'true' : 'false' ;
    const park_enabled_string = this.zone.park_enabled ? 'true' : 'false' ;
    const isVisible_string = this.zone.isVisible ? 'true' : 'false' ;
    const warning_string = this.zone.warning_chiusura_attivo ? 'true' : 'false' ;
    const command_type_string = this.zone.commandTypeId ? String(this.zone.commandTypeId) : '';
    const zone_area = this.zone.area ? this.zone.area : 0;

    console.log('@@@@isVisible_string:', isVisible_string);

    /* Zone Form report */
    this.zoneForm = this.formBuilder.group({
      id: [this.zone.id || null, Validators.compose([  ])],
      coordinates: [this.zone.coordinates || '', Validators.compose([ Validators.required ])],
      name: [this.zone.name || '', Validators.compose([ Validators.required, Validators.maxLength(128), ])],
      description: [this.zone.description || '', Validators.compose([ Validators.required, Validators.maxLength(512), ])],
      cityId: [cityValue, Validators.compose([ Validators.required, ])],
      commandTypeId: [command_type_string, Validators.compose([ Validators.required, ])],
      park_enabled: [park_enabled_string, Validators.compose([ Validators.required, ])],
      free_unlock: [free_unlock_string, Validators.compose([ Validators.required, ])],
      isVisible: [isVisible_string, Validators.compose([ Validators.required, ])],
      warning_chiusura_attivo: [warning_string, Validators.compose([ Validators.required, ])],
      discount_on_total: [this.zone.discount_on_total || 0, Validators.compose([ Validators.required, ])],
      discount_on_fee: [this.zone.discount_on_fee || 0, Validators.compose([ Validators.required, ])],
      color: [this.zone.color || 'ABABAB', Validators.compose([ Validators.required, Validators.minLength(6), Validators.maxLength(6), ])],
      area: [zone_area, Validators.compose([  ])],
      categoryFlags: [this.zone.categoryFlags || 0, Validators.compose([Validators.min(1)])],
      zIndex: [this.zone.zIndex || 1, Validators.compose([ Validators.required, ])],
    });
    this.validation_messages = {
      'coordinates': [
        {type: 'required', message: 'This field is required.'}
      ],
      'name': [
        {type: 'required', message: 'This field is required.'},
        {type: 'maxlength', message: 'This field must have at most 128 characters.'},
      ],
      'description': [
        {type: 'required', message: 'This field is required.'},
        {type: 'maxlength', message: 'This field must have at most 512 characters.'},
      ],
      'cityId': [
        {type: 'required', message: 'This field is required.'},
      ],
      'commandTypeId': [
      {type: 'required', message: 'This field is required.'},
      ],
      'park_enabled': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_total': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'discount_on_fee': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'free_unlock': [
        {type: 'required', message: 'This field is required.'},
      ],
      'isVisible': [
        {type: 'required', message: 'This field is required.'},
      ],
      'warning_chiusura_attivo': [
        {type: 'required', message: 'This field is required.'},
      ],
      'color': [
        {type: 'required', message: 'This field is required.'},
        {type: 'minlength', message: 'This field must have just 6 characters.'},
        {type: 'maxlength', message: 'This field must have just 6 characters.'},
      ],
      'validity_flag': [
        { type: 'required', message: 'subscription_registry.validity_flag_required' },
      ],
      'categoryFlags': [
        { type: 'required', message: 'This field is required.'},
      ],
      'zIndex': [
        { type: 'required', message: 'This field is required.'},
      ]
    };
  }


  private getZoneArea(coordinates) {
    let zoneArray = [];
    const areaPathArray = [];
    // Separo i vertici del poligopno
    zoneArray = coordinates.split(';');
    // console.log('@@@@zoneArray:', zoneArray);
    zoneArray.forEach(element => {
      // Separo le coordinate dei vertici del poligono
      const arr = element.split(',');
      areaPathArray.push(new google.maps.LatLng(arr[0], arr[1]));
    });
    // console.log('@@@@areaPathArray:', areaPathArray);
    const zoneArea = google.maps.geometry.spherical.computeArea(areaPathArray).toFixed(2);
    console.log('@@@@CALCOLA AREA --> getZoneArea:', zoneArea);
    return zoneArea;
  }

  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async closePopover(reloadParent = false) {
    await this.popoverController.dismiss(reloadParent);
  }

  async onSubmit(values) {
    console.log('@@ ZONE submit: ', values);
    console.log('@@ ZONE submit zoneForm: ', this.zoneForm);
    console.log('@@ ZONE submit zoneForm.controls: ', this.zoneForm.controls);
    this.showSpinner();
    // Ricalcolo l'area della zona perché potrebbe essere cambiata
    if (values['coordinates']) {
      const zoneArea = this.getZoneArea(values['coordinates']);
      values['area'] = zoneArea ? zoneArea : 0;
    }
    if (this.currentZoneId) {
      // EDIT
      this._zoneSvc.edit(this.currentZoneId, values)
        .subscribe((response) => {
            console.log('onSubmit OK', response);
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Zone edited successfully.');
            this.showMessage('success', actiondMsg);
            this.closePopover(true);
          },
          (err) => {
            console.log('onSubmit KO', err.status, err);
            this.hideSpinner();
            let actiondMsg;
            if (err.status === 402) {
              actiondMsg = this.translate.instant('Wrong coordinates. This zone overlaps another zone.');
            } else {
              actiondMsg = this.translate.instant('Error editing the zone.');
            }
            this.showMessage('danger', actiondMsg);
          });
    } else {
      this._zoneSvc.create(values)
        .subscribe(() => {
            // ADD
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Zone added successfully.');
            this.showMessage('success', actiondMsg);
            this.closePopover(true);
          },
          (err) => {
            this.hideSpinner();
            let actiondMsg;
            if (err.status === 402) {
              actiondMsg = this.translate.instant('Wrong coordinates. This zone overlaps another zone.');
            } else if (err.status === 403) {
              actiondMsg = this.translate.instant('The zone is outside the city.');
            } else {
              actiondMsg = this.translate.instant('Error creating the zone.');
            }
            this.showMessage('danger', actiondMsg);
          });
    }
  }

  async showMessage(messageSeverity: string, messageText: string, messageDuration = 2000): Promise<void> {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: true,
      color: messageSeverity,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }

  deleteZone() {
    this.presentAlertConfirm(this.currentZoneId);
  }

  async presentAlertConfirm(zoneId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Delete zone');
            this._zoneSvc.delete(zoneId).subscribe(() => {
              const msgOK = this.translate.instant('Zone removed successfully.');
              this.showMessage('success', msgOK);
              this.closePopover(true);
            }, () => {
              const msgKO = this.translate.instant('Error removing the zone.');
              this.showMessage('danger', msgKO);
            });
          }
        }
      ]
    });

    await alert.present();
  }

}
