import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavParams, PopoverController,
  NavController, Platform, ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// BS modules
import {Hub, Zone} from '../../classes';

// BS service providers
import { SettingsmanagerService } from '../../services/settingsmanager.service';
import { WsmanagerService } from '../../services/wsmanager.service';
import { HubService } from 'src/app/services/hub.service';

const HUB_TYPE_CIRCLE = 'Circle';
const HUB_TYPE_POLYGON = 'Polygon';

@Component({
  selector: 'app-hub-editor',
  templateUrl: './hub-editor.component.html',
  styleUrls: ['./hub-editor.component.scss'],
})
export class HubEditorComponent implements OnInit {

  public isSpinnerVisible  = false;

  public hub: Hub = null;
  public hubForm: FormGroup;
  public newHub =  false;
  public validation_messages = null;
  public currentCityId = null;
  public currentHubId = null;
  public cities = null;

  constructor(
    private navParams: NavParams,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private navCtrl: NavController,
    private formBuilder: FormBuilder,
    private _hubSvc: HubService,
    private toastController: ToastController,
    public alertController: AlertController,
    private popoverController: PopoverController
  ) {
    console.log('@@@CONSTRUCTOR-HUB DETAIL');
  }

  ngOnInit() {
    // Retrieving page parameters
    const hubString = this.navParams.get('hub');
    const hubLatitude = this.navParams.get('hub_latitude') || 0;
    const hubLongitude = this.navParams.get('hub_longitude') || 0;
    const hubType = this.navParams.get('hub_type');
    const hubCoordinates = this.navParams.get('hub_coordinates') || '';

    this.currentHubId = this.navParams.get('hub_id');
    this.currentCityId = this.settingsManager.getCitySetting();
    this.cities = this.settingsManager.getCitiesOptions();

    console.log('-->hubString:', hubString);
    console.log('-->hub_latitude:', hubLatitude);
    console.log('-->hub_longitude:', hubLongitude);
    console.log('-->hubCoordinates:', hubCoordinates);
    console.log('-->hubType:', hubType);

    // Creating the Hub Object
    if (this.currentHubId) {
      this.hub = new Hub (JSON.parse(hubString));
      if (this.hub.hubType === HUB_TYPE_POLYGON) {
        this.hub.latitude = hubLatitude;
        this.hub.longitude = hubLongitude;
      }
    } else {
      this.hub = new Hub();
      this.hub.latitude = hubLatitude;
      this.hub.longitude = hubLongitude;
      this.hub.coordinates = hubCoordinates;
      this.hub.hubType = hubType;
      this.hub.devices = 0;
      this.hub.isHubToHub = false;
    }

    let cityValue;
    if (this.currentCityId) {
      cityValue = Number(this.currentCityId) || '';
    } else {
      cityValue = Number(this.hub.cityId) || '';
    }

    console.log('-->hubObject:', this.hub, cityValue);

    const isVisible_string = this.hub.isVisible ? 'true' : 'false' ;
    const isHubToHub_string = this.hub.isHubToHub ? 'true' : 'false' ;

    this.hubForm = this.formBuilder.group({
      id: [this.hub.id, Validators.compose([  ])],
      cityId: [cityValue, Validators.compose([  ])],
      name: [this.hub.name, Validators.compose([  ])],
      description: [this.hub.description || '', Validators.compose([ Validators.required, Validators.maxLength(512), ])],
      hubType: [this.hub.hubType, Validators.compose([  ])],
      coordinates: [this.hub.coordinates || '', Validators.compose([ ])],
      latitude: [this.hub.latitude || 0, Validators.compose([ Validators.required, ])],
      longitude: [this.hub.longitude || 0, Validators.compose([ Validators.required, ])],
      devices: [0, Validators.compose([ Validators.required, ])],
      desired_devices: [this.hub.desired_devices || 0, Validators.compose([ Validators.required, ])],
      range_meters: [this.hub.range_meters, Validators.compose([ Validators.required, ])],
      discount_on_active_fee: [this.hub.discount_on_active_fee, Validators.compose([ Validators.required, ])],
      discount_on_break_fee: [this.hub.discount_on_break_fee, Validators.compose([ Validators.required, ])],
      discount_on_unlock_fee: [this.hub.discount_on_unlock_fee, Validators.compose([ Validators.required, ])],
      isVisible: [isVisible_string, Validators.required],
      categoryFlags: [this.hub.categoryFlags || 0, Validators.compose([Validators.min(1)])],
      isHubToHub: [isHubToHub_string, Validators.required],
    });

    this.validation_messages = {
      'id': [
        {type: 'required', message: 'add_device.lbl_id_field_required'}
      ],
      'name': [
        {type: 'required', message: 'This field is required.'},
        {type: 'maxlength', message: 'This field must have at most 512 characters.'},
      ],
      'description': [
        {type: 'required', message: 'This field is required.'},
        {type: 'maxlength', message: 'This field must have at most 512 characters.'},
      ],
      'coordinates': [
        {type: 'required', message: 'This field is required.'},
      ],
      'latitude': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'longitude': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'desired_devices': [
        {type: 'required', message: 'This field is required.'},
        {type: 'pattern', message: 'This field must contain a number.'},
      ],
      'range_meters': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_active_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_break_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'discount_on_unlock_fee': [
        {type: 'required', message: 'This field is required.'},
      ],
      'isVisible': [
        {type: 'required', message: 'This field is required.'},
      ],
      'validity_flag': [
        { type: 'required', message: 'subscription_registry.validity_flag_required' },
      ]
    };
  }

  showSpinner() {
    this.isSpinnerVisible = true;
  }

  hideSpinner() {
    this.isSpinnerVisible = false;
  }

  async closePopover(reloadParent = false) {
    console.log('closePopover3', reloadParent);
    await this.popoverController.dismiss(reloadParent);
  }

  async showMessage(messageSeverity: string, messageText: string, messageDuration: number = 2000) {
    const toast = await this.toastController.create({
      message: messageText,
      translucent: true,
      color: messageSeverity,
      duration: messageDuration,
      position: 'bottom',
      mode: 'md',
      cssClass: messageSeverity
    });
    toast.present();
  }


  deleteHub() {
    this.presentAlertConfirm(this.currentHubId);
  }

  async presentAlertConfirm(hubId: number) {
    const alert = await this.alertController.create({
      header: this.translate.instant('Are you sure to proceed?'),
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Delete zone');
            this._hubSvc.delete(hubId).subscribe(() => {
              const msgOK = this.translate.instant('Hub removed successfully.');
              this.showMessage('success', msgOK);
              this.closePopover(true);
            }, err => {
              const msgKO = this.translate.instant('Error removing the hub.');
              this.showMessage('danger', msgKO);
            });
          }
        }
      ]
    });
    await alert.present();
  }


  async onSubmit() {
    this.showSpinner();

    const values = this.hubForm.value;
    if ((values.discount_on_active_fee > 1) || (values.discount_on_active_fee < 0) ||
        (values.discount_on_break_fee > 1) || (values.discount_on_break_fee < 0) ||
        (values.discount_on_unlock_fee > 1) || (values.discount_on_unlock_fee < 0)) {
      const actiondMsg = this.translate.instant('Discounts must be between 0 and 1.');
      this.showMessage('danger', actiondMsg);
      this.closePopover(true);
    }

    values.isHubToHub = values.isHubToHub === 'true';
    values.isVisible = values.isVisible === 'true';

    if (this.currentHubId) {
      // EDIT
      this._hubSvc.edit(this.currentHubId, values)
        .subscribe((response) => {
            console.log('onSubmit OK', response);
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Hub edited successfully.');
            this.showMessage('success', actiondMsg);
            this.closePopover(true);
          },
          (err) => {
            console.log('onSubmit KO', err.status, err);
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Error editing the hub.');
            this.showMessage('danger', actiondMsg);
          });
    } else {
      this._hubSvc.create(values)
        .subscribe((response) => {
            // ADD
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Hub added successfully.');
            this.showMessage('success', actiondMsg);
            this.closePopover(true);
          },
          (err) => {
            this.hideSpinner();
            const actiondMsg = this.translate.instant('Error creating the hub.');
            this.showMessage('danger', actiondMsg);
          });
    }
  }
}
