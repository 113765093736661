import { Moment } from 'moment';
import {
  AlarmType, Booking,
  City,
  Device,
  EventType,
  PaymentMethod, Promotion,
  RechargeActive,
  Rental, RentalsByCity, SeasonTicketType, TripInfo,
  User,
  UserDocument,
  VoucherType,
  WarningType,
  PricingPlan,
  TransactionData,
  SeasonTicket,
  RentalStatistics,
  TransactionStatistics,
  Break,
  DriverData
} from '../classes';
import { CategoryFlags } from '../classes/DeviceCategoryFlags';

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  attributes: string;
  rentalsNumber: number;
  rentalsPrice: number;
  createdAt: string;
  deletedAt: string;
  email_confirmed: boolean;
  account_confirmed: boolean;
  blocked: boolean;
  odometer: number;
  payment_methods: PaymentMethod[];
  documents: UserDocument[];
  rentals: Rental[];
  usage_conditions_accepted: boolean;
  privacy_conditions_accepted: boolean;
  unfair_terms_accepted: boolean;
  registrationCity: string;
  gender: string;
  birth_place: string;
  birth_date: string;
  cap: number;
  pricingPlan: PricingPlan;
  fullSubscription: boolean;
  tempPricingPlan: PricingPlan;
  active_subscriptions: SeasonTicket[];
  wallet_amount: number;
  assignedCities: City[];
  debt: number;
  driverData: DriverData
}

export interface IOperator {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  attributes: string;
}

export interface ICity {
  id: number;
  name: string;
  description: string;
  coordinates: string;
  park_enabled: boolean;
  active_fee: number;
  break_fee: number;
  unlock_fee: number;
  commandTypeId: number;
  color: string;
  rule1: string;
  rule2: string;
  rule3: string;
  center_latitude: number;
  center_longitude: number;
  code: number;
  isVisible: boolean;
  avviso_sanzioni_attivo: boolean;
  warning_chiusura_attivo: boolean;
  isDocumentRequired: boolean;
  isFullSubscriptionRequired: boolean;
  pricingPlanId: number;
  pricingPlan: PricingPlan;
  distance: number;
  your_active_fee: number;
  your_break_fee: number;
  your_unlock_fee: number;
  booking_fee: number;
  your_booking_fee: number;
  scooter_coordinates: string;
  scooter_area: number;
  scooter_area_color: string;
  scooter_active_fee: number;
  scooter_break_fee: number;
  scooter_unlock_fee: number;
  scooter_booking_fee: number;

  assignedUsers: User[];
}

export enum DeviceStateEnum {
  IDLE = 1,
  BOOKED = 2,
  RENT = 3,
  SUSPENDED = 4,
  BATTERY_LOW = 5,
  ACTION_REQUIRED = 6,
  CHARGER = 7,
  FAULTY = 8,
  LOST = 9,
  OPERATOR = 10,
  STOKED = 11,
  SEARCHING = 12
}

export interface IDevice {
  id: number;
  name: string;
  imei: number;
  protocol: string;
  createdAt: string;
  model: string;
  ICCID: string;
  fw_version: string;
  hw_version: string;
  blocked: boolean;
  attributes: string;
  cityId: number;
  stateId: DeviceStateEnum;
  ext_battery: number;
  battery_fuel: number;
  swap_battery_fuel: number;
  in_charge: number;
  is_active: number;
  latitude: number;
  longitude: number;
  speed: number;
  satellites: number;
  device_time: string;
  city: City;
  stateName: string;
  cityName: string;
  odometer: number;
  batteryCycles: number;
  ping: string;
  tripint: string;
  statint: string;
  impact: string;
  blekey: string;
  netconfig: string;
  lastRental: string;
  lastMaintenance: string;
}


export interface IZone {
  cityId: number;
  color: string;
  commandTypeId: number;
  coordinates: string;
  creation_time: string;
  description: string;
  discount_on_fee: number;
  discount_on_total: number;
  free_unlock: boolean;
  id: number;
  name: string;
  park_enabled: boolean;
  isVisible: boolean;
  warning_chiusura_attivo: boolean;
  area: number;
}

export interface IRentalReport {
  rentalId: number;
  price: number;
  trip_time_sec: number;
  break_time_sec: number;
  booking_time_sec: number;
  total_time_sec: number;
  start_time: string;
  stop_time: string;
  rental_history_id: number;
  deviceId: number;
  subscription: boolean;
  time_voucher_reduction: number;
  money_voucher_reduction: number;
  zone: string;
  discount_on_total: number;
  discount_on_fee: number;
  unlock_fees: number;
  booking_price: number;
}


export interface IRentalStatus {
  start_time: string;
  deviceId: number;
  stateId: number;
}

export interface IBookingStatus {
  booking: IBookingDev;
  device: IDevice;
}

export interface IBookingDev {
  id: number;
  start_time: string;
  userId: number;
  deviceId: number;
}

export interface IBooking {
  id: number;
  start_time: string;
  stop_time: string;
  userId: number;
  deviceId: number;
  device: Device;
  user: User;
}

export interface ICustomerProfile {
  id: number;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  email: string;
  attributes: string;
  blocked: number;
  email_confirmed: number;
  roleId: number;
  gender: string;
  birth_date: string;
  birth_place: string;
  cap: string;
  createdAt: string;
  updatedAt: string;
  subscribed: boolean;
  subscription_type: number;
  subscription_creation: string;
  subscription_expiration: string;
}

export interface ITripReport {
  id: number;
  name: string;
  userId: number;
  start_time: string;
  stop_time: string;
  break_time: number;
  deviceId: number;
  photoFilename: string;
  firstName: string;
  lastName: string;
  social_security_number: string;
  telephone: string;
  address: string;
  email: string;
  blocked: number;
  email_confirmed: number;
  roleId: number;
  createdAt: string;
  city: string;
  imei: number;
  protocol: string;
  model: string;
  contact: string;
  cityId: number;
  amount: number;
  start_latitude: number;
  start_longitude: number;
  stop_latitude: number;
  stop_longitude: number;
  tripDist_km: number;
  usage_conditions_accepted: boolean;
  privacy_conditions_accepted: boolean;
  unfair_terms_accepted: boolean;
  currentAppVersion: string;
  currentOS: string;
  active_price: number;
  break_price: number;
  unlock_price: number;
  booking_price: number;
  voucher_reduction: number;
  promotion_reduction: number;
  subscription_reduction: number;
}

export interface IEventType {
  id: number;
  name: string;
  description: string;
  attributes: string;
}

export interface IEvent {
  id: number;
  event_data: string;
  device_time: string;
  deviceId: number;
  imei: string;
  name: string;
  device: Device;
  eventType: EventType;
}

export interface IVoucherType {
  id: number;
  name: string;
  description: string;
  unit_of_measure: string;
}

export interface IVoucher {
  id: number;
  voucher_code: string;
  value: number;
  isValid: boolean;
  release_date: string;
  expiration_date: string;
  invocation_date: string;
  city: City;
  user: User;
  searchString: string;
  voucherType: VoucherType;
  remaining_value: number;
  preassignedTo: string;
}

export interface IRentalActive {
  id: number;
  start_time: Date;
  start_latitude: number;
  start_longitude: number;
  city: string;
  transactionId: number;
  transaction: TransactionData;
  userId: number;
  user: User;
  deviceId: number;
  device: Device;
  currentAppVersion: string;
  currentOS: string;
  bookingHistoryId: number;
  deviceCategory: CategoryFlags;
  bookingHistory: IBooking;
  breaks: Break[];
}

export interface IRecharge {
  id: number;
  start_time: string;
  release_time: string;
  userId: number;
  deviceId: number;
  hubId: number;
  email: string;
  telephone: string;
  firstName: string;
  lastName: string;
  imei: string;
  model: string;
}

export interface ISelectOption {
  value: number;
  label: string;
}

export interface IUserDocument {
  id: number;
  isValid: boolean;
  submission_date: string;
  expiration_date: string;
  path: string;
  filename: string;
  mimetype: string;
  md5: string;
  size: number;
  documentTypeId: number;
  userId: number;
  released_by: string;
  doc_number: string;
}

export interface INotification {
  id: number;
  text: string;
  datetime: string;
  userId: string;
  visualized: boolean;
}

export interface IHub {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  description: string;
  attributes: string;
  devices: number;
  desired_devices: number;
  cityId: number;
  isVisible: boolean;
  range_meters: number;
  discount_on_active_fee: number;
  discount_on_break_fee: number;
  discount_on_unlock_fee: number;
  coordinates: string;
  hubType: string;
  area: number;
}

export interface IPaymentMethod {
  id: number;
  card_number: string;
  owner_firstName: string;
  owner_lastName: string;
  registration_date: string;
  expiryMonth: number;
  expiryYear: number;
  isValid: boolean;
  userId: number;
  paymentTypeId: number;
  walletId: string;
}

export interface ISeasonTicketType {
  id: number;
  name: string;
  description: string;
  price: number;
  duration_hours: number;
  creation_date: string;
  isValid: boolean;
  type: string;
}

export interface ISeasonTicket {
  id: number;
  creation_date: string;
  expiration_date: string;
  ticketType: SeasonTicketType;
  user: User;
}

export interface IUserSubscription {
  id: number;
  creation_date: string;
  expiration_date: string;
  name: string;
  description: string;
  price: number;
  duration: number;
  isActive: boolean;
}

export interface ITransactionData {
  id: number;
  creation_date: string;
  userId: number;
  code: string;
  paymentid: string;
  description: string;
  authorizationcode: string;
  rrn: string;
  result: string;
  errorcode: string;
  errormessage: string;
  type: string;
  amount: number;
  social_security_number: string;
  telephone: string;
  address: string;
  blocked: boolean;
  roleId: number;
  gender: string;
  createdAt: string;
  rentalHistoryId: number;
  user: User;
  rentalHistory: Rental;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userTelephone: string;
  userAddress: string;
  userBlocked: boolean;
  userCity: string;
  rentalStartTime: string;
  rentalStopTime: string;
  rentalCity: string;
  action: string;

}

export interface IWarningType {
  id: number;
  name: string;
  description: string;
}

export interface IReport {
  id: number;
  mark: number;
  description: string;
  submission_date: string;
  fault: WarningType;
  rental: Rental;
}

export interface IReportStats {
  rentals_num: number;
  reports_num: number;
  avg_mark: number;
  star_0: number;
  star_1: number;
  star_2: number;
  star_3: number;
  star_4: number;
  star_5: number;
}

export interface IRechargeActive {
  id: number;
  start_time: string;
  userId: number;
  deviceId: number;
  hubId: number;
}

export interface IChargerInfo {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  attributes: string;
  createdAt: string;
  charger_enabled: boolean;
  active_recharges: RechargeActive[];
}

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface IMobileTests {
  cameraEnabled: boolean;
  cameraAuthorized: boolean;
  gpsLocationEnabled: boolean;
  gpsLocationAuthorized: boolean;
}

export interface IBlacklistedCard {
  paymentMethodId: number;
  owner_firstName: string;
  owner_lastName: string;
  registration_date: string;
  card_number: string;
  expiryMonth: number;
  expiryYear: number;
  email: string;
  registeredBy: string;
  user_blocked: boolean;
  userId: number;
  blacklistedAt: string;
  blacklistedCardId: number;
}

export interface IRentalsByCity {
  rentals: number;
  city: string;
}

export interface IStatistics {
  online_devices: number;
  offline_devices: number;
  devices_idle: number;
  devices_booked: number;
  devices_on_trip: number;
  devices_suspended: number;
  devices_battery_low: number;
  devices_on_charge: number;
  devices_assigned_to_charger: number;
  signed_users: number;
  active_users: number;
  deleted_users: number;
  unconfirmed_users: number;
  new_users_today: number;
  new_users_week: number;
  active_rentals: number;
  number_of_rentals: number;
  avg_rental_time: number;
  avg_trip_km: number;
  daily_revenue: number;
  weekly_revenue: number;
  monthly_revenue: number;
  daily_revenue_trend: number;
  weekly_revenue_trend: number;
  monthly_revenue_trend: number;
  avg_rental_revenue: number;
  faulty_devices: number;
  open_faults: number;
  closed_faults: number;
  maintenance_price: number;
  lost_devices: number;
  rentals_by_city: RentalsByCity[];
}

export interface ITransactionStatistics {
  date: Date;
  count: Number;
  city: string;
}

export interface IRentalStatistics {
  date: Date;
  amount: Number;
  city: string;
}

export interface IHistoryStatistics {
  transactions: TransactionStatistics[];
  rentals: RentalStatistics[];
}

export interface ITimeStatistics {
  new_users: number;
  deleted_users: number;
  number_of_rentals: number;
  revenue: number;
  rentals_by_city: RentalsByCity[];
  new_faults: number;
}

export interface IFault {
  id: number;
  description: string;
  latitude: number;
  longitude: number;
  odometerValue: number;
  createdAt: string;
  closed: boolean;
  closedAt: string;
  price: number;
  attributes: string;
  comments: string;
  city: string;
  device: Device;
  technician: string;
}

export interface IPromotion {
  id: number;
  code: string;
  name: string;
  description: string;
  type: number;
  createdAt: string;
  startsAt: string;
  expiresAt: string;
  value: number;
  firstRentalOnly: boolean;
  allCities: boolean;
  city: string;
  usedBy: number;
}

export interface IUserPromotion {
  id: number;
  user: User;
  used: boolean;
  promotion: Promotion;
  usedAt: string;
  remaining_value: number;
}

export interface IPricingPlan {
  id: number;
  name: string;
  description: string;
  discount_on_active_fee: number;
  discount_on_break_fee: number;
  discount_on_unlock_fee: number;
  is_valid: boolean;
  creation_time: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  max_free_unlock_daily: number;
  free_unlock_threshold: number;
}

export interface ISetting {
  id: number;
  name: string;
  description: string;
  value: string;
  type: string;
}

export interface IOperation {
  id: number;
  start_time: string;
  comment: string;
  stop_latitude: number;
  stop_longitude: number;
  stop_time: string;
  closed: boolean;
  device: Device;
  user: User;
}

export interface IOperationDevice {
  operator: boolean;
  device: Device;
}

export interface IStateTransition {
  id: number;
  stateA: string;
  stateB: string;
  reason: string;
  timestamp: string;
  forced: boolean;
  putOnline: boolean;
  putOffline: boolean;
}

export interface IAlarmType {
  id: number;
  name: string;
  description: string;
  attributes: string;
}

export interface IAlarmActive {
  id: number;
  alarm_data: string;
  server_time: string;
  latitude: number;
  longitude: number;
  seen: boolean;
  seenBy: string;
  deviceId: number;
  rental: TripInfo;
  device: Device;
  alarmType: AlarmType;
  closed: boolean;
  closedBy: string;
}

export enum commandType {
  POWER_ON = 1,
  POWER_OFF = 2,
  LOCK = 3,
  UNLOCK = 4,
  SPEEDLIMIT_5 = 5,
  SPEEDLIMIT_10 = 6,
  SPEEDLIMIT_15 = 7,
  SPEEDLIMIT_20 = 8,
  BUZZER_ON = 9,
  SPEEDLIMIT_0 = 10,
  LIGHTS_ON = 11,
  LIGHTS_OFF = 12,
  SPEEDLIMIT_30 = 13,
  SPEEDLIMIT_6 = 14,
  GET_MT1 = 15,
  TRIP_INTERVAL = 16,
  STATIONARY_INTERVAL = 17,
  SPEEDLIMIT_25 = 18,
  GET_MT2 = 19,
  UNLOCK_TRUNK = 20,
  LOCK_BATTERY = 21,
  UNLOCK_BATTERY = 22,
  UNAUTHORIZED_AREA = 23,
  EMERGENCY = 24,
  UNAVAILABLE_DEVICE = 25,
  BUZZON_S3 = 33,
  START_KM_COUNTER = 40,
  STOP_KM_COUNTER = 41
}

export interface ICommand {
  id: number;
  toDo: boolean;
  invocation_time: string;
  execution_time: string;
  command_data: string;
  commandTypeId: commandType;
  deviceId: number;
  request_ip: string;
}

export enum measureUnitEnum {
  GG = 'gg', // days
  MIN = 'min', // minutes
  FIXED = 'fx', // fixed amount
  FREE_AMOUNT = 'fa' // amount not estabilied
}

export interface IPenaltyRegistry {
  id: number;
  code: string;
  name: string;
  description: string;
  measureUnit: measureUnitEnum;
  amount?: number;
  vatCode?: string;
}

export interface IFcmMessage {
  id: number;
  userId: string;
  os: string;
  title: string;
  description: string;
  image_url: string;
  fcm_token: string;
  fcm_message_id: number;
  badge_ios: number;
  deadline_type: string;
  deadline_value: number;
  send_on_date: string;
  created_at: string;
  sent: number;
  topicId: number;
  topicName: string;
  topicDescription: string;
}

export interface IFcmTopic {
  id: number;
  name: string;
  description: string;
  is_valid: number;
}
