import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { City, Device, Model } from 'src/app/classes';
import { DeviceService } from 'src/app/services/device.service';
import { ModelService } from 'src/app/services/model.service';
import { SettingsmanagerService } from 'src/app/services/settingsmanager.service';
import { WidgetsmanagerService } from 'src/app/services/widgetsmanager.service';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {

  public states = {};
  public statesKeys = Object.keys(this.settingsManager.getDeviceStates());
  public cities: City[] = this.settingsManager.getCitiesOptions();
  public deviceForm: FormGroup;
  public validation_messages: any;
  public loading = false;
  public connectorType = [];
  public models: Model[];
  
  constructor(
    private formBuilder: FormBuilder,
    private _modelSvc: ModelService,
    private _deviceSvc: DeviceService,
    private _popoverController: PopoverController,
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private widgetsManager: WidgetsmanagerService
  ) { }

  ngOnInit() {

    // Popola select del campo stato
    const states = this.settingsManager.getDeviceStates();
    for (const state of Object.keys(states)) {
      this.states[state] = states[state];
    }

    // Popola select dei modelli
    this._modelSvc.list().subscribe(
      (models) => { this.models = models; },
      (err) => { console.log('ERR (add-device):', err.message); }
    );

    this.deviceForm = this.formBuilder.group({
      id: [undefined, [Validators.pattern('^[0-9]*$')]],
      name: [undefined, [Validators.required]],
      imei: [undefined, [Validators.required, Validators.minLength(15), Validators.maxLength(15), Validators.pattern('^[0-9]*$')]],
      stateId: [undefined, [Validators.nullValidator]],
      cityName: [undefined, [Validators.required]],
      protocol: [undefined, [Validators.required]],
      modelId: [undefined, [Validators.required]],
      iccid: [undefined, [Validators.nullValidator]],
      blocked: [0, [Validators.nullValidator]],
      alarms_enabled: [1, [Validators.nullValidator]],
    });

    this.validation_messages = {
      id: [
        { type: 'required', message: 'add_device.lbl_id_field_required' },
        { type: 'pattern', message: 'Only numbers are accepted' }
      ],
      name: [
        { type: 'required', message: 'Field is required' },
      ],
      stateId: [
        { type: 'required', message: 'Field is required' },
      ],
      modelId: [
        { type: 'required', message: 'Field is required' },
      ],
      imei: [
        { type: 'required', message: 'Field is required.' },
        { type: 'minlength', message: 'Imei length must be equal to 15.' },
        { type: 'maxlength', message: 'Imei length must be equal to 15.' },
        { type: 'pattern', message: 'Only numbers are accepted.' },
      ],
      protocol: [
        { type: 'required', message: 'Field is required' },
      ],
      cityName: [
        { type: 'required', message: 'Field is required' },
      ]
    };
  }

  async close(reloadParent = false): Promise<void> {
    await this._popoverController.dismiss(reloadParent);
  }

  onSubmit(): void {
    this.loading = true;

    // estrazione valori da mandare
    // const objToSend = this.deviceForm.value;
    const objToSend: Device = new Device({
      id: this.deviceForm.value.id,
      name: this.deviceForm.value.name,
      imei: this.deviceForm.value.imei,
      stateId: this.deviceForm.value.stateId,
      cityName: this.deviceForm.value.cityName,
      protocol: this.deviceForm.value.protocol,
      modelId: this.deviceForm.value.modelId,
      iccid: this.deviceForm.value.iccid,
      blocked: this.deviceForm.value.blocked,
      alarms_enabled: this.deviceForm.value.alarms_enabled,
      cityId: undefined
    });
    for (const city of this.cities) {
      if (objToSend.cityName == city.name) {
        objToSend.cityId = city.id;
        break;
      }
    }

    this._deviceSvc.create(objToSend).subscribe(() => {
      this.loading = false;
      //TODO trigger evento ma serve avere oggetto device
      //this.newDevice.emit();
      const msg = this.translate.instant('Device added successfully');
      this.widgetsManager.showToastMessage('success', msg);
      this.close(true);
    }, err => {
      this.loading = false;
      console.log(err);
      this.widgetsManager.showToastMessage('danger', 'Error while adding device');
    });
  }

}
