import {
  Component,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {
  AlertController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import * as _ from 'lodash';

// BS modules
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { DeviceDetailComponent } from 'src/app/components/device-detail/device-detail.component';
import { ZonedetailPage } from './../../managers/zonedetail/zonedetail.page';
import { CitydetailPage } from './../../managers/citydetail/citydetail.page';
import { ViewAreaComponent } from 'src/app/components/view-area/view-area.component';
import { HubDetailComponent } from 'src/app/components/hub-detail/hub-detail.component';
import { HubEditorComponent } from 'src/app/components/hub-editor/hub-editor.component';

// BS Service providers
import { SettingsmanagerService } from './../../services/settingsmanager.service';
import { WsmanagerService } from '../../services/wsmanager.service';
import { Hub, City, Position } from '../../classes';
import { DeviceStateEnum } from 'src/app/interfaces/WSInterfaces';
import { Device } from 'src/app/classes';
import { CategoryFlags } from 'src/app/classes/DeviceCategoryFlags';

declare let google;

const HUB_TYPE_CIRCLE = 'Circle';
const HUB_TYPE_POLYGON = 'Polygon';

const BASE_ICON_URL = '../../assets/icon/device/';

const VEHICLE_STATUS_BOOKED = 2;

const enum zIndexMapping {
  CITY = 5,
  ZONE = 6,
  HUB = 20,
  DEVICE = 99
}

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent {
  apiKey = null;
  mapType = null;

  position_base = {
    position_longitude: null,
    position_latitude: null,
  };

  public map: any = null;
  public marker: any;
  public connectionAvailable = true;

  // The positions of the markers displayed on the map
  private devicePositions = null;
  private zonePositions = null;
  private areasPositions = null;
  private hubPositions = null;

  // Current City
  private currentCityData = null;
  private currentCityId: number = null;

  // The markers displayed on the map in this moment
  private displayedMarkers = [];
  private foundMarkers = [];
  private myPositionMarker = null;
  private zoneMarkers = [];
  private areasMarkers = [];
  private displayedHubs = [];
  private displayedZoneHubs = [];
  private rentedVehicleMarker = null;

  // The markers of the admin interface
  private newZoneCoordinates = '';
  private newZoneOverlay = [];
  private newHubOverlay = [];
  private newHubCoordinates = '';

  // Google Directions
  private directionsService = null;
  private directionsRenderer = null;

  private defaultHubZonesColor = '';
  private defaultGsPointColor = '';

  private targetHub: Hub;

  private currentZoom: number;
  private heatmap;

  private cityPolygons: _.Dictionary<any> = {};
  private scooterAreaPolygons: _.Dictionary<any> = {};
  private zonePolygons: _.Dictionary<any> = {};
  private hubPolygons: _.Dictionary<any> = {};
  private hubMarkers: _.Dictionary<any> = {};
  private deviceMarkers: _.Dictionary<any> = {};

  constructor(
    private element: ElementRef,
    private platform: Platform,
    private translate: TranslateService,
    public alertController: AlertController,
    private settingsManager: SettingsmanagerService,
    private popoverController: PopoverController,
    private wsManager: WsmanagerService,
    private toastController: ToastController
  ) {
    this.apiKey = this.settingsManager.getGoogleMapsApiKey();
    this.defaultHubZonesColor = this.settingsManager.getDefaultColorHubZones();
    this.defaultGsPointColor = this.settingsManager.getDefaultColorBitPoint();
  }

  public init(mapType, mapOptions): Promise<any> {
    this.mapType = mapType;
    this.settingsManager.currentCityId.subscribe((value) => {
      this.currentCityId = value;
    });
    return new Promise((resolve, reject) => {
      if (this.map == null) {
        this.initMap(mapOptions).then(
          (res1) => {
            this.enableMap();
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        reject('Google maps already initialised');
      }
    });
  }

  private initMap(options): Promise<any> {
    console.log('@@@initMap@@@');
    return new Promise((resolve, reject) => {
      // const latLng = new google.maps.LatLng(45.441032, 10.986387);
      const mapOptions = {
        // center: latLng,
        // zoom: zoomLevel,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_CENTER,
        },
        clickableIcons: options.clickableIcons,
        zoomControl: options.zoomControl,
        mapTypeControl: options.mapTypeControl,
        scaleControl: options.scaleControl,
        streetViewControl: options.streetViewControl,
        rotateControl: options.rotateControl,
        fullscreenControl: options.fullscreenControl,
        styles: [
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#2657a0',
              },
            ],
          },
          {
            featureType: 'administrative.province',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#3d3d3d',
              },
              {
                weight: 2,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#ffffff',
              },
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#3d3d3d',
              },
              {
                visibility: 'on',
              },
              {
                weight: 1.5,
              },
            ],
          },
        ],
      };

      this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
      this.settingsManager.incrDebugGoogleMapCalls();
      console.log(
        '---> NEW google MAP()',
        this.settingsManager.getDebugGoogleMapCalls()
      );

      google.maps.event.addListener(this.map, 'idle', () => {
        const zoom = this.map.getZoom();
        if (this.heatmap && this.currentZoom !== zoom) {
          this.setHeatmapRadius(this.getRadius());
        }
        this.currentZoom = zoom;
      });

      resolve(true);
    });
  }

  private getRadius() {
    let radius: number;
    const position = this.map.getCenter();
    if (position) {
      const zoom = this.map.getZoom();
      const metersPerPx = 156543.03392 * Math.cos(position.lat() * Math.PI / 180) / Math.pow(2, zoom);
      radius = 10 / metersPerPx;
    }
    return radius;
  }

  enableMap(): void {
    this.connectionAvailable = true;
  }

  public loadHeatmap(positions: Position[], maxIntensity = 20) {
    const data = positions.map((position) => {
      return new google.maps.LatLng(position.latitude, position.longitude);
    });
    if (this.heatmap) {
      this.heatmap.setData(data);
      this.heatmap.setMap(this.map);
    } else {
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: data,
        radius: this.getRadius(),
        dissipating: true,
        maxIntensity: maxIntensity
      });
      this.heatmap.setMap(this.map);
    }
  }

  public setHeatmapVisibility(visibility: boolean) {
    if (this.heatmap) {
      this.heatmap.setMap(visibility ? this.map : null);
    }
  }

  public setHeatmapRadius(radius: number) {
    if (this.heatmap) {
      this.heatmap.set('radius', radius);
    }
  }

  public setHeatmapMaxIntensity(maxIntensity: number) {
    if (this.heatmap) {
      this.heatmap.set('maxIntensity', maxIntensity);
    }
  }

  public setHeatmapOptions(radius: number, maxIntensity: number) {
    if (this.heatmap) {
      this.heatmap.setOptions({
        radius: radius,
        maxIntensity: maxIntensity
      });
    }
  }

  public setHeatmapAutoIntensity() {
    this.setHeatmapMaxIntensity(null);
  }

  public changeMarker(lat: number, lng: number): void {
    const latLng = new google.maps.LatLng(lat, lng);
    const marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      zindex: zIndexMapping.DEVICE + 1,
    });
    // Remove existing marker if it exists
    if (this.marker) {
      this.marker.setMap(null);
    }
    // Add new marker
    this.marker = marker;
  }

  public removeRentedVehicleMarker() {
    if (this.rentedVehicleMarker) {
      this.rentedVehicleMarker.setMap(null);
    }
  }

  public addDeviceMarkers(devicePositions: Array<any>): void {
    const context = this;
    console.log('@@@@ addDeviceMarkers', devicePositions);
    context.devicePositions = devicePositions;

    // 1 - Delete the old markers
    if (context.displayedMarkers) {
      for (let i = 0; i < context.displayedMarkers.length; i++) {
        context.displayedMarkers[i].setMap(null);
      }
      context.displayedMarkers = [];
    }

    // 2 - Now we can add the new markers
    devicePositions.map(function (device) {
      const position = {
        lat: Number(device.latitude),
        lng: Number(device.longitude),
      };
      // Create window content
      // const imagePath = '../../assets/img/' + device.model + '.jpg';

      // Fetch icon
      const iconUrl = context.getDeviceIconUrl(device);

      const icon = {
        url: iconUrl,
        scaledSize: new google.maps.Size(45, 45),
      };
      const marker = new google.maps.Marker({
        position: position,
        title: device.name,
        id: device.id,
        icon: icon,
        zindex: zIndexMapping.DEVICE,
      });

      // Save the marker in the list of the displayed markers (it is useful to remove them when needed)
      context.displayedMarkers.push(marker);
      // Set the marker on the map
      marker.setMap(context.map);
      // Add a listener so that something happens when the user clicks on the marker
      marker.addListener('click', function (event) {
        // infoWindow.open(context.map, marker);
        context.presentPopoverDeviceDetail(marker.get('device'), event);
      });
    });
  }

  getDeviceIconDir(deviceCategory: CategoryFlags): string {
    return CategoryFlags[deviceCategory];
  }

  getDeviceIconUrl(device: Device) {
    const baseUrl = BASE_ICON_URL;
    let name = '';
    const deviceIconDir = this.getDeviceIconDir(device.deviceCategory);

    // icon url depends by state
    switch (device.stateId) {
      case DeviceStateEnum.IDLE: {
        if ((device.battery_fuel >= 0) && (device.battery_fuel < 10)) {
          name = 'batteria_1.png';
        } else if ((device.battery_fuel >= 10) && (device.battery_fuel < 25)) {
          name = 'batteria_2.png';
        } else if ((device.battery_fuel >= 25) && (device.battery_fuel < 50)) {
          name = 'batteria_3.png';
        } else if ((device.battery_fuel >= 50) && (device.battery_fuel < 75)) {
          name = 'batteria_4.png';
        } else {
          name = 'batteria_5.png';
        }
        break;
      }
      default: {
        name = 'stato_' + device.stateId + '.png';
        break;
      }
    }

    return baseUrl + deviceIconDir + '/' + name;
  }

  getDeviceIconUrlByState(deviceCategory: CategoryFlags, stateId) {
    const baseUrl = BASE_ICON_URL;
    const deviceIconDir = this.getDeviceIconDir(deviceCategory);
    const name = 'stato_' + stateId + '.png';
    return baseUrl + deviceIconDir + '/' + name;
  }

  // Adds hubs markers on google map
  addHubMarkers(editMode: boolean, filters: any = null) {
    // let hubs: Hub[] = [];
    const context = this;

    // check if there are defined filters
    let isFlagEnabledSelected = true;
    let isFlagNotAvailableSelected = true;
    if (filters) {
      filters.visibility.forEach((element) => {
        if (!element.isChecked && element.type === 'ENABLED') {
          isFlagEnabledSelected = false;
        }
        if (!element.isChecked && element.type === 'NOT_AVAILABLE') {
          isFlagNotAvailableSelected = false;
        }
      });
    }

    // 1 - Delete the old markers
    if (context.displayedHubs) {
      for (let i = 0; i < context.displayedHubs.length; i++) {
        context.displayedHubs[i].setMap(null);
      }
      context.displayedHubs = [];
    }
    if (context.displayedZoneHubs) {
      for (let i = 0; i < context.displayedZoneHubs.length; i++) {
        context.displayedZoneHubs[i].setMap(null);
      }
      context.displayedZoneHubs = [];
    }

    // 2 - Now we can add the new markers
    context.wsManager.fetchHubs().subscribe((items) => {
      // hubs = items;
      console.log('@@@ Hubs:', items);
      context.hubPositions = items;

      context.hubPositions.map(function (hub) {
        // Flag of the HUB
        // visualization will be denied if specified in filters
        if (
          (hub.isVisible && isFlagEnabledSelected) ||
          (!hub.isVisible && isFlagNotAvailableSelected)
        ) {
          const marker = context.createHubMarker(hub);
          if (marker) {
            // Add the marker to the list of showed markers
            context.displayedHubs.push(marker);
            // Put the marker on the map
            marker.setMap(context.map);
          }
        }

        // Zone of the HUB
        const zoneMarker = context.createHubZone(hub);
        if (zoneMarker) {
          // Add the marker to the list of showed markers
          context.displayedZoneHubs.push(zoneMarker);
          // Put the marker on the map
          zoneMarker.setMap(context.map);
        }

        if (editMode) {
          // Add a listener so that something happens when the user clicks on the marker
          if (zoneMarker) {
            zoneMarker.addListener('click', function (event) {
              context.presentEditPopoverHubDetail(hub);
            });
          }
        } else {
          if (zoneMarker) {
            zoneMarker.addListener('click', function (event) {
              context.presentPopoverHubDetail(hub, event);
            });
          }
        }
      });
    });
  }

  createHubMarker(hub) {
    // HUB marker
    const position = { lat: Number(hub.latitude), lng: Number(hub.longitude) };
    let iconUrl;

    if (hub.isVisible) {
      iconUrl = '../../assets/icon/gs-point.png';
    } else {
      iconUrl = '../../assets/icon/hub.png';
    }
    const icon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(45, 45),
    };
    const marker = new google.maps.Marker({
      position: position,
      title: hub.name,
      id: hub.id,
      icon: icon,
      zindex: zIndexMapping.HUB + 1,
    });

    return marker;
  }

  createHubZone(hub) {
    const context = this;
    let marker = null;
    let zoneColor;

    if (hub.isVisible) {
      zoneColor = context.defaultHubZonesColor;
    } else {
      zoneColor = context.defaultGsPointColor;
    }

    if (hub.hubType === HUB_TYPE_CIRCLE) {
      // Draw circular HUB
      if (hub.latitude && hub.longitude) {
        const position = {
          lat: Number(hub.latitude),
          lng: Number(hub.longitude),
        };

        const center = {
          lat: Number(hub.latitude),
          lng: Number(hub.longitude),
        };
        marker = new google.maps.Circle({
          strokeColor: zoneColor,
          strokeOpacity: 0.9,
          strokeWeight: 3,
          fillColor: zoneColor,
          fillOpacity: 0.2,
          center: center,
          radius: hub.range_meters,
          zIndex: zIndexMapping.HUB,
        });
      } else {
        console.log('ERR: HUB Circolare Non valido', hub);
      }
    } else {
      if (hub.coordinates.trim().length > 0) {
        const poligCoordinates = context.getCoordinatesByString(
          hub.coordinates
        );
        // Draw polygonal HUB
        marker = new google.maps.Polygon({
          paths: poligCoordinates,
          strokeColor: zoneColor,
          strokeOpacity: 0.9,
          strokeWeight: 3,
          fillColor: zoneColor,
          fillOpacity: 0.2,
          zoneId: hub.id,
          zoneName: hub.name,
          zIndex: zIndexMapping.HUB,
        });
      } else {
        console.log('ERR: HUB Non valido', hub);
      }
    }

    if (marker) {
      this.hubPolygons[hub.id] = marker;
    }

    return marker;
  }

  getCoordinatesByString(coordString): Array<{ lat: number; lng: number }> {
    const poligCoordinates: Array<{ lat: number; lng: number }> = [];
    const coordsArray = coordString.split(';');
    coordsArray.map(function (coupleString) {
      const cords = coupleString.split(',');
      const position = { lat: Number(cords[0]), lng: Number(cords[1]) };
      poligCoordinates.push(position);
    });
    return poligCoordinates;
  }

  showCurrentPosition(pos, showMarker = true, zoomLevel = null): void {

    let myZoomLevel = null;
    if (zoomLevel == null) {
      // Default zoom level of 'get my position'
      myZoomLevel = this.settingsManager.getGoogleMapsPositionZoomLevel();
    } else {
      // Custom zoom level
      myZoomLevel = zoomLevel;
    }
    this.map.setZoom(myZoomLevel);
    this.map.setCenter(pos);
  }


  findCurrentPosition(pos): void {
    console.log('### findCurrentPosition', pos);
    const imageUrl = this.settingsManager.getIconCurrentPosition();
    const marker = new google.maps.Marker({
      position: pos,
      title: 'Here it is!',
      icon: imageUrl,
      zindex: zIndexMapping.DEVICE - 1,
    });

    marker.metadata = { id: 'current_position' };
    marker.setMap(this.map);
    this.foundMarkers.push(marker);

    const zoomLevel = this.settingsManager.getGoogleMapsPositionZoomLevel();
    this.map.setZoom(zoomLevel);
    this.map.setCenter(pos);
  }

  findAddress(address, cityName = ''): void {
    const context = this;
    const imageUrl = context.settingsManager.getIconCurrentPosition();
    const geocoder = new google.maps.Geocoder();

    if (cityName) {
      address = cityName + ', ' + address;
    }
    // console.log('### findAddress:', address);

    geocoder.geocode({ address: address }, function (results, status) {
      // console.log('@@@@@ GEOCODE:', results[0].geometry.location, this.city);
      if (status === 'OK') {
        const pos = results[0].geometry.location;

        const marker = new google.maps.Marker({
          map: context.map,
          title: 'Here it is!',
          position: pos,
          icon: imageUrl,
          zindex: zIndexMapping.DEVICE + 1,
        });
        marker.setMap(context.map);
        context.foundMarkers.push(marker);

        const zoomLevel = context.settingsManager.getGoogleMapsPositionZoomLevel();
        context.map.setZoom(zoomLevel);
        // const latLng = new google.maps.LatLng(pos.lat, pos.lng); // Makes a latlng
        // this.map.panTo(latLng); // Make map global
        context.map.setCenter(pos);
      } else {
        console.log(
          'Geocode was not successful for the following reason: ' + status
        );
      }
    });
  }

  clearFoundPositions() {
    // Delete the old markers
    // console.log('@@@ CANCELLO I MARKERS 2 ****');
    if (this.foundMarkers) {
      for (let i = 0; i < this.foundMarkers.length; i++) {
        this.foundMarkers[i].setMap(null);
      }
      this.foundMarkers = [];
    }
  }

  /**
   *  This method opens the popover with the infos of the device selected on the map
   *
   * @param deviceId: The id of the device.
   */
  async presentPopoverDeviceDetail(device: Device, ev: any): Promise<void> {
    const refreshEvent = new EventEmitter<Device>();
    const popover = await this.popoverController.create({
      component: DeviceDetailComponent,
      event: ev,
      translucent: false,
      componentProps: {
        deviceId: device.id,
        refresh: refreshEvent
      },
    });

    refreshEvent.subscribe((device) => {
      this.addDevice(device);
    });

    popover.onDidDismiss().then((dataPar: OverlayEventDetail) => {
      console.log(
        '@@@ chiuso modal - presentPopoverDeviceDetail',
        dataPar.data
      );
      // Verifico se c'è da mostrare il percorso verso il veicolo.
      if (dataPar.data && dataPar.data.showRoutePar) {
        this.hideRouteToVehicle();
        this.showRouteToVehicle(dataPar);
      } else {
        this.hideRouteToVehicle();
      }

      // Verifico se è stato prenotato un veicolo per cambiarne l'icona
      if (dataPar.data && dataPar.data.showBookedVehicle) {
        console.log(
          '@@@@@@--->showBookedVehicle: ',
          dataPar.data.showBookedVehicle,
          dataPar.data.deviceIdPar
        );
        this.forceStatusShown(
          Number(dataPar.data.deviceIdPar),
          dataPar.data.deviceCategory,
          VEHICLE_STATUS_BOOKED
        );
      }
    });

    return await popover.present();
  }

  forceStatusShown(deviceId, deviceCategory, statusId) {
    // Cerca il veicolo e cambia l'icona
    const vehicleMarker = this.displayedMarkers.find(
      (x) => Number(x.id) === Number(deviceId)
    );
    console.log(
      '@@@@@@forceStatusShown--->',
      this.devicePositions,
      vehicleMarker
    );
    // const icon = this.getDeviceIconUrlByState(deviceType, statusId);
    const icon = {
      url: this.getDeviceIconUrlByState(deviceCategory, statusId),
      scaledSize: new google.maps.Size(45, 45),
    };
    vehicleMarker.setIcon(icon);
  }

  async presentPopoverHubDetail(hub: Hub, ev: any) {
    const popover = await this.popoverController.create({
      component: HubDetailComponent,
      event: ev,
      translucent: false,
      componentProps: {
        hub_data: JSON.stringify(hub),
      },
      cssClass: 'device-detail-popover',
    });

    popover.onDidDismiss().then((dataPar: OverlayEventDetail) => {
      if (dataPar.data && dataPar.data.showRoutePar) {
        this.hideRouteToVehicle();
        this.showRouteToVehicle(dataPar);
      } else {
        this.hideRouteToVehicle();
      }
    });

    return await popover.present();
  }

  showRouteToVehicle(devicePar) {
    const that = this;

    that.directionsService = new google.maps.DirectionsService();
    that.directionsRenderer = new google.maps.DirectionsRenderer();
    // const userPosition = {lat: 43.7225706, lng: 10.6159556};
    // const vehiclePosition = {lat: 43.7225706, lng: 11.6159556};
    const userPosition = {
      lat: devicePar.data.userLatitudePar,
      lng: devicePar.data.userLongitudePar,
    };
    const vehiclePosition = {
      lat: devicePar.data.vehicleLatitudePar,
      lng: devicePar.data.vehicleLongitude,
    };

    // Collegamento alla mappa visualizzata
    that.directionsRenderer.setMap(this.map);
    that.directionsService.route(
      {
        origin: userPosition,
        destination: vehiclePosition,
        travelMode: 'WALKING',
      },
      function (response, status) {
        if (status === 'OK') {
          // console.log('@@@@@@ OK -->response:', response);
          that.directionsRenderer.setDirections(response);
        } else {
          console.log('@@@@@@@@ Directions request failed due to ' + status);
        }
      }
    );
  }

  hideRouteToVehicle() {
    // console.log('@@@@@ hideRouteToVehicle');
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    }
    this.directionsRenderer = null;
    this.directionsService = null;
  }

  /** Drawing tools **/
  public configureDrawingTools() {
    const context = this;
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      // drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        // drawingModes: ['marker', 'circle', 'polygon', 'polyline', 'rectangle']
        drawingModes: ['marker', 'polygon', 'circle'],
      },
      markerOptions: {
        icon:
          'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
      },
    });
    drawingManager.setMap(this.map);

    // Adding listener to open the form to create/modify/view a Zone
    google.maps.event.addListener(drawingManager, 'polygoncomplete', function (
      polygon
    ) {
      context.chooseEditZoneOrHub(polygon);
    });

    // Adding listener to open the form to create/modify/view a Zone
    google.maps.event.addListener(drawingManager, 'markercomplete', function (
      marker
    ) {
      const cityId = context.currentCityId;
      const coordinates = marker.getPosition();
      const latitude = Number(coordinates.lat());
      const longitude = Number(coordinates.lng());

      context.wsManager
        .checkHubExistence(cityId, latitude, longitude)
        .subscribe(
          (hub) => {
            console.log('Hub exists');
            context.targetHub = hub;
            context.presentEditPopoverHubDetail(
              hub,
              latitude,
              longitude,
              hub.coordinates,
              hub.hubType,
              marker
            );
          },
          (err) => {
            console.log(err);
            console.log('Hub doesn\'t exist');
            context.newHubOverlay.push(marker);
            context.editHub(marker);
          }
        );
    });

    // Adding listener to open the form to create/modify/view a Zone
    google.maps.event.addListener(
      drawingManager,
      'circlecomplete',
      async function (circle) {
        context.showDistanceMessage(circle.getRadius());
        circle.setMap(null);
      }
    );
  }

  async chooseEditZoneOrHub(polygon) {
    const alert = await this.alertController.create({
      header: this.translate.instant(
        'Do you want to draw a simple zone or a hub-zone?'
      ),
      buttons: [
        {
          text: this.translate.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: this.translate.instant('Create a zone'),
          handler: () => {
            console.log('@@@ Create a zone');
            this.newZoneOverlay.push(polygon);
            this.editZonePolyg(polygon);
          },
        },
        {
          text: this.translate.instant('Create a hub-zone'),
          handler: () => {
            const polygonPath = polygon.getPath();
            const coordinatesString = this.fromPolygonToCorrdinatesString(
              polygonPath
            );
            console.log(
              '@@@ Create a hub-zone coordinatesString: ',
              coordinatesString
            );
            this.newHubCoordinates = coordinatesString;
            let lat, lng;
            if (coordinatesString) {
              const bounds = new google.maps.LatLngBounds();
              polygonPath.forEach(point => {
                bounds.extend(point);
              });
              const center = bounds.getCenter();
              lat = center.lat();
              lng = center.lng();
            }
            this.presentEditPopoverHubDetail(
              null,
              lat,
              lng,
              coordinatesString,
              HUB_TYPE_POLYGON,
              null
            );
          },
        },
      ],
    });

    await alert.present();
  }

  /** Zones **/

  public fromPolygonToCorrdinatesString(polygonPath) {
    // Iterate over the vertices and prepare a string with all coordinates
    const coordinatesArray = [];
    for (let i = 0; i < polygonPath.getLength(); i++) {
      const xy = polygonPath.getAt(i);
      coordinatesArray.push(xy.lat() + ',' + xy.lng());
    }
    const coordinatesString = coordinatesArray.join(';');
    return coordinatesString;
  }

  public editZonePolyg(event) {
    const polygonPath = this.newZoneOverlay[0].getPath();
    const coordinatesString = this.fromPolygonToCorrdinatesString(polygonPath);

    this.newZoneCoordinates = coordinatesString;
    const currentCityCoords = this.settingsManager.getActiveCityCoordinates();
    if (currentCityCoords.coordinates) {
      this.presentEditPopoverZoneDetail(null, this.newZoneCoordinates, event);
    } else {
      this.presentEditPopoverCityZoneDetail(
        null,
        this.newZoneCoordinates,
        event
      );
    }
  }

  /** Hubs **/

  public editHub(event) {
    const coordinates = event.getPosition();
    const coordinatesString = [coordinates.lat(), coordinates.lng()].join(';');
    this.newHubCoordinates = coordinatesString;
    this.presentEditPopoverHubDetail(
      null,
      Number(coordinates.lat()),
      Number(coordinates.lng()),
      null,
      HUB_TYPE_CIRCLE,
      event
    );
  }

  public async showDistanceMessage(distance: number) {
    const toast = await this.toastController.create({
      message: 'Distanza: '.concat(distance.toFixed(2)).concat(' metri'),
      translucent: true,
      duration: 4000,
      position: 'bottom',
      mode: 'md',
      cssClass: 'success',
    });
    toast.present();
  }

  // public editZoneRect(event) {
  // }

  /**
   * Shows the page to modify/edit/add a Zone based on the parameters passed to the function.
   *
   *  - if zoneId is passed then it is an EDIT/VIEW zone request.
   *  - if coordinates is passed then it is an ADD zone request.
   *
   * @param zoneId: Id of an existent area.
   * @param coordinates: String that contains the coordinates of a new area.
   * @param ev: event
   *
   */
  async presentEditPopoverZoneDetail(
    zoneId: number = null,
    coordinates = '',
    ev: any = null
  ) {
    let zone = null;
    if (zoneId) {
      zone = this.zonePositions.find((x) => x.id === zoneId);
    }

    const popover = await this.popoverController.create({
      component: ZonedetailPage,
      event: ev,
      translucent: false,
      componentProps: {
        zone: zone ? JSON.stringify(zone) : '',
        zone_coordinates: coordinates ? coordinates : '',
        zone_id: zoneId,
      },
      cssClass: 'zone-detail-popover',
    });

    popover.onDidDismiss().then((reloadParent) => {
      this.removeTempAreadDraw();
      this.redesignZone(reloadParent);
    });
    return await popover.present();
  }

  /**
   * Shows the page to modify/edit/add a Zone based on the parameters passed to the function.
   *
   *  - if zoneId is passed then it is an EDIT/VIEW zone request.
   *  - if coordinates is passed then it is an ADD zone request.
   *
   * @param zoneId: Id of an existent area.
   * @param coordinates: String that contains the coordinates of a new area.
   * @param ev: event
   *
   */
  async presentEditPopoverCityZoneDetail(
    newCity = 0,
    coordinates = '',
    ev: any = null
  ) {
    let city = null;
    if (!newCity) {
      city = this.currentCityData;
    }

    const popover = await this.popoverController.create({
      component: CitydetailPage,
      event: ev,
      translucent: false,
      componentProps: {
        city: city ? JSON.stringify(city) : '',
        city_coordinates: coordinates ? coordinates : '',
        new_city: newCity,
      },
      cssClass: 'zone-detail-popover',
    });

    popover.onDidDismiss().then((reloadParent) => {
      this.removeTempAreadDraw();
      this.redesignZone(reloadParent);
    });
    return await popover.present();
  }

  async presentEditPopoverHubDetail(
    hub: Hub = null,
    latitude: number = null,
    longitude: number = null,
    coordinates = '',
    type: string = HUB_TYPE_CIRCLE,
    ev: any = null
  ) {

    const popover = await this.popoverController.create({
      component: HubEditorComponent,
      event: ev,
      translucent: false,
      componentProps: {
        hub: hub ? JSON.stringify(hub) : '',
        hub_latitude: latitude ? latitude : 0,
        hub_longitude: longitude ? longitude : 0,
        hub_coordinates: coordinates,
        hub_type: type,
        hub_id: hub ? hub.id : null,
      },
      cssClass: 'hub-detail-popover',
    });

    popover.onDidDismiss().then((reloadParent) => {
      this.removeTempHubDraw();
      this.redesignHub(reloadParent);
    });
    return await popover.present();
  }

  /**
   *  delete the hub drawn but not confirmed
   */
  private removeTempHubDraw() {
    if (this.newHubOverlay.length > 0) {
      this.newHubOverlay[0].setMap(null);
      this.newHubOverlay = [];
    }
  }

  /**
   *  Delete the area drawn but not confirmed
   */
  private removeTempAreadDraw() {
    if (this.newZoneOverlay.length > 0) {
      this.newZoneOverlay[0].setMap(null);
      this.newZoneOverlay = [];
    }
  }

  private redesignZone(reloadParent) {
    if (reloadParent) {
      const cityId = Number(this.settingsManager.getCitySetting());

      // Reload  the main zone of the city
      this.wsManager.fetchCityData(cityId).subscribe(
        (data) => {
          console.log('RELOAD-CITY:', data);
          this.addCity(data, true);
        },
        (err) => {
          console.log('ERR-RELOAD-CITY:', err);
        }
      );

      // reload all the other zones of the city
      this.wsManager.fetchZonesByCityId(cityId).subscribe(
        (data) => {
          console.log('RELOAD-ZONE:', data);
          this.addZoneMarkers(data, true);
        },
        (err) => {
          console.log('ERR-RELOAD-ZONE:', err);
        }
      );
    }
  }

  private redesignHub(reloadParent) {
    if (reloadParent) {
      this.addHubMarkers(true);
    }
  }

  /**
   *  Design the zones on the map.
   *
   * @param zones: The data of the zones to be drawn
   * @param editMode: True if the edit of the zone is enabled
   */
  public addZoneMarkers(zones: Array<any>, editMode: boolean): void {
    console.log('addZoneMarkers', zones);
    const context = this;
    context.zonePositions = zones;

    // 1 - Delete the old zones
    if (context.zoneMarkers) {
      for (let i = 0; i < context.zoneMarkers.length; i++) {
        context.zoneMarkers[i].setMap(null);
      }
      context.zoneMarkers = [];
    }

    // 2 - Now we can add the zones
    zones.map(function (zone) {
      const poligCoordinates = context.getCoordinatesByString(zone.coordinates);

      // New Zone marker
      const newZone = new google.maps.Polygon({
        paths: poligCoordinates,
        strokeColor: '#' + zone.color,
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#' + zone.color,
        fillOpacity: 0.2,
        zoneId: zone.id,
        zoneName: zone.name,
        zIndex: zIndexMapping.ZONE + zone.zIndex,
      });

      // Popup di una zona della città corrente in amministrazione
      if (editMode && zone.cityId === context.currentCityId) {
        // Add a listener for the click event
        newZone.addListener('click', function (event) {
          context.presentEditPopoverZoneDetail(newZone.zoneId, '', event);
        });
      }

      // Popup della zona per i clienti o di una zona di una città diversa da quella corrente in amministrazione
      if (!editMode || (editMode && zone.cityId !== context.currentCityId)) {
        newZone.addListener('click', function (event) {
          context.presentPopoverViewArea(false, zone.id, zone);
        });
      }

      newZone.setMap(context.map);
      context.zoneMarkers.push(newZone);

      context.zonePolygons[zone.id] = newZone;
    });
  }

  // Visualizza il popup dell'area di città o della zona (solo clienti)
  async presentPopoverViewArea(
    isCity: boolean,
    itemId: number,
    itemData: object
  ) {
    const popover = await this.popoverController.create({
      component: ViewAreaComponent,
      translucent: false,
      componentProps: {
        isCity: isCity,
        itemId: itemId,
        item_data: JSON.stringify(itemData),
      },
      cssClass: 'area-view-popover',
    });
    return await popover.present();
  }

  public addCities(cities: City[], editMode = false) {
    cities.forEach(city => {
      this.addCity(city, editMode);
    });
  }

  public addCity(city: City, editMode = false) {
    if (city.coordinates) {
      const poligCoordinates = this.getCoordinatesByString(city.coordinates);
      if (city.id in this.cityPolygons) {
        const polygon = this.cityPolygons[city.id];
        polygon.setPaths(poligCoordinates);
      } else {
        const polygon = new google.maps.Polygon({
          paths: poligCoordinates,
          strokeColor: '#' + city.color,
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#' + city.color,
          fillOpacity: 0.2,
          city: city,
          zoneName: 'City',
          zIndex: zIndexMapping.CITY,
        });
        polygon.setMap(this.map);
        if (editMode) {
          polygon.addListener('click', (event) => {
            if (city.id === this.currentCityId) {
              this.presentEditPopoverCityZoneDetail(0, '', event);
            } else {
              this.presentPopoverViewArea(true, city.id, city);
            }
          });
        }
        this.cityPolygons[city.id] = polygon;
      }
    }
    if (city.scooter_coordinates) {
      const poligCoordinates = this.getCoordinatesByString(city.scooter_coordinates);
      if (city.id in this.scooterAreaPolygons) {
        const polygon = this.scooterAreaPolygons[city.id];
        polygon.setPaths(poligCoordinates);
      } else {
        const polygon = new google.maps.Polygon({
          paths: poligCoordinates,
          strokeColor: '#' + city.scooter_area_color,
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: '#' + city.scooter_area_color,
          fillOpacity: 0.2,
          city: city,
          zoneName: 'Scooter Area',
          zIndex: zIndexMapping.CITY,
        });
        polygon.setMap(this.map);
        if (editMode) {
          polygon.addListener('click', (event) => {
            if (city.id === this.currentCityId) {
              this.presentEditPopoverCityZoneDetail(0, '', event);
            } else {
              this.presentPopoverViewArea(true, city.id, city);
            }
          });
        }
        this.scooterAreaPolygons[city.id] = polygon;
      }
    }
  }

  public addDevices(devices: Device[]) {
    this.setDevicesVisibility(false);
    devices.forEach(device => {
      this.addDevice(device);
    });
  }

  public addDevice(device: Device) {
    console.log('@@@ addDevice', device);
    const icon = {
      url: this.getDeviceIconUrl(device),
      scaledSize: new google.maps.Size(45, 45)
    };
    if (device.id in this.deviceMarkers) {
      const deviceMarker = this.deviceMarkers[device.id];
      deviceMarker.setPosition({
        lat: device.latitude,
        lng: device.longitude
      });
      deviceMarker.setIcon(icon);
      deviceMarker.setVisible(true);
      deviceMarker.set('device', device);
    } else {
      const marker = new google.maps.Marker({
        position: {
          lat: device.latitude,
          lng: device.longitude
        },
        title: device.name,
        device: device,
        icon: icon,
        zindex: zIndexMapping.DEVICE
      });
      marker.addListener('click', (event) => {
        this.presentPopoverDeviceDetail(marker.get('device'), event);
      });
      marker.setMap(this.map);
      this.deviceMarkers[device.id] = marker;
    }
  }

  public addHubs(hubs: Hub[], editMode = false) {
    hubs.forEach(hub => {
      this.addHub(hub, editMode);
    });
  }

  public addHub(hub: Hub, editMode = false) {
    let marker;
    let zoneColor;
    let iconUrl;
    if (hub.isVisible) {
      zoneColor = this.defaultHubZonesColor;
      iconUrl = '../../assets/icon/gs-point.png';
    } else {
      zoneColor = this.defaultGsPointColor;
      iconUrl = '../../assets/icon/hub.png';
    }
    const icon = {
      url: iconUrl,
      scaledSize: new google.maps.Size(45, 45),
    };
    const position = {
      lat: hub.latitude,
      lng: hub.longitude
    };
    const poligCoordinates = this.getCoordinatesByString(hub.coordinates);

    if (hub.id in this.hubPolygons) {
      if (hub.hubType === HUB_TYPE_CIRCLE) {
        const polygon = this.hubPolygons[hub.id];
        polygon.setOptions({
          radius: hub.range_meters,
          center: position,
          fillColor: zoneColor,
          strokeColor: zoneColor
        });
      } else {
        const polygon = this.hubPolygons[hub.id];
        polygon.setOptions({
          paths: poligCoordinates,
          strokeColor: zoneColor,
          fillColor: zoneColor
        });
      }
      marker = this.hubMarkers[hub.id];
      marker.setIcon(icon);
    } else {
      let polygon;
      if (hub.hubType === HUB_TYPE_CIRCLE) {
        polygon = new google.maps.Circle({
          strokeColor: zoneColor,
          strokeOpacity: 0.9,
          strokeWeight: 3,
          fillColor: zoneColor,
          fillOpacity: 0.2,
          center: position,
          radius: hub.range_meters,
          zIndex: zIndexMapping.HUB,
        });
      } else {
        polygon = new google.maps.Polygon({
          paths: poligCoordinates,
          strokeColor: zoneColor,
          strokeOpacity: 0.9,
          strokeWeight: 3,
          fillColor: zoneColor,
          fillOpacity: 0.2,
          hub: hub,
          zIndex: zIndexMapping.HUB,
        });
      }
      marker = new google.maps.Marker({
        position: position,
        title: hub.name,
        id: hub.id,
        hub: hub,
        icon: icon,
        zindex: zIndexMapping.HUB,
      });
      polygon.setMap(this.map);
      marker.setMap(this.map);
      polygon.addListener('click', (event) => {
        if (editMode) {
          this.presentEditPopoverHubDetail(hub);
        } else {
          this.presentPopoverHubDetail(hub, event);
        }
      });
      this.hubPolygons[hub.id] = polygon;
      this.hubMarkers[hub.id] = marker;
    }
  }

  public setCitiesVisibility(visibility: boolean) {
    this.setHubMarkersVisibility(visibility);
    _(this.hubPolygons).forEach(poly => poly.setVisible(visibility));
    _(this.zonePolygons).forEach(poly => poly.setVisible(visibility));
    _(this.cityPolygons).forEach(poly => poly.setVisible(visibility));
  }

  public setDevicesVisibility(visibility: boolean) {
    _(this.deviceMarkers).forEach(marker => marker.setVisible(visibility));
  }

  public setHubMarkersVisibility(visibility: boolean) {
    _(this.hubMarkers).forEach(marker => marker.setVisible(visibility));
  }

}
