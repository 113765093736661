import { DeviceCategoryFlags, CategoryFlags, CategoryAggregations } from 'src/app/classes/DeviceCategoryFlags';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

// BS modules
import { ActiveRentalFlatten, ChargeStationData, Device, DeviceStateEnum, Ente, ChargeStationStatus } from '../../classes';
import { TranslateService } from '@ngx-translate/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// BS service providers
import { SettingsmanagerService } from '../../services/settingsmanager.service';
import { WsmanagerService } from '../../services/wsmanager.service';
import { RentalmanagerService } from '../../services/rentalmanager.service';
import { WidgetsmanagerService } from '../../services/widgetsmanager.service';
import { ChangeStatePopoverPage } from 'src/app/managers/change-state-popover/change-state-popover.page';

// BS components
import { EditDeviceInfoComponent } from '../edit-device-info/edit-device-info.component';
import { DeviceService } from 'src/app/services/device.service';
import { HttpErrorResponse } from '@angular/common/http';
import { roundTo } from 'src/app/utils';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModelService } from 'src/app/services/model.service';
import { RentalService } from 'src/app/services/rental.service';
import { commandType } from 'src/app/interfaces/WSInterfaces';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
})
export class DeviceDetailComponent implements OnInit {
  public DeviceCategoryFlags: typeof DeviceCategoryFlags = DeviceCategoryFlags;
  public CategoryFlags: typeof CategoryFlags = CategoryFlags;
  public CategoryAggregations: typeof CategoryAggregations = CategoryAggregations;

  @Output()
  public refresh: EventEmitter<Device> = new EventEmitter<Device>();

  public device: Device;
  public extendedData: Array<{ key: string, value: string}>;
  public sourceImageUrl: SafeUrl;

  public isLoading: boolean;
  public showSection = { info: true, otherInfo: true, commands: true }

  public assignneeEnte: Ente;
  public activeRental: ActiveRentalFlatten = null;
  public deviceImagePath: string = null;
  public spinnerVisible  = false;
  public statusString: string = null;
  public ringBellButtonDisabled = false;
  public rentDeviceButtonDisabled = false;
  public bookingDeviceButtonDisabled = false;
  public commandButtonDisabled = false;
  public ConfirmMsg: string = null;
  public ErrorMsg: string = null;
  private userCoordinates = { userLat: 0, userLng: 0 };
  public showCustomerData =  false;
  public showChargerData =  false;
  public showOperatorData = false;
  public showManagersData =  false;
  public showCityManagerData =  false;
  public deviceDistanceFlagDisable = true;
  public distanceFromUser = 0;


  public commandType: typeof commandType;

  constructor(
    private translate: TranslateService,
    private settingsManager: SettingsmanagerService,
    private wsManager: WsmanagerService,
    private _rentalSvc: RentalService,
    private _modelSvc: ModelService,
    private widgetManager: WidgetsmanagerService,
    private rentalManager: RentalmanagerService,
    private geolocation: Geolocation,
    private popoverController: PopoverController,
    private _deviceSvc: DeviceService,
    private _sanitizer: DomSanitizer,
    private _navParams: NavParams,
  ) {
    this.commandType = commandType;
    this.isLoading  = false;
    this.ConfirmMsg = this.translate.instant('Operation successfully completed');
    this.ErrorMsg = this.translate.instant('Error while completing operation');
    this.loadDeviceDetail(this._navParams.data.deviceId);
  }

  /***********************************************************
    eventi componente
  ************************************************************/

  ngOnInit(): void {
    console.log('@ngOnInit-DEVICE');

    const geo_options = this.settingsManager.getDefaultGeolocationOptions();
    this.geolocation.getCurrentPosition(geo_options).then((position) => {
      this.userCoordinates = {
        userLat: position.coords.latitude,
        userLng: position.coords.longitude
      };
    }, (err) => {
      console.log(err);
    });
    
    // this.deviceImagePath = '../../assets/img/' + this.device.model + '.jpg';
    
    console.log('@ngOnInit-DEVICE', this.device, this.rentalManager.rentedVehicleId);
    
    // You can't book a vehicle if you already have a booking active
    this.bookingDeviceButtonDisabled =  this.rentalManager.bookedVehicleId.getValue() == null ? false : true;
    this.ringBellButtonDisabled = this.rentDeviceButtonDisabled;

  }

  /*****************************************************
    chiamate  server
  ******************************************************/

  public loadDeviceDetail(id: number): void {
    this.isLoading = true;
    this._deviceSvc.getDeviceDetail(id).subscribe((objDevice => {
      this.device = objDevice;
      this.rentDeviceButtonDisabled = this.device.stateId !== DeviceStateEnum.IDLE || this.rentalManager.rentedVehicleId.getValue() !== null;
      this.loadImageModel(this.device.modelId);
      this._getActiveRental(this._navParams.data.deviceId);
      this.isLoading = false;
    }), 
    (err: HttpErrorResponse) => {
      console.log(err);
    });
  }

  public loadExtendedData(): void {
    this.isLoading = true;
    this._deviceSvc.getDeviceExtendedData(this.device.id).subscribe(obj => {
      this.extendedData = [];
      Object.keys(obj).forEach(key => { this.extendedData.push({key: key, value: obj[key]}) });
      this.isLoading = false;
    }, 
    (err: HttpErrorResponse) => {
      this.isLoading = false;
      console.log(err);
    });
  }

  public loadImageModel(modelId: number): void {
    this._modelSvc.downloadImage(modelId).subscribe(async (data) => {
      this.sourceImageUrl = this._sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(data));
    });
  }

  private _getActiveRental(deviceId: number) {
    this.activeRental = undefined;
    this.wsManager.getActiveRentalByDevice(deviceId).subscribe((data) => {
      this.activeRental = data;
    });
  }

  public showHideSection(cod: string): void {
    this.showSection[cod] = !this.showSection[cod];
  }

  refreshStatus(deviceId: number): void {
    console.log('Refresh status for device ', deviceId);
    this.loadDeviceDetail(deviceId);
  }

  getEnte(enteId: number) {
    this.wsManager.enteGetDetails(enteId).subscribe(
      (det) => { this.assignneeEnte = det; },
      () => { this.assignneeEnte = null; }
    );
  }

  closePopover(showRoute = false, deviceId = null, showBookedVehicle = false) {
    this.refreshStatus(this.device.id);
    const dataPar = {
      showBookedVehicle: showBookedVehicle,
      showRoutePar: showRoute,
      deviceIdPar: deviceId,
      deviceType: this.device.deviceCategory,
      vehicleLatitudePar: this.device.latitude,
      vehicleLongitude: this.device.longitude,
      userLatitudePar:  this.userCoordinates.userLat,
      userLongitudePar:  this.userCoordinates.userLng
    };
    this.popoverController.dismiss(dataPar);
  }

  ringBell(deviceId: number): void {
    this.ringBellButtonDisabled =  true;
    this.rentDeviceButtonDisabled =  true;
    this.spinnerVisible = true;
    const ringTime = this.settingsManager.getRingBellMillisec();
    setTimeout( () => {
      this.spinnerVisible = false;
      this.ringBellButtonDisabled =  false;
      this.rentDeviceButtonDisabled = this.device.stateId !== DeviceStateEnum.IDLE;
    }, ringTime);
    this.wsManager.ringBellByDeviceId(deviceId).subscribe(data => {
      console.log('@@@ringBellByDeviceId-data:', data);
    }, err => {
      console.log('ERR-POSITIONS3:', err.message);
    });
  }

  sendCommand(deviceId: number, commandTypeId: number) {
    this.spinnerVisible = true;
    this.wsManager.sendCommand(
        deviceId,
        commandTypeId
    ).subscribe(data => {
      console.log(data);
      this.spinnerVisible = false;
    }, err => {
      this.spinnerVisible = false;
    });
  }

  stopRental(deviceId: number): void {
    this.spinnerVisible = true;
    this._rentalSvc.stopRentalAdmin(deviceId).subscribe(res => {
      this.widgetManager.showToastMessage('success', this.ConfirmMsg);
      this.refreshStatus(this.device.id);
      this.spinnerVisible = false;
    }, err => {
      this.widgetManager.showToastMessage('danger', this.ErrorMsg);
      this.spinnerVisible = false;
    });
  }

  changeDeviceState(deviceId: number, deviceName: string, stateId: number) {
    this.spinnerVisible = true;
    this.wsManager.changeDeviceState(
        deviceId,
        deviceName,
        stateId
    ).subscribe(data => {
      console.log(data);
      this.spinnerVisible = false;
      const confirmMsg = this.translate.instant('State successfully updated');
      this.widgetManager.showToastMessage('success', confirmMsg);
      this.closePopover();
    }, err => {
      this.spinnerVisible = false;
      const errorMsg = this.translate.instant('Error while updating device state');
      this.widgetManager.showToastMessage('danger', errorMsg);
    });
  }

  actionGoToLog(deviceName): void {
    if (deviceName !== null) {
      const url = 'managers/manage-log/' + deviceName ;
      window.open(url, 'anotherwindow', 'toolbar=0,location=0,menubar=0');
      // this.navCtrl.navigateForward(['managers', 'manage-log', deviceName]);
    }
  }

  actionRequireAction(deviceName): void {
    this.presentAlertPromptNewRequest(deviceName);
  }

  async presentAlertPromptNewRequest(deviceName) {
    const header = this.translate.instant('Require new action');
    const inputs = [
      {
        name: 'deviceName',
        id: 'deviceName',
        type: 'text',
        value: deviceName,
      },
      {
        name: 'description',
        id: 'description',
        type: 'text',
        placeholder: this.translate.instant('Description'),
      }
    ];
    const buttons = [
      {
        text:  this.translate.instant('Cancel'),
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      }, {
        text:  this.translate.instant('Add'),
        cssClass: 'primary',
        handler: (data) => {
          // Creazione del guasto
          if (data.deviceId === '') {
            const message = this.translate.instant('Field is required.');
            this.widgetManager.showToastMessage('danger', message, 2000);
          } else {
            this.wsManager.newOperationRequest(data)
                .subscribe(res => {
                  const message = this.translate.instant('Request added.');
                  this.widgetManager.showToastMessage('success', message, 2000);
                }, (err) => {
                  console.log(err);
                  const message = this.translate.instant('Error adding the request');
                  this.widgetManager.showToastMessage('danger', message, 2000);
                });
          }
        }
      }
    ];

    this.widgetManager.showAlert(header, "", buttons, inputs);
  }

  actionChangeDeviceState(deviceId, deviceName, deviceState): void {
    this.presentEditDevicePopover(deviceId, deviceName, deviceState);
  }

  async presentEditDevicePopover(deviceId, deviceName, deviceState) {

    const popover = await this.popoverController.create({
      component: ChangeStatePopoverPage,
      translucent: false,
      componentProps: {
        deviceId: deviceId,
        deviceName: deviceName,
        deviceState: deviceState
      }
    });

    popover.onDidDismiss()
     .then( () => {
       this.refreshStatus(this.device.id);
     });
    return await popover.present();
  }

  async presentEditConfigDevicePopover() {

    const deviceUpdated = new EventEmitter<Device[]>();
    const closeEvent = new EventEmitter();
    
    const popover = await this.popoverController.create({
      component: EditDeviceInfoComponent,
      componentProps: {
        device: this.device,
        deviceUpdated: deviceUpdated,
        closeEvent: closeEvent
      }
    });
    deviceUpdated.subscribe((device: Device) => {
      this.refreshStatus(this.device.id);
    });
    closeEvent.subscribe(() => {
      popover.dismiss();
    })

    await popover.present();
  }

  drawRoute(deviceId) {
    this.spinnerVisible = true;
    setTimeout( () => {
      this.spinnerVisible = false;
      // Chiudi il popover passando al chiamante i parametri per mostrare la strada
      this.closePopover(true, deviceId);
    }, 1000);
  }

  /*******************************************************
    altro
  ********************************************************/

  public getLabelDeviceState(): string {
    return this.translate.instant(Device.getLabelDeviceState(this.device.stateId));
  }

  public getLabelDeviceType(): string {
    return this.translate.instant(Device.getLabelDeviceType(this.device.deviceCategory));
  }

  public getLabelStatusChrSt(cod: ChargeStationStatus): string {
    return cod ? this.translate.instant(ChargeStationData.getLabelStatus(cod)) : '';
  }

  public roundTo(num: number): number {
    return roundTo(num);
  }
}
