export enum CategoryFlags {
  NONE = 0,
  SCOOTER = 1 << 0, // 1
  BIKE = 1 << 1, // 2
  MOPED = 1 << 2, // 4
  MOTORBIKE_A1 = 1 << 3, // 8
  MOTORBIKE_A2 = 1 << 4, // 16
  MOTORBIKE_A3 = 1 << 5, // 32
  MICRO_CAR = 1 << 6, // 64
  SMALL_CAR = 1 << 7, // 128
  CAR = 1 << 8, // 256
  CHARGER_STATION = 1 << 9 // 512
}
 
export class DeviceCategoryFlags {
  static ALL: CategoryFlags = ~(~0 << 10);
 
  static isType(inputFlag: CategoryFlags, category: CategoryFlags | CategoryAggregations): boolean {
    console.log('istype  inputFlag: ', inputFlag , 'category: ', category , ' (inputFlag & category) !== 0: ', (inputFlag & category) !== 0)
    return (inputFlag & category) !== 0;
  }
 
}
 
export enum CategoryGroups {
  BIKES = CategoryFlags.BIKE,
  SCOOTERS = CategoryFlags.SCOOTER,
  MOPEDS = CategoryFlags.MOPED | CategoryFlags.MOTORBIKE_A1 | CategoryFlags.MOTORBIKE_A2,
  MOTORBIKES = CategoryFlags.MOTORBIKE_A3,
  MICRO_CARS = CategoryFlags.MICRO_CAR,
  CARS = CategoryFlags.CAR | CategoryFlags.SMALL_CAR
}

export enum CategoryAggregations {
  VELOCIPEDES = CategoryFlags.BIKE | CategoryFlags.SCOOTER,
  MOTORCYCLES = CategoryFlags.MOPED | CategoryFlags.MOTORBIKE_A1 | CategoryFlags.MOTORBIKE_A2 | CategoryFlags.MOTORBIKE_A3,
  CARS = CategoryFlags.CAR | CategoryFlags.SMALL_CAR | CategoryFlags.MICRO_CAR,

  DRIVER_DEPENDANT = DeviceCategoryFlags.ALL - (CategoryFlags.CHARGER_STATION + VELOCIPEDES), //508

  CITY_ONLY_VEHICLES = VELOCIPEDES | CategoryFlags.MOPED | CategoryFlags.MICRO_CAR,
  OUTSIDE_CITY_VEHICLES = DeviceCategoryFlags.ALL - CITY_ONLY_VEHICLES, //952

  CAR = CategoryFlags.CAR | CategoryFlags.SMALL_CAR,

  LIGHT_MOTORCYCLE = CategoryFlags.MOPED | CategoryFlags.MOTORBIKE_A1 | CategoryFlags.MOTORBIKE_A2
}

