
import {
  IOperator,
  IDevice,
  IRentalReport,
  IRentalStatus,
  ICustomerProfile,
  ITripReport,
  IEvent,
  IVoucher,
  IRecharge,
  IUserDocument,
  ISelectOption,
  INotification,
  ISeasonTicket,
  IUserSubscription,
  ITransactionData,
  IWarningType,
  IReport,
  IReportStats,
  IBookingStatus,
  IBooking,
  IBookingDev,
  IRechargeActive,
  IChargerInfo,
  ICoordinates,
  IMobileTests,
  IEventType,
  IVoucherType,
  IBlacklistedCard,
  IRentalsByCity,
  IStatistics,
  IFault,
  IPromotion,
  IUserPromotion,
  ITimeStatistics,
  IPricingPlan,
  ISetting,
  IOperation,
  IOperationDevice,
  IStateTransition,
  IAlarmActive,
  IAlarmType,
  commandType,
  ICommand,
  IPenaltyRegistry,
  measureUnitEnum,
  IFcmTopic,
  IFcmMessage,
  IHistoryStatistics,
  ITransactionStatistics,
  IRentalStatistics,
  IRentalActive
} from './../interfaces/WSInterfaces';
import * as _ from 'lodash';
import { Device } from './Device';
import { User } from './User';
import { TimeSlot } from './TimeSlot';
import { CategoryFlags } from './DeviceCategoryFlags';

/*
Classi per importazione dati da WS
 */

export class Operator implements IOperator {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  attributes: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

export class Zone {
  cityId: number;
  color: string;
  commandTypeId: number;
  coordinates: string;
  creation_time: string;
  description: string;
  discount_on_fee: number;
  discount_on_total: number;
  free_unlock: boolean;
  id: number;
  name: string;
  park_enabled: boolean;
  isVisible: boolean;
  warning_chiusura_attivo: boolean;
  area: number;
  categoryFlags: CategoryFlags;
  zIndex: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

export class City {
  id: number;
  name: string;
  description: string;
  coordinates: string;
  discount_on_active_fee: number;
  discount_on_break_fee: number;
  discount_on_unlock_fee: number;
  discount_on_booking_fee: number;
  commandTypeId: number;
  color: string;
  park_enabled: boolean;
  center_latitude: number;
  center_longitude: number;
  code: number;
  rule1: string;
  rule2: string;
  rule3: string;
  isVisible: boolean;
  avviso_sanzioni_attivo: boolean;
  warning_chiusura_attivo: boolean;
  isDocumentRequired: boolean;
  isFullSubscriptionRequired: boolean;
  area: number;
  pricingPlanId: number;
  pricingPlan: PricingPlan;
  pricingPlanName: string;
  distance: number;
  your_active_fee: number;
  your_break_fee: number;
  your_unlock_fee: number;
  your_booking_fee: number;
  scooter_coordinates: string;
  scooter_area: number;
  scooter_area_color: string;
  scooterauto_parking_enabled: number; 
  availability: boolean;

  assignedUsers: User[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.pricingPlanName = this.pricingPlan.name;
  }

}

export class RentalReport implements IRentalReport {
  rentalId: number;
  price: number;
  trip_time_sec: number;
  break_time_sec: number;
  total_time_sec: number;
  booking_time_sec: number;
  start_time: string;
  stop_time: string;
  rental_history_id: number;
  deviceId: number;
  subscription: boolean;
  time_voucher_reduction: number;
  money_voucher_reduction: number;
  zone: string;
  discount_on_total: number;
  discount_on_fee: number;
  unlock_fees: number;
  booking_price: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class RentalStatus implements IRentalStatus {
  start_time: string;
  deviceId: number;
  stateId: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

// export class BookingDev implements IBookingDev {
//   id: number;
//   start_time: string;
//   userId: number;
//   deviceId: number;
// }

export class BookingStatus implements IBookingStatus {
  booking: IBookingDev;
  device: IDevice;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class CustomerProfile implements ICustomerProfile {
  id: number;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  email: string;
  attributes: string;
  blocked: number;
  email_confirmed: number;
  roleId: number;
  gender: string;
  birth_date: string;
  birth_place: string;
  cap: string;
  createdAt: string;
  updatedAt: string;
  subscribed: boolean;
  subscription_type: number;
  subscription_creation: string;
  subscription_expiration: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

export class TripInfo implements ITripReport {
  id: number;
  name: string;
  userId: number;
  start_time: string;
  stop_time: string;
  break_time: number;
  deviceId: number;
  photoFilename: string;
  firstName: string;
  lastName: string;
  social_security_number: string;
  telephone: string;
  address: string;
  email: string;
  blocked: number;
  email_confirmed: number;
  roleId: number;
  createdAt: string;
  city: string;
  imei: number;
  protocol: string;
  model: string;
  contact: string;
  cityId: number;
  start_latitude: number;
  start_longitude: number;
  stop_latitude: number;
  stop_longitude: number;
  tripDist_km: number;
  amount: number;
  trip_time_sec: number;
  tripTime: string;
  searchString: string;

  user: User;
  device: Device;
  deviceCategory: CategoryFlags;

  active_price: number;
  break_price: number;
  unlock_price: number;
  booking_price: number;
  voucher_reduction: number;
  promotion_reduction: number;
  subscription_reduction: number;

  trip_start_date: string;
  trip_start_time: string;
  trip_stop_date: string;
  trip_stop_time: string;

  usage_conditions_accepted: boolean;
  privacy_conditions_accepted: boolean;
  unfair_terms_accepted: boolean;
  currentAppVersion: string;
  currentOS: string;

  enteName: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.device) {
      this.device = new Device(this.device);
      this.deviceId = this.device.id;
      this.name = this.device.name;
    }
    if (this.user) {
      this.user = new User(this.user);
      this.userId = this.user.id;
      this.email = this.user.email;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
    }
    const startDate = new Date(this.start_time);
    const stopDate = new Date(this.stop_time);

    this.searchString = `${this.id} ${this.userId} ${this.deviceId} ${this.name} ${this.email} ${this.firstName} ${this.lastName} ${this.start_time}`;
    this.trip_time_sec = Math.round((stopDate.getTime() - startDate.getTime()) / 1000);
    this.deviceId = Number(this.deviceId);
    this.id = Number(this.id);

    const hours = Math.floor(this.trip_time_sec / 3600);
    const minutes = Math.floor((this.trip_time_sec - (hours * 3600)) / 60);
    const seconds = this.trip_time_sec - (hours * 3600) - (minutes * 60);

    // tslint:disable-next-line:prefer-const
    let hours_str, min_str, sec_str;

    if (hours < 10) {
      hours_str = '0'.concat(hours.toString());
    } else {
      hours_str = hours.toString();
    }

    if (minutes < 10) {
      min_str = '0'.concat(minutes.toString());
    } else {
      min_str = minutes.toString();
    }

    if (seconds < 10) {
      sec_str = '0'.concat(seconds.toString());
    } else {
      sec_str = seconds.toString();
    }

    this.tripTime = hours_str.concat(':').concat(min_str).concat(':').concat(sec_str);

    this.trip_start_date = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
    this.trip_stop_date = stopDate.getDate() + '/' + (stopDate.getMonth() + 1) + '/' + stopDate.getFullYear();

    this.trip_start_time = startDate.getHours() + ':' + startDate.getMinutes() + ':' + startDate.getSeconds();
    this.trip_stop_time = `${stopDate.getHours().toString().padStart(2, '0')}:${stopDate.getMinutes().toString().padStart(2, '0')}:${stopDate.getSeconds().toString().padStart(2, '0')}`;

    // ente
    if (values['user'] && values['user']['ente'])
      this.enteName = values['user']['ente']['name'];
  }
}

export class EventType implements IEventType {
  id: number;
  name: string;
  description: string;
  attributes: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Event implements IEvent {
  id: number;
  event_data: string;
  device_time: string;
  deviceId: number;
  imei: string;
  name: string;
  device: Device;
  eventType: EventType;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class VoucherType implements IVoucherType {
  id: number;
  name: string;
  description: string;
  unit_of_measure: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Voucher implements IVoucher {
  id: number;
  voucher_code: string;
  value: number;
  isValid: boolean;
  release_date: string;
  expiration_date: string;
  invocation_date: string;
  city: City;
  user: User;
  searchString: string;
  voucherType: VoucherType;
  remaining_value: number;
  isFree: boolean;
  preassignedTo: string;
  pricingPlan: PricingPlan;
  pricingPlanName: string;

  voucherTypeName: string;
  cityName = "";

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (this.city) {
      this.cityName = this.city.name;
    }

    if (this.user === null) {
      this.isFree = true;
      this.searchString = `${this.voucher_code} ${this.cityName} ${this.release_date} ${this.expiration_date}`;
    } else {
      this.isFree = false;
      // tslint:disable-next-line:max-line-length
      this.searchString = `${this.voucher_code} ${this.user.email} ${this.user.firstName} ${this.user.lastName} ${this.cityName} ${this.release_date} ${this.expiration_date}`;
    }

    if (this.pricingPlan === null) {
      this.pricingPlanName = '';
    } else {
      this.pricingPlanName = this.pricingPlan.name;
    }

    this.voucherTypeName = this.voucherType.name;
  }
}

export class ActiveRentalFlatten {
  id: number;
  name: string;
  start_time: string;
  userId: number;
  deviceId: number;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  telephone: string;
  address: string;
  roleId: number;
  blocked: boolean;
  email_confirmed: boolean;
  social_security_number: string;
  break: boolean;
  subscription: boolean;
  createdAt: string;
  startedAt: string;
  searchString: string;
  usage_conditions_accepted: boolean;
  privacy_conditions_accepted: boolean;
  unfair_terms_accepted: boolean;
  currentAppVersion: string;
  currentOS: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.name} ${this.email} ${this.firstName} ${this.lastName} ${this.city}`;
  }
}

export class RentalActive implements IRentalActive {
  id: number;
  start_time: Date;
  start_latitude: number;
  start_longitude: number;
  city: string;
  transactionId: number;
  transaction: TransactionData;
  userId: number;
  user: User;
  deviceId: number;
  device: Device;
  currentAppVersion: string;
  currentOS: string;
  bookingHistoryId: number;
  deviceCategory: CategoryFlags;
  bookingHistory: Booking;
  breaks: Break[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.start_time = new Date(this.start_time);
    this.breaks = this.breaks.map(x => new Break(x));
  }

  isInBreak(): boolean {
    if (this.breaks){
      return this.breaks.filter(x => x.duration == null).length > 0;
    } else {
      return false;
    }
  }

  sortedBreaks(): Break[] {
    return this.breaks.sort((x, y) => x.start_time.getTime() - y.start_time.getTime())
  }
}

export class Break {
  id: number;
  start_time: Date;
  duration: number;
  rentalId: number;
  rental: RentalActive;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.start_time = new Date(this.start_time);
  }
}

export class Recharge implements IRecharge {
  id: number;
  start_time: string;
  release_time: string;
  userId: number;
  deviceId: number;
  hubId: number;
  email: string;
  telephone: string;
  firstName: string;
  lastName: string;
  imei: string;
  model: string;
  searchString: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.start_time} ${this.release_time} ${this.userId} ${this.deviceId} ${this.email} ${this.imei}`;
  }
}


export class SelectOption implements ISelectOption {
  value: number;
  label: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class UserDocument implements IUserDocument {
  id: number;
  isValid: boolean;
  submission_date: string;
  expiration_date: string;
  path: string;
  filename: string;
  mimetype: string;
  md5: string;
  size: number;
  documentTypeId: number;
  userId: number;
  released_by: string;
  doc_number: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Notification implements INotification {
  id: number;
  text: string;
  datetime: string;
  userId: string;
  visualized: boolean;
  searchString: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.text} ${this.datetime} ${this.userId} ${this.visualized}`;
  }
}

export class Hub {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  description: string;
  attributes: string;
  devices: number;
  desired_devices: number;
  cityId: number;
  searchString: string;
  isVisible: boolean;
  range_meters: number;
  discount_on_active_fee: number;
  discount_on_break_fee: number;
  discount_on_unlock_fee: number;
  categoryFlags: CategoryFlags
  coordinates: string;
  hubType: string;
  area: number;
  isHubToHub: boolean;

  constructor(values: unknown = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.name} ${this.description} ${this.cityId}`;
  }
}

export enum SeasonTicketTypeEnum {
  Subscription = 'Subscription',
  SubscriptionPacket = 'SubscriptionPacket',
  Daily = 'Daily',
  SingleRental = 'SingleRental'
}

export class SeasonTicketType {
  id: number;
  name: string;
  description: string;
  price: number;
  duration_hours: number;
  creation_date: string;
  isValid: boolean;
  type: SeasonTicketTypeEnum;
  unlocks: number;
  freeUnlocks: boolean;
  seconds: number;
  discount: number;
  included_kms: number;
  isRenewAutomatic: boolean;
  categoryFlags: CategoryFlags;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  static getTypeLabel(value: SeasonTicketTypeEnum): string {
    switch (value) {
      case SeasonTicketTypeEnum.Subscription:
        return 'enum.seasonTicketType.Subscription';
      case SeasonTicketTypeEnum.SubscriptionPacket:
        return 'enum.seasonTicketType.SubscriptionPacket';
      case SeasonTicketTypeEnum.Daily:
        return 'enum.seasonTicketType.Daily';
      case SeasonTicketTypeEnum.SingleRental:
        return 'enum.seasonTicketType.SingleRental';
      default:
        return '-';
    }
  }
}

export class SeasonTicket implements ISeasonTicket {
  id: number;
  creation_date: string;
  expiration_date: string;
  ticketType: SeasonTicketType;
  user: User;
  userId: number;
  searchString: string;

  priceString: string;
  ticketTypeType: SeasonTicketTypeEnum;
  ticketTypeName: string;
  userMail: string;
  ticketTypeHours: number;
  subscriptionPrice: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = '';

    if (this.user) {
      this.userMail = this.user.email;
      this.searchString += `${this.user.email} ${this.user.firstName} ${this.user.lastName}`;
    }

    if (this.ticketType) {
      this.searchString += ` ${this.ticketType.name}`;
      this.priceString = this.ticketType.price.toFixed(2) + ' €';
      this.ticketTypeName = this.ticketType.name;
      this.ticketTypeType = this.ticketType.type;
      this.ticketTypeHours = this.ticketType.duration_hours;
      this.subscriptionPrice = this.ticketType.price;
    }
    this.userId = Number(this.userId);

  }
}

export class UserSubscription implements IUserSubscription {
  id: number;
  creation_date: string;
  expiration_date: string;
  name: string;
  description: string;
  price: number;
  duration: number;
  isActive: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export enum paymentMethodEnum {
  PAYPAL = 'PAYPAL',
  WALLET = 'WALLET',
  MERCURY = 'MERCURY'
}

export enum ExportStateEnum {
  Exported = 'Exported',
  NotExported = 'NotExported',
  ExportError = 'ExportError'
}

export class TransactionData implements ITransactionData {
  id: number;
  creation_date: string;
  userId: number;
  code: string;
  paymentid: string;
  description: string;
  authorizationcode: string;
  rrn: string;
  result: string;
  errorcode: string;
  errormessage: string;
  type: string;
  amount: number;
  payment_method: paymentMethodEnum;
  social_security_number: string;
  telephone: string;
  address: string;
  blocked: boolean;
  roleId: number;
  gender: string;
  createdAt: string;
  searchString: string;
  amountFloat: string;
  rentalHistoryId: number;
  reason: string;
  parentId: number;
  user: User;
  rentalHistory: Rental;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userTelephone: string;
  userAddress: string;
  userCity: string;
  rentalStartTime: string;
  rentalStopTime: string;
  userBlocked: boolean;
  merchantOrderId: string;
  city: City;
  transactionDate: string;
  transactionTime: string;
  rental_time: string;
  rentalCity: string;
  action: string;
  exportState: ExportStateEnum

  constructor(values: Object = {}) {
    Object.assign(this, values);
    // tslint:disable-next-line:max-line-length
    this.searchString = `${this.id} ${this.creation_date} ${this.amount} ${this.userEmail} ${this.userFirstName} ${this.userLastName} ${this.code} ${this.paymentid} ${this.result} ${this.errorcode} ${this.amount} ${this.rentalHistoryId} ${this.type}`;
    this.amountFloat = this.amount.toFixed(2).concat(' €');
    // tslint:disable-next-line:max-line-length

    const creation_date = new Date(this.creation_date);
    this.transactionDate = creation_date.getDate() + '/' + (creation_date.getMonth() + 1) + '/' + creation_date.getFullYear()
    this.transactionTime = creation_date.getHours() + ':' + creation_date.getMinutes() + ':' + creation_date.getSeconds();

    if (this.rentalStopTime != null && this.rentalStartTime != null) {
      // tslint:disable-next-line:max-line-length
      const sec_num = Math.round(new Date(this.rentalStopTime).getTime() / 1000 - new Date(this.rentalStartTime).getTime() / 1000);
      const hours = Math.floor(sec_num / 3600);
      const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      const seconds = sec_num - (hours * 3600) - (minutes * 60);

      let hoursStr, minutesStr, secondsStr;
      if (hours < 10) { hoursStr = '0' + hours; } else { hoursStr = hours; }
      if (minutes < 10) { minutesStr = '0' + minutes; } else { minutesStr = minutes; }
      if (seconds < 10) { secondsStr = '0' + seconds; } else { secondsStr = seconds; }
      this.rental_time = hoursStr + ':' + minutesStr + ':' + secondsStr;

    } else {
      this.rental_time = '';
      this.rentalCity = '';
    }
  }
}

export class WarningType implements IWarningType {
  id: number;
  name: string;
  description: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Rental {
  id: number;
  start_time: Date;
  stop_time: Date;
  break_time: number;
  photoFilename: string;
  attributes: string;
  start_latitude: number;
  start_longitude: number;
  stop_latitude: number;
  stop_longitude: number;
  amount: number;
  city: string;
  userId: number;
  user: User;
  deviceId: number;
  device: Device;
  bookingHistory: Booking;
  tripDist_km: number;
  currentOS: string;
  currentAppVersion: string;
  hasPhoto: boolean;
  unlock_price: number;
  active_price: number;
  break_price: number;
  booking_price: number;
  km_price: number;
  subscription_reduction: number;
  voucher_reduction: number;
  promotion_reduction: number;
  daily_fee_reduction: number;

  constructor(values: any) {
    if (!values) {
      return;
    }
    Object.assign(this, values);
    if (values.start_time) {
      this.start_time = new Date(values.start_time);
    }
    if (values.stop_time) {
      this.stop_time = new Date(values.stop_time);
    }
  }

}

export class Warning {
  id: number;
  submission_date: string;
  photo_path: string;
  filename: string;
  mimetype: string;
  md5: string;
  size: number;
  description: string;
  managed: boolean;
  userId: number;
  user: User;
  device: Device;
  deviceId: number;
  deviceName: string;
  warningtypes: WarningType[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.user) {
      this.user = new User(this.user);
    }
  }
}

export class Report implements IReport {
  id: number;
  mark: number;
  description: string;
  submission_date: string;
  fault: WarningType;
  rental: Rental;
  searchString: string;

  rentalId: number;
  userMail: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.mark} ${this.rental.userId}`;

    this.rentalId = Number(this.rental.id);
    this.userMail = this.rental.user.email;
  }
}

export class ReportStats implements IReportStats {
  rentals_num: number;
  reports_num: number;
  avg_mark: number;
  star_0: number;
  star_1: number;
  star_2: number;
  star_3: number;
  star_4: number;
  star_5: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class RechargeActive implements IRechargeActive {
  id: number;
  start_time: string;
  userId: number;
  deviceId: number;
  hubId: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class ChargerInfo implements IChargerInfo {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  city: string;
  social_security_number: string;
  telephone: string;
  address: string;
  attributes: string;
  createdAt: string;
  charger_enabled: boolean;
  active_recharges: RechargeActive[];
  searchString: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.firstName} ${this.lastName} ${this.email} ${this.email}`;
  }
}

export class Coordinates implements ICoordinates {
  public latitude: number;
  public longitude: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class MobileTests implements IMobileTests {
  cameraEnabled: boolean;
  cameraAuthorized: boolean;
  gpsLocationEnabled: boolean;
  gpsLocationAuthorized: boolean;
}

export class BlacklistedCard implements IBlacklistedCard {
  paymentMethodId: number;
  owner_firstName: string;
  owner_lastName: string;
  registration_date: string;
  card_number: string;
  expiryMonth: number;
  expiryYear: number;
  email: string;
  registeredBy: string;
  user_blocked: boolean;
  userId: number;
  blacklistedAt: string;
  blacklistedCardId: number;
  searchString: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.owner_firstName} ${this.owner_lastName} ${this.email} ${this.userId}`;
  }
}

export class RentalsByCity implements IRentalsByCity {
  rentals: number;
  city: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Statistics implements IStatistics {
  online_devices: number;
  offline_devices: number;
  devices_idle: number;
  devices_booked: number;
  devices_on_trip: number;
  devices_suspended: number;
  devices_battery_low: number;
  devices_on_charge: number;
  devices_assigned_to_charger: number;
  signed_users: number;
  active_users: number;
  deleted_users: number;
  unconfirmed_users: number;
  new_users_today: number;
  new_users_week: number;
  active_rentals: number;
  number_of_rentals: number;
  avg_rental_time: number;
  avg_trip_km: number;
  daily_revenue: number;
  weekly_revenue: number;
  monthly_revenue: number;
  daily_revenue_trend: number;
  weekly_revenue_trend: number;
  monthly_revenue_trend: number;
  avg_rental_revenue: number;
  faulty_devices: number;
  open_faults: number;
  closed_faults: number;
  maintenance_price: number;
  lost_devices: number;
  rentals_by_city: RentalsByCity[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class TransactionStatistics implements ITransactionStatistics {
  date: Date;
  count: Number;
  city: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.count = Number(this.count);
  }
}

export class RentalStatistics implements IRentalStatistics {
  date: Date;
  amount: Number;
  city: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class HistoryStatistics implements IHistoryStatistics {
  transactions: TransactionStatistics[];
  rentals: RentalStatistics[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class TimeStatistics implements ITimeStatistics {
  new_users: number;
  deleted_users: number;
  number_of_rentals: number;
  revenue: number;
  rentals_by_city: RentalsByCity[];
  new_faults: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Fault implements IFault {
  id: number;
  description: string;
  latitude: number;
  longitude: number;
  odometerValue: number;
  createdAt: string;
  closed: boolean;
  closedAt: string;
  price: number;
  attributes: string;
  comments: string;
  city: string;
  device: Device;
  technician: string;
  searchString: string;
  deviceId: number;
  deviceName: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.device) {
      this.device = new Device(this.device);
      this.deviceId = this.device.id;
      this.deviceName = this.device.name;
    }
    this.searchString = `${this.id} ${this.deviceId} ${this.description} ${this.city} ${this.technician} ${this.comments}`;
  }
}

export class Promotion implements IPromotion {
  id: number;
  code: string;
  name: string;
  description: string;
  type: number;
  createdAt: string;
  startsAt: string;
  expiresAt: string;
  value: number;
  firstRentalOnly: boolean;
  allCities: boolean;
  city: string;
  usedBy: number;
  searchString: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.name} ${this.code} ${this.city}`;
  }
}

export class UserPromotion implements IUserPromotion {
  id: number;
  user: User;
  used: boolean;
  promotion: Promotion;
  usedAt: string;
  remaining_value: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class PricingPlan implements IPricingPlan {
  id: number;
  name: string;
  description: string;
  discount_on_active_fee: number;
  discount_on_break_fee: number;
  discount_on_unlock_fee: number;
  is_valid: boolean;
  creation_time: string;
  searchString: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  max_free_unlock_daily: number;
  free_unlock_threshold: number;
  timeSlots: TimeSlot[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.name} ${this.description}`;
  }
}

export class Setting implements ISetting {
  id: number;
  name: string;
  description: string;
  value: string;
  type: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Operation implements IOperation {
  id: number;
  start_time: string;
  comment: string;
  stop_latitude: number;
  stop_longitude: number;
  stop_time: string;
  closed: boolean;
  device: Device;
  user: User;
  searchString: string;
  deviceId: number;
  deviceName: string;
  deviceModelId: number;
  deviceModel: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.device) {
      this.device = new Device(this.device);
      this.deviceId = this.device.id;
      this.deviceName = this.device.name;
      this.deviceModelId = this.device.modelId;
    }
    this.searchString = `${this.id} ${this.start_time} ${this.stop_time} ${this.deviceId} ${this.deviceModel} ${this.comment}`;
  }
}

export class OperationDevice implements IOperationDevice {
  operator: boolean;
  device: Device;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class StateTransition implements IStateTransition {
  id: number;
  stateA: string;
  stateB: string;
  reason: string;
  timestamp: string;
  forced: boolean;
  putOnline: boolean;
  putOffline: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class AlarmType implements IAlarmType {
  id: number;
  name: string;
  description: string;
  attributes: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class AlarmActive implements IAlarmActive {
  id: number;
  alarm_data: string;
  server_time: string;
  latitude: number;
  longitude: number;
  seen: boolean;
  seenBy: string;
  deviceId: number;
  rental: TripInfo;
  alarmType: AlarmType;
  device: Device;
  alarmTypeName: string;
  deviceName: string;
  cityId: number;
  searchString: string;
  closed: boolean;
  closedBy: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.alarmTypeName = this.alarmType.name;
    if (this.device) {
      this.device = new Device(this.device);
      this.deviceName = this.device.name;
      this.cityId = this.device.cityId;
    }

    this.searchString = `${this.id} ${this.alarmTypeName} ${this.deviceId} ${this.seenBy}`;
  }
}

export class Booking implements IBooking {
  id: number;
  start_time: string;
  stop_time: string;
  userId: number;
  deviceId: number;
  device: Device;
  user: User;
  searchString: string;

  userEmail: string;
  deviceName: string;
  userFirstName: string;
  userLastName: string;
  deviceCity: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
    this.searchString = `${this.id} ${this.user.email} ${this.user.firstName} ${this.user.lastName} ${this.device.name}`;

    this.userEmail = this.user.email;
    this.deviceName = this.device.name;
    this.userFirstName = this.user.firstName;
    this.userLastName = this.user.lastName;
    this.deviceCity = this.device.city.name;
  }
}

export class Command implements ICommand {
  id: number;
  toDo: boolean;
  invocation_time: string;
  execution_time: string;
  command_data: string;
  commandTypeId: number;
  deviceId: number;
  request_ip: string;
}

export enum DeviceLogType {
  COMMAND = 'Command',
  STATE_TRANSITION = 'State_transition'
}

export class DeviceLog implements ICommand, IStateTransition {
  id: number;
  row_type: DeviceLogType;
  toDo: boolean;
  invocation_time: string;
  execution_time: string;
  command_data: string;
  commandTypeId: commandType;
  deviceId: number;
  request_ip: string;
  stateA: string;
  stateB: string;
  reason: string;
  timestamp: string;
  forced: boolean;
  putOnline: boolean;
  putOffline: boolean;

  constructor(obj: any = {}) {
    Object.assign(this, obj);
  }
}

export class PenaltyRegistry implements IPenaltyRegistry {
  id: number;
  code: string;
  name: string;
  description: string;
  measureUnit: measureUnitEnum;
  isDeductible: boolean;
  amount?: number;
  vatCode?: string;

  constructor(obj: any = {}) {
    Object.assign(this, obj);
  }
}

export class FcmTopic implements IFcmTopic {
  id: number;
  name: string;
  description: string;
  is_valid: number;

  constructor(obj: any = {}) {
    Object.assign(this, obj);
  }
}

export class FcmMessage implements IFcmMessage {
  id: number;
  userId: string;
  os: string;
  title: string;
  description: string;
  image_url: string;
  fcm_token: string;
  fcm_message_id: number;
  badge_ios: number;
  deadline_type: string;
  deadline_value: number;
  send_on_date: string;
  created_at: string;
  sent: number;
  topicId: number;
  topicName: string;
  topicDescription: string;
  search_string: string;

  constructor(obj: any = {}) {
    Object.assign(this, obj);
    this.search_string = this.id + ' ' + this.title + ' ' + this.topicName +
      ' ' + this.topicDescription + ' ' + this.os;
  }
}

export class Ente {
  id: number;
  name: string;
  description: string;
  attributes: string;
  users: User[];
  devices: Device[];

  constructor(obj: any = {}) {
    Object.assign(this, obj);
  }
}



export enum DocumentStatus {
  Uploading = 'Uploading',
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Expired = 'Expired',
}

export enum DocumentTypeEnum {
  'CI' = 1,
  'PATENTE' = 2,
}

export class DocumentTypeItem {
  id: number;
  name: DocumentTypeEnum;
  description: string;
  attributes: string;
  photoOrientations: string;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
  }
}

export class DocumentRegistrationRequests {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  doc_number: string;
  documentType: number;
  released_by: string;
  status: DocumentStatus;
  documentPhotos: {id: number, photoOrientation: string}[];
  submission_date: Date;
  expiration_date: Date;
  social_security_number: string;
  userId: number;
  created_at: Date;
  updated_at: Date;
  onboard_completed: boolean;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
    if (obj.created_at) {
      this.created_at = new Date(obj.created_at);
    }
    if (obj.updated_at) {
      this.updated_at = new Date(obj.updated_at);
    }
    if (obj.user) {
      this.firstName = obj.user.firstName;
      this.lastName = obj.user.lastName;
      this.social_security_number = obj.user.social_security_number;
    }
  }
}

export enum ScooterRegistrationStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export class ScooterRegistrationRequests {
  id: number;
  status: ScooterRegistrationStatus;
  email: string;
  firstName: string;
  lastName: string;
  licenseCode: string;
  licenseReleasedBy: string;
  licenseExpiryDate: string;
  social_security_number: string;
  userId: number;
  created_at: Date;
  updated_at: Date;
  onboard_completed: boolean;
  license_country: string;

  constructor(obj: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
    if (obj.created_at) {
      this.created_at = new Date(obj.created_at);
    }
    if (obj.updated_at) {
      this.updated_at = new Date(obj.updated_at);
    }
    if (obj.user) {
      this.firstName = obj.user.firstName;
      this.lastName = obj.user.lastName;
      this.social_security_number = obj.user.social_security_number;
    }
  }
}

export class Model {
  id: number;
  name: string;
  description: string;
  active_fee: number;
  unlock_fee: number;
  break_fee: number;
  booking_fee: number;
  photoFile: string;
  time_threshold_min: number;
  active_fee2: number;
  break_fee2: number;
  daily_fee: number; 
  km_fee: number;
  km_fee2: number;
  limit_km_fee: number;
  deviceCategory: CategoryFlags;
  seats: number;
  // searchString: string;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    Object.assign(this, obj);
    // this.searchString = this.id + ' ' + this.name + ' ' + this.description;
  }
}

