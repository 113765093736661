import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

import { City, Device } from './../classes';
import { BehaviorSubject } from 'rxjs';
import { commandType } from '../interfaces/WSInterfaces';
// import { environment } from 'src/environments/environment';
import * as CONFIG from '../../environments/appconfig.json';

/////////////////////////////// APP VERSION ///////////////////////////////
const APP_VERSION = CONFIG.appVersion;
const APP_BUILD = CONFIG.appBuild;
//////////////////////////////////////////////////////////////

/////////////////////////////// DEFAULT CONFIGURATIONS ///////////////////////////////

// Server di produzione
const DEFAULT_DEV_WS_PROTOCOL = CONFIG.defaultDevWsProtocol;
const DEFAULT_DEV_WS_PORT =  CONFIG.defaultDevWsPort;
const BASE_DEV_WS_ADDRESS = DEFAULT_DEV_WS_PROTOCOL + '://' + CONFIG.defaultDevBaseAddress + ':' + DEFAULT_DEV_WS_PORT;

// Server di sviluppo
const DEFAULT_PROD_WS_PROTOCOL = CONFIG.defaultProdWsProtocol;
const DEFAULT_PROD_WS_PORT = CONFIG.defaultProdWsPort;
const BASE_PROD_WS_ADDRESS = DEFAULT_PROD_WS_PROTOCOL + '://' + CONFIG.defaultProdBaseAddress + ':' + DEFAULT_PROD_WS_PORT;

// Server locale
const BASE_LOCAL_WS_PROTOCOL = CONFIG.defaultLocalWsProtocol;
const BASE_LOCAL_WS_PORT =  CONFIG.defaultLocalWsPort;
const BASE_LOCAL_WS_ADDRESS = BASE_LOCAL_WS_PROTOCOL + '://' + CONFIG.defaultLocalBaseAddress  + ':' + BASE_LOCAL_WS_PORT;

const PRODUCTION_MODE = '0';
const DEVELOPMENT_MODE = '1';
const LOCAL_MODE = '2';

////////////   Modalità di avvio   /////////////
const DEFAULT_SERVER_MODE = CONFIG.defaultStartMode;
///////////////////////////////////////////////

const defaultCity = Number(CONFIG.defaultCity);
const TOKEN_KEY = 'auth-token';
const PROFILE_KEY = 'user_profile';
const SETTINGS_KEY = 'settings_data';
const DEFAULT_LANGUAGE = 'en';
const TEST_DEVICE_ID = 10001;
const TEST_DEVICE_NAME = '3s0';
const PAYMENT_TYPES_OPTIONS = [
  { name: 'Visa', value: 1 },
  { name: 'Mastercard', value: 2 }
];
const GEOLOCATION_DEFAULT_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const TYPE_AREA_CITY = 0;
const TYPE_AREA_ZONE = 1;
const TYPE_AREA_OUTCITY = 2;

const DEVICE_STATUS  = {'invalid': 0, 'idle': 1, 'booked': 2,
    'on_trip': 3, 'suspended': 4, 'battery_low': 5, 'action_required': 6, 'charger': 7, 'faulty': 8, 'lost': 9, 'operator': 10, 'stoked': 11, 'searching': 12 };
const DOCUMENT_TYPE = {1: 'CI', 2: 'PATENTE'};
const ROLE_ADMIN = 1;
const ROLE_CITY_MANAGER = 2;
const ROLE_OP_MANAGER = 3;
const ROLE_CHARGER = 4;
const ROLE_CUSTOMER = 5;
const ROLE_GUEST = 7;
const ROLE_OPERATOR = 8;
const ROLE_OPENDATA = 9;
const ROLES = {ROLE_ADMIN: 'Administrator', ROLE_CITY_MANAGER: 'City Manager', ROLE_OP_MANAGER: 'Operations Manager',
      ROLE_CHARGER: 'Charger', ROLE_CUSTOMER: 'Customer', ROLE_SECURITY: 'Security', ROLE_GUEST: 'Guest', ROLE_OPERATOR: 'Operator', ROLE_OPENDATA: 'Opendata'};
const ROLES_BY_ID = {1: 'Administrator', 2: 'City Manager', 3: 'Operations Manager',
  4: 'Charger', 5: 'Customer', 6: 'Security', 7: 'Guest', 8: 'Operator', 9: 'Opendata'};
const RENTED_VEHICLE_KEY = 'rid';
const RING_BELL_MSEC = 15000; // 15 sec
const PAUSE_SPINNER_MSEC = 6000; // 6 sec
const RENTED_VEHICLE_POSITION_RELOAD_TIMEOUT_MS = 5000; // 5 secondi
const VEHICLE_STATUS_RELOAD_TIMEOUT_MSEC = 60000; // 1 minuto
const CUSTOMER_MAP_RELOAD_TIMEOUT_MSEC = 60000; // 1 minuto
const OPERATOR_MAP_RELOAD_TIMEOUT_MSEC = 30000; // 30 secondi
const SEND_GEOLOCATION_TO_SERVER_TIMEOUT_MSEC = 10000; // 10 secondi
const DURATION_ZONE_NOTIFICATION_MSEC  = 4000; // 4 secondi
const DURATION_VIEW_AREA_NOTIFICATION_MSEC  = 8000; // 8 secondi
const MAX_BOOKING_DURATION_MIN = 30; // 30 minuti
const DISTANCE_THRESHOLD = 50; // Expressed in meters
const VOUCHER_TYPES = [
  { name: 'Time (s)', value: 1},
  { name: 'Wallet (€)', value: 4}
];
const PROMOTIONS_TYPES = [
  { name: 'Time (s)', value: 1}
  // { name: 'Import (€)', value: 2}
];
const VOUCHER_LABELS = {1: 'Time (s)', 2: 'Import (€)'};

/* GOOGLE MAPS*/
const GOOGLE_MAPS_API_KEY = CONFIG.googleMapApiKey;
// Tariffe WS: https://developers.google.com/maps/billing/understanding-cost-of-use?hl=it#dynamic-maps
const GOOGLE_MAPS_DEFAULT_ZOOM_LEVEL = 12;
const GOOGLE_MAPS_POSITION_ZOOM_LEVEL = 20;
const GOOGLE_MAPS_USER_POSITION_ZOOM_LEVEL = 18;
const GOOGLE_MAPS_INITIAL_POSITION_ZOOM_LEVEL = 16;

const STATUS_VEHICLE_VISIBLE_ADMIN = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const STATUS_VEHICLE_VISIBLE_OP = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const STATUS_VEHICLE_VISIBLE_CUSTOMER = [1, 2];
const STATUS_VEHICLE_VISIBLE_CHARGER = [1, 2, 5];
const STATUS_VEHICLE_VISIBLE_OPERATOR = [5, 6, 10];

const CLOSE_RENTAL_IN_AREA = 1;
const CLOSE_RENTAL_IN_HUB = 2;

const OUT_CITY_DEFAULT_DESCRIPTION = 'You are out of city, go back, please.';
const OUT_CITY_DEFAULT_NAME = 'You are out of city, go back, please.';
const ICON_CURRENT_POSITION = '../../assets/icon/beachflag.png';
const LIMITS_ICON_DIR_PATH = '../../assets/limiti/';
const SPEED_ID_OUT_CITY = 14;
const SPEED_LIMITS = {
  10: {
    'icon': 'Limite_0.png',
    'value': 0
  },
  5: {
    'icon': 'Limite_5.png',
    'value': 5
  },
  14: {
    'icon': 'Limite_6.png',
    'value': 6
  },
  6: {
    'icon': 'Limite_10.png',
    'value': 10
  },
  7: {
    'icon': 'Limite_15.png',
    'value': 15
  },
  8: {
    'icon': 'Limite_20.png',
    'value': 20
  },
  18: {
    'icon': 'Limite_25.png',
    'value': 25
  },
  13: {
    'icon': 'Limite_30.png',
    'value': 30 },
};

const SOURCE_TYPE_PHOTOLIBRARY = 0;
const SOURCE_TYPE_CAMERA = 1;
const OTP_SMS_PREFIX = 'OTP:';
const COLOR_HUB_ZONES = '#9BBF23';
const COLOR_HUB_BIT_POINT = '#000000';

const CLOSE_RENTAL_WARNINGS = {
  CODE_ERROR_NO_PARKING_AREA : 441,
  CODE_ERROR_NO_HUB_AREA : 440,
  PARKING_DENIED : 442,
  ERROR_CLOSING_RENTAL : 409,
  RENTAL_NOT_FOUND : 404,
};

const CLOSE_RENTAL_WARNING_ACTIONS = {
  WARNING_ACTION_FORCE_CLOSING : 0,
  WARNING_ACTION_FIND_BITPOINT : 1,
  WARNING_ACTION_CHECK_POSITION : 2,
  WARNING_ACTION_HOWTO_PARK : 3
};

const AREA_COLORS = [
  {'name': 'Black', 'value': '000000' },
  {'name': 'Purple', 'value': '660066' },
  {'name': 'Magenta', 'value': 'FF00FF' },
  {'name': 'Brown', 'value': '663300' },
  {'name': 'White', 'value': 'FFFFFF' },
  {'name': 'Gray', 'value': 'E6E6E6' },
  {'name': 'Red', 'value': 'FF0000' },
  {'name': 'Yellow', 'value': 'FFBF00' },
  {'name': 'Green', 'value': '00FF00' },
  {'name': 'Blue', 'value': '0080FF' },
  {'name': 'Orange', 'value': 'FF8000' },
  {'name': 'Transparent', 'value': 'ABABAB' },
  {'name': 'Maroon', 'value': '800000' },
  {'name': 'Fuchsia', 'value': 'FF00FF' },
  {'name': 'Lightskyblue', 'value': '87CEFA' }
];

//////////////////////////////////////////////////////////////////////////////////////

@Injectable({
  providedIn: 'root'
})
export class SettingsmanagerService {

  public mapsLoaded = false;
  private debugWsCalls = 0 ;
  private debugGmCalls = 0 ;
  private cities: Array<City> = [];
  public currentCityId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public currentCityName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentCityAvailability: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public currentCity: BehaviorSubject<City> = new BehaviorSubject<City>(null);
  // private wsMode: string = PRODUCTION_MODE;
  private developersGroup = [];
  private testersGroup = [];


  constructor(private translate: TranslateService,
              private platform: Platform) {
    console.log('### constructor SETTINGS MANAGER');
  }

  /* BEGIN SETTINGS MANAGEMENT */

  private settings_data = {
    'language' : null,
    'active_city': null,
    'version': null,
    'server_mode': null
  };


    /*
    * Questo metodo restituisce l'indirizzo dei web services da invocare.
    * L'indirizzo può essere:
    *   - BASE_DEV_WS_ADDRESS: server di sviluppo
    *   - BASE_PROD_WS_ADDRESS: server di produzione
    *   - BASE_LOCAL_WS_ADDRESS: server in locale
    */
  // getBaseWsUrl() {
  //   return environment.backendUrl;
  // }
  getBaseWsUrl() {
    let url = '';
    switch (String(this.getServerModeSetting())) {
      case LOCAL_MODE:
        url = BASE_LOCAL_WS_ADDRESS;
        break;

      case DEVELOPMENT_MODE:
        url = BASE_DEV_WS_ADDRESS;
        break;

      case PRODUCTION_MODE:
        url = BASE_PROD_WS_ADDRESS;
        break;
    }
    // console.log('@@@@@@@@@@@@ getBaseWsUrl', this.getServerModeSetting(), url);
    return url;
  }

  getBaseDevWsUrl() {
    return BASE_DEV_WS_ADDRESS;
  }

  getBaseProdWsUrl() {
    return BASE_PROD_WS_ADDRESS;
  }

  setCurrentCity(city: City) {
    this.currentCity.next(city);
    this.currentCityId.next(city.id);
    this.currentCityName.next(city.name);
  }

  setCurrentCityId(cityId: number) {
    this.currentCityId.next(cityId);
    const cityName = this.getCityById(cityId);
    this.currentCityName.next(cityName);
    const cityAvailability = this.getCityAvailabilityById(cityId);
    this.currentCityAvailability.next(cityAvailability);
  }

  getDefaultGeolocationOptions() {
    return GEOLOCATION_DEFAULT_OPTIONS;
  }

  getCurrentCityId() {
    this.currentCityId.getValue();
  }

  getTestDeviceNumber() {
    return TEST_DEVICE_ID;
  }

  getTestDeviceName() {
    return TEST_DEVICE_NAME;
  }

  getSpeedOutCity() {
    return SPEED_ID_OUT_CITY;
  }

  getOutCityAreaName() {
    return OUT_CITY_DEFAULT_NAME;
  }

  getOutCityAreaDescription() {
    return OUT_CITY_DEFAULT_DESCRIPTION;
  }

  getSpeedLimitById(idSpeed: number) {
    try {
    return SPEED_LIMITS[idSpeed].value;
    } catch (err) {
      return 0;
    }
  }

  getSpeedIconById(idSpeed: number) {
    try {
      return SPEED_LIMITS[idSpeed].icon;
    } catch (err) {
      return SPEED_LIMITS[10].icon;
    }
  }

  getSourceTypePhotolibrary() {
    return SOURCE_TYPE_PHOTOLIBRARY;
  }

  getSourceTypeCamera() {
    return SOURCE_TYPE_CAMERA;
  }

  getStatusVehicleVisibleByRoleId(roleId) {
    switch (roleId) {
      case ROLE_CHARGER:
        return STATUS_VEHICLE_VISIBLE_CHARGER;
      case ROLE_ADMIN:
        return STATUS_VEHICLE_VISIBLE_ADMIN;
      case ROLE_CITY_MANAGER:
        return STATUS_VEHICLE_VISIBLE_ADMIN;
      case ROLE_OP_MANAGER:
        return STATUS_VEHICLE_VISIBLE_OP;
      case ROLE_OPERATOR:
        return STATUS_VEHICLE_VISIBLE_OPERATOR;
      case ROLE_CUSTOMER:
      case ROLE_OPENDATA:
      case ROLE_GUEST:
      default:
        return STATUS_VEHICLE_VISIBLE_CUSTOMER;
    }
  }

  getLanguageSetting(): string {
    return this.settings_data.language;
  }

  getCitySetting(): number {
    let cityId: number;
    if ((this.settings_data) && (this.settings_data.active_city)){
      cityId = Number(this.settings_data.active_city);
    } else {
      this.settings_data.active_city = Number(this.getDefaultCity());
    }
    console.log('@@@ getCitySetting', cityId);
    return cityId;
  }

  getDefaultCity():number {
    return defaultCity;
  }

  getCitiesSetting(): Array<City> {
    console.log('@@@ getCitiesSetting1:', this.cities, this.cities.length);
    if (this.cities.length === 0) {
      this.cities = JSON.parse(localStorage.getItem('cities'));
      console.log('@@@ getCitiesSetting2:', this.cities);
    }
    return this.cities;
  }

  getVersionSetting(): string {
    return this.settings_data.version;
  }

  getSettingsData() {
    return this.settings_data;
  }

  // TODO: Leggerlo dai settings o da un file di configurazione
  getServerModeSetting(): string {
    return DEFAULT_SERVER_MODE;
  }

  setLanguageSetting(lang: string) {
    // console.log('### setLanguageSetting ###', lang);
    this.settings_data.language = lang;
    localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
    this.translate.use(lang);
  }

  setServerSetting(mode: string) {
    // console.log('### setLanguageSetting ###', lang);
    this.settings_data.server_mode = mode;
    localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
    // this.setServerMode(Number(mode));
  }

  setActiveCityIdSetting(active_city: number) {
    this.settings_data.active_city = active_city;
    this.setCurrentCityId(active_city);
    localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
  }

  setCitiesSetting(cities: Array<City>) {
    localStorage.setItem('cities', JSON.stringify(this.cities));
  }

  setVersionSetting(version: string) {
    this.settings_data.version = version;
    localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
  }

  getDefaultSettings () {
    return  {
      'language' : DEFAULT_LANGUAGE,
      'active_city': Number(defaultCity),
      'version': this.getAppVersion(),
      'server_mode': String(PRODUCTION_MODE)
    };
  }

  initAppSettings() {
    console.log('initAppSettings - INIZIO', this.getSettingsKey());
    const savedSettings = JSON.parse(localStorage.getItem(this.getSettingsKey()));

    // If in the local storage there is a settings object
    // we load its value in the settings service provider
    if (savedSettings) {

      // Language setting
      // Piority:
      //  1) language setting in th localStorage
      //  2) language setting of the browser
      //  3) DEFAULT_LANGUAGE

      if ((savedSettings.language) && (savedSettings.language === 'it' || (savedSettings.language === 'en') )) {
        this.settings_data.language = savedSettings.language;
      }
      // else {
      //   const browserLang = this.getBrowserLanguage();
      //   // C'è il profilo salvato, ma la lingua non è specificata
      //   if (browserLang) {
      //     this.settings_data.language = browserLang;
      //   }
      // localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
      // }

      // Active city setting
      if ((savedSettings.active_city)) {
        this.settings_data.active_city = savedSettings.active_city;
      }

      // Server mode setting
      if ((savedSettings.server_mode)) {
        this.settings_data.server_mode = savedSettings.server_mode;
      }

      // Current version Setting
      const currentVersion =  this.getAppVersion();
      if (savedSettings.version !== currentVersion) {
        // console.log('APP UPGRADED');
        this.setVersionSetting(currentVersion);
      }

    } else {
      // console.log('@@@ saveDefaultSettings');
      this.saveDefaultSettings();
    }

    this.setCurrentCityId(this.settings_data.active_city);

    // Set the language when the app is loaded
    // console.log('@@@ Set the language when the app is loaded:', this.settings_data.language);
    this.translate.use(this.settings_data.language);
    console.log('initAppSettings - FINE');
  }

  getBrowserLanguage() {
    // Calcolo della lingua da visualizzare dopo l'installazione:
    // - Se la lingua del browser è italiano allora 'it'
    // - Altrimenti la lingua è inglese
    const browserLang = this.translate.getBrowserLang();
    console.log('@@@ LINGUA DEL BROWSER: ', browserLang);
    if (browserLang) {
      return (browserLang === 'it') ? 'it' : 'en';
    } else {
      return DEFAULT_LANGUAGE;
    }
  }

  saveDefaultSettings() {
    this.settings_data = this.getDefaultSettings();
    // console.log('@@@saveDefaultSettings-PRIMA:', this.settings_data);
    const browserLang = this.getBrowserLanguage();
    if (browserLang) {
      this.settings_data.language = browserLang;
    }
    // console.log('@@@saveDefaultSettings-DOPO', this.settings_data);
    localStorage.setItem(this.getSettingsKey(), JSON.stringify(this.settings_data));
  }

  /* END SETTINGS MANAGEMENT */

  /* GET CONSTANTS */

  getAppVersion() {
    return APP_VERSION;
  }

  getAppBuild() {
    return APP_BUILD;
  }

  getCurrentOS() {
    let currentOS;
    if (this.platform.is('ios')) {
      currentOS = 'ios';
    } else if (this.platform.is('android')) {
      currentOS = 'android';
    } else if (this.platform.is('desktop')) {
      currentOS = 'desktop';
    } else if (this.platform.is('pwa')) {
      currentOS = 'pwa';
    } else  {
      currentOS = 'other';
    }
    return currentOS;
  }

  getPaymentTypes() {
    return PAYMENT_TYPES_OPTIONS;
  }

  getPaymentNameById(id: number): string {
    for (let i = 0; i < PAYMENT_TYPES_OPTIONS.length; i++) {
      if (PAYMENT_TYPES_OPTIONS[i].value === id) {
        return PAYMENT_TYPES_OPTIONS[i].name;
      }
    }
  }

  getDocumentTypes() {
    return DOCUMENT_TYPE;
  }

  getVoucherTypes() {
    return VOUCHER_TYPES;
  }

  getDeviceStates() {
    return DEVICE_STATUS;
  }

  getDeviceStatusIdByName(status): number {
    return DEVICE_STATUS[status];
  }

  getRoleNameById(roleId: number) {
    return ROLES_BY_ID[roleId];
  }

  getTokenKey() {
    return TOKEN_KEY;
  }

  getProfileKey() {
    return PROFILE_KEY;
  }

  getSettingsKey() {
    return SETTINGS_KEY;
  }

  getCityKey() {
    return SETTINGS_KEY;
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  getRentedVehicleKey(): string {
    return RENTED_VEHICLE_KEY;
  }

  getRingBellMillisec () {
    return RING_BELL_MSEC;
  }

  getPauseSpinnerMillisec () {
    return PAUSE_SPINNER_MSEC;
  }

  getCustomerMapReloadInterval() {
    return CUSTOMER_MAP_RELOAD_TIMEOUT_MSEC;
  }

  getRentedVehiclePositionReloadInterval() {
    return RENTED_VEHICLE_POSITION_RELOAD_TIMEOUT_MS;
  }

  getOperatorMapReloadInterval() {
    return OPERATOR_MAP_RELOAD_TIMEOUT_MSEC;
  }

  getVehicleStatusReloadInterval() {
    return VEHICLE_STATUS_RELOAD_TIMEOUT_MSEC;
  }

  getSendGeolocationToServerInterval() {
    return SEND_GEOLOCATION_TO_SERVER_TIMEOUT_MSEC;
  }

  getLimitsIconDirPath() {
    return LIMITS_ICON_DIR_PATH;
  }

  getIconCurrentPosition() {
    return ICON_CURRENT_POSITION;
  }

  getDurationZoneNotification() {
    return DURATION_ZONE_NOTIFICATION_MSEC;
  }

  getTypeAreaCity() {
    return TYPE_AREA_CITY;
  }

  getTypeAreaZone() {
    return TYPE_AREA_ZONE;
  }

  getTypeAreaZoneOutCity() {
    return TYPE_AREA_OUTCITY;
  }

  getDurationViewAreaNotification() {
    return DURATION_VIEW_AREA_NOTIFICATION_MSEC;
  }

  getMaxBookingDuration() {
    return MAX_BOOKING_DURATION_MIN;
  }

  getGoogleMapsApiKey() {
    return GOOGLE_MAPS_API_KEY;
  }

  getGoogleMapsDefaultZoomLevel() {
    return GOOGLE_MAPS_DEFAULT_ZOOM_LEVEL;
  }

  getGoogleMapsPositionZoomLevel() {
    return GOOGLE_MAPS_POSITION_ZOOM_LEVEL;
  }

  getGoogleMapsInitialPositionZoomLevel() {
    return GOOGLE_MAPS_INITIAL_POSITION_ZOOM_LEVEL;
  }

  getGoogleMapsInitialUserPositionZoomLevel() {
    return GOOGLE_MAPS_USER_POSITION_ZOOM_LEVEL;
  }

  getDocumentTypeById(id: number): string {
    return DOCUMENT_TYPE[id];
  }

  getDefaultLanguage() {
    return DEFAULT_LANGUAGE;
  }

  getDistanceThreshold() {
    return DISTANCE_THRESHOLD;
  }

  getVoucherTypeById(id: number): string {
    return VOUCHER_LABELS[id];
  }

  // City management

  updateCities(data: Array<City>) {
    // console.log('updateCities settingsmanager', data);
    this.cities = data;
    this.setCitiesSetting(this.cities);
  }

  getCities() {
    console.log('###### getCities', this.cities);
    const cityList = this.getCitiesSetting();
    const citiesArray = [];
    for (let i = 0; i < cityList.length; i++) {
      const cityId =  String(cityList[i].id);
      citiesArray[cityId] = cityList[i].name;
    }
    return citiesArray;
  }

  getCityById(id: number): string {
    // console.log('######@@ getCityById', this.cities);
    // return const CITIES_OPTIONS = [ { name: 'Verona', value: 1 }, { name: 'Pisa', value: 2 }, { name: 'Cattolica', value: 3 }];
    for (let i = 0; i < this.cities.length; i++) {
      if (Number(this.cities[i].id) === Number(id)) {
        // console.log('######@@ getCityById', this.cities[i].name);
        return this.cities[i].name;
      }
    }
  }

  getCityAvailabilityById(id: number): boolean {
    for (let i = 0; i < this.cities.length; i++) {
      if (Number(this.cities[i].id) === Number(id)) {
        return this.cities[i].availability;
      }
    }
  }

  setCurrentCityAvailability(availability: boolean) {
    this.currentCityAvailability.next(availability);
    const currentCityId: number = this.currentCityId.getValue();
    this.cities.find( city => city.id === currentCityId).availability = availability;
    this.setCitiesSetting(this.cities);
  }

  getCitiesOptions() {
    const cityList = this.getCitiesSetting();
    console.log('@@@ getCitiesOptions: ', cityList);
    const citiesArray = [];
    for (let i = 0; i < cityList.length; i++) {
      citiesArray.push({'id' : Number(cityList[i].id), 'name': cityList[i].name});
    }
    return citiesArray;
  }

  getPromotionOptions() {
    return PROMOTIONS_TYPES;
  }

  getStatesOptions() {
    const statesArray = [];
    const obj = this.getDeviceStates();
    for (const i in obj) {
      const id = obj[i];
      if (id === 0) {
        continue;
      }
      statesArray.push({'id' : id, 'name': Device.getLabelDeviceState(id)});
    }
    // console.log(statesArray);
    return statesArray;
  }

  getActiveCityCoordinates() {
    const activeCityId = Number(this.getCitySetting());
    console.log('@@@kkk activeCityId:', activeCityId);
    console.log('@@@kkk this.cities:', this.cities);
    for (let i = 0; i < this.cities.length; i++) {
      if (this.cities[i].id === activeCityId) {
        return {position_latitude: this.cities[i].center_latitude, position_longitude: this.cities[i].center_longitude, coordinates: this.cities[i].coordinates};
      }
    }
  }


  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::                                                                         :::
// :::  This routine calculates the distance between two points (given the     :::
// :::  latitude/longitude of those points). It is being used to calculate     :::
// :::  the distance between two locations using GeoDataSource (TM) prodducts  :::
// :::                                                                         :::
// :::  Definitions:                                                           :::
// :::    South latitudes are negative, east longitudes are positive           :::
// :::                                                                         :::
// :::  Passed to function:                                                    :::
// :::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
// :::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
// :::    unit = the unit you desire for results                               :::
// :::           where: 'M' is statute miles (default)                         :::
// :::                  'K' is kilometers                                      :::
// :::                  'N' is nautical miles                                  :::
// :::                  'T' is meters                                                       :::
// :::  Worldwide cities and other features databases with latitude longitude  :::
// :::  are available at https://www.geodatasource.com                         :::
// :::                                                                         :::
// :::  For enquiries, please contact sales@geodatasource.com                  :::
// :::                                                                         :::
// :::  Official Web site: https://www.geodatasource.com                       :::
// :::                                                                         :::
// :::               GeoDataSource.com (C) All Rights Reserved 2018            :::
// :::                                                                         :::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

  computeCoordinatesDistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') { dist = dist * 1.609344; }
      if (unit === 'N') { dist = dist * 0.8684; }
      if (unit === 'T') { dist = dist * 1609.344; }
      return dist;
    }
  }


  // @TODO: Remove these functions and the related propertie

  getDebugWsCalls() {
    return this.debugWsCalls;
  }

  incrDebugWsCalls() {
    this.debugWsCalls = this.debugWsCalls + 1;
  }

  getDebugGoogleMapCalls() {
    return this.debugGmCalls;
  }

  incrDebugGoogleMapCalls() {
    this.debugGmCalls = this.debugGmCalls + 1;
  }

  getCloseInArea() {
    return CLOSE_RENTAL_IN_AREA;
  }

  getCloseInHub() {
    return CLOSE_RENTAL_IN_HUB;
  }

  getOTPSmsPrefix() {
    return OTP_SMS_PREFIX;
  }


  getTestersGroup() {
    return this.testersGroup;
  }

  getDevelopersGroup() {
    return this.developersGroup;
  }

  setTesterGroup(testers) {
    this.testersGroup = testers;
  }

  setDeveloperGroup(developers) {
    this.developersGroup = developers;
  }

  isLoggedUserDeveloper(loggedUserEmail) {
    const developers = this.getDevelopersGroup();
    if (developers.indexOf(loggedUserEmail) >= 0) {
      // console.log('@@@ You are a DEVELOPER');
      return true;
    } else {
      // console.log('@@@ You are not a developer');
      return false;
    }
  }

  getDefaultColorHubZones() {
    return COLOR_HUB_ZONES;
  }

  getDefaultColorBitPoint() {
    return COLOR_HUB_BIT_POINT;
  }

  getCloseRentalWarnings() {
    return CLOSE_RENTAL_WARNINGS;
  }

  getCloseRentalWarningActions() {
    return CLOSE_RENTAL_WARNING_ACTIONS;
  }

  getDatePicker() {
    return {
      showTodayButton: false, // default true
      closeOnSelect: false, // default false
      disableWeekDays: [], // default []
      mondayFirst: true, // default false
      closeLabel: this.translate.instant('Close'), // default 'Close'
      setLabel: this.translate.instant('OK'),  // default 'Set'
      todayLabel: this.translate.instant('Today'), // default 'Today'
      disabledDates: [], // default []
      titleLabel: this.translate.instant('Selected date'), // default null
      monthsList: [
        this.translate.instant('Jan'),
        this.translate.instant('Feb'),
        this.translate.instant('Mar'),
        this.translate.instant('Apr'),
        this.translate.instant('May'),
        this.translate.instant('Jun'),
        this.translate.instant('Jul'),
        this.translate.instant('Aug'),
        this.translate.instant('Sep'),
        this.translate.instant('Oct'),
        this.translate.instant('Nov'),
        this.translate.instant('Dec')
      ],
      weeksList: [
        this.translate.instant('SUN_day'),
        this.translate.instant('MON_day'),
        this.translate.instant('TUE_day'),
        this.translate.instant('WED_day'),
        this.translate.instant('THU_day'),
        this.translate.instant('FRI_day'),
        this.translate.instant('SAT_day'),
      ],
      dateFormat: 'DD-MM-YYYY', // default DD MMM YYYY
      clearButton: true, // default true
      momentLocale: 'pt-BR', // Default 'en-US'
      yearInAscending: true, // Default false
      btnCloseSetInReverse: false, // Default false
      btnProperties: {
        expand: 'block', // Default 'block'
        fill: '', // Default 'solid'
        size: '', // Default 'default'
        disabled: '', // Default false
        strong: '', // Default false
        color: '' // Default ''
      },
    };
  }

  getCountriesList(): Array<any> {
    return [
      ['IT', 'Italy'],
      ['AF', 'Afghanistan'],
      ['AL', 'Albania'],
      ['DZ', 'Algeria'],
      ['AD', 'Andorra'],
      ['AO', 'Angola'],
      ['AI', 'Anguilla'],
      ['AG', 'Antigua'],
      ['AR', 'Argentina'],
      ['AM', 'Armenia'],
      ['AU', 'Australia'],
      ['AT', 'Austria'],
      ['AZ', 'Azerbaijan'],
      ['BS', 'Bahamas'],
      ['BH', 'Bahrain'],
      ['BD', 'Bangladesh'],
      ['BB', 'Barbados'],
      ['BY', 'Belarus'],
      ['BE', 'Belgium'],
      ['BZ', 'Belize'],
      ['BJ', 'Benin'],
      ['BM', 'Bermuda'],
      ['BT', 'Bhutan'],
      ['BO', 'Bolivia'],
      ['BA', 'Bosnia and Herzegovina'],
      ['BW', 'Botswana'],
      ['BR', 'Brazil'],
      ['BN', 'Brunei'],
      ['BG', 'Bulgaria'],
      ['BF', 'Burkina Faso'],
      ['BI', 'Burundi'],
      ['KH', 'Cambogia'],
      ['CM', 'Cameroon'],
      ['CA', 'Canada'],
      ['CV', 'Cape Verde'],
      ['KY', 'Cayman islands'],
      ['CF', 'Central African Republic'],
      ['TD', 'Chad'],
      ['CL', 'Chile'],
      ['CN', 'China'],
      ['CO', 'Colombia'],
      ['CR', 'Costa Rica'],
      ['HR', 'Croatia'],
      ['CU', 'Cuba'],
      ['CY', 'Cyprus'],
      ['CZ', 'Czech Republic'],
      ['CD', 'Congo'],
      ['DK', 'Denmark'],
      ['DJ', 'Djibouti'],
      ['DM', 'Dominica'],
      ['DO', 'Dominican Republic'],
      ['EC', 'Ecuador'],
      ['EG', 'Egypt'],
      ['SV', 'El Salvador'],
      ['GQ', 'Guinea'],
      ['ER', 'Eritrea'],
      ['EE', 'Estonia'],
      ['ET', 'Ethiopia'],
      ['FK', 'Falkland islands'],
      ['FO', 'Faroe islands'],
      ['FJ', 'Fiji'],
      ['FI', 'Finland'],
      ['FR', 'France'],
      ['PF', 'Polynesia'],
      ['GA', 'Gabon'],
      ['GM', 'Gambia'],
      ['GE', 'Georgia'],
      ['DE', 'Germany'],
      ['GH', 'Ghana'],
      ['GI', 'Gibraitar'],
      ['GR', 'Greece'],
      ['GL', 'Greenland'],
      ['GT', 'Guatemala'],
      ['GG', 'Guarnsey'],
      ['GN', 'Guinea'],
      ['Gw', 'Guinea-Bissau'],
      ['GY', 'Guyana'],
      ['HT', 'Haiti'],
      ['HN', 'Honduras'],
      ['HK', 'Hong Kong'],
      ['HU', 'Hungary'],
      ['IS', 'Iceland'],
      ['IN', 'India'],
      ['ID', 'Indonesia'],
      ['IR', 'Iran'],
      ['IQ', 'Iraq'],
      ['IE', 'Ireland'],
      ['IL', 'Israel'],
      ['IT', 'Italy'],
      ['CI', 'Ivory Coast'],
      ['JM', 'Jamaica'],
      ['JP', 'Japan'],
      ['JE', 'Jersey'],
      ['JO', 'Jordan'],
      ['KZ', 'Kazakhstan'],
      ['KE', 'Kenya'],
      ['XK', 'Kosovo'],
      ['KW', 'Kuwait'],
      ['KG', 'Kyrgystan'],
      ['LA', 'Laos'],
      ['LB', 'Lebanon'],
      ['LR', 'Liberia'],
      ['LY', 'Libya'],
      ['LI', 'Liechtenstein'],
      ['LT', 'Lithuania'],
      ['LU', 'Luxembourg'],
      ['LV', 'Latvia'],
      ['MK', 'Macedonia'],
      ['MG', 'Madagascar'],
      ['MW', 'Malawi'],
      ['MY', 'Malaysia'],
      ['MV', 'Maldives'],
      ['ML', 'Mali'],
      ['MT', 'Malta'],
      ['MR', 'Mauritania'],
      ['MU', 'Mauritius'],
      ['MX', 'Mexico'],
      ['FM', 'Micronesia'],
      ['MD', 'Moldova'],
      ['MC', 'Monaco'],
      ['MN', 'Mongolia'],
      ['ME', 'Montenegro'],
      ['MA', 'Morocco'],
      ['MZ', 'Mozambique'],
      ['NA', 'Namibia'],
      ['NP', 'Nepal'],
      ['NL', 'Netherlands'],
      ['NC', 'New Caledonia'],
      ['NZ', 'New Zealand'],
      ['NI', 'Nicaragua'],
      ['NE', 'Niger'],
      ['NG', 'Nigeria'],
      ['NU', 'Niue'],
      ['KP', 'North Korea'],
      ['NO', 'Norway'],
      ['OM', 'Oman'],
      ['PK', 'Pakistan'],
      ['PS', 'Palestine'],
      ['PA', 'Panama'],
      ['PG', 'Papua New Guinea'],
      ['PY', 'Paraguay'],
      ['PE', 'Peru'],
      ['PH', 'Philippines'],
      ['PL', 'Poland'],
      ['PT', 'Portugal'],
      ['PR', 'Puerto Rico'],
      ['QA', 'Qatar'],
      ['CG', 'Republic of the Congo'],
      ['RO', 'Romania'],
      ['RU', 'Russia'],
      ['RW', 'Rwanda'],
      ['VC', 'Saint Vincent'],
      ['WS', 'Samoa'],
      ['SM', 'San Marino'],
      ['ST', 'Sao Tome'],
      ['SA', 'Saudi Arabia'],
      ['SN', 'Senegal'],
      ['RS', 'Serbia'],
      ['SC', 'Seychelles'],
      ['SL', 'Sierra Leone'],
      ['SG', 'Singapore'],
      ['SK', 'Slovakia'],
      ['SI', 'Slovenia'],
      ['SO', 'Somalia'],
      ['ZA', 'South Africa'],
      ['KR', 'South Korea'],
      ['SS', 'South Sudan'],
      ['ES', 'Spain'],
      ['LK', 'Sri Lanka'],
      ['SD', 'Sudan'],
      ['SZ', 'Swaziland'],
      ['SE', 'Sweden'],
      ['CH', 'Switzerland'],
      ['SY', 'Syria'],
      ['TW', 'Taiwan'],
      ['TJ', 'Tajikistan'],
      ['TZ', 'Tanzania'],
      ['TH', 'Thailand'],
      ['TG', 'Togo'],
      ['TO', 'Tonga'],
      ['TT', 'Trinidad and Tobago'],
      ['TN', 'Tunisia'],
      ['TR', 'Turkey'],
      ['TM', 'Turkmenistan'],
      ['UG', 'Uganda'],
      ['UA', 'Ukraine'],
      ['AE', 'United Arab Emirates'],
      ['GB', 'United Kingdom'],
      ['US', 'United States'],
      ['UY', 'Uruguay'],
      ['UZ', 'Uzbekistan'],
      ['VA', 'Vatican'],
      ['VE', 'Venezuela'],
      ['VN', 'Vietnam'],
      ['YE', 'Yemen'],
      ['ZM', 'Zambia'],
      ['ZW', 'Zimbabwe']
    ];
  }

  public getCommandDescription(cod: commandType): string {
    switch (cod) {
      case commandType.POWER_ON: return 'POWER ON';
      case commandType.POWER_OFF: return 'POWER OFF';
      case commandType.LOCK: return 'LOCK';
      case commandType.UNLOCK: return 'UNLOCK';
      case commandType.SPEEDLIMIT_5: return 'SPEEDLIMIT 5';
      case commandType.SPEEDLIMIT_10: return 'SPEEDLIMIT 10';
      case commandType.SPEEDLIMIT_15: return 'SPEEDLIMIT 15';
      case commandType.SPEEDLIMIT_20: return 'SPEEDLIMIT 20';
      case commandType.BUZZER_ON: return 'BUZZER ON';
      case commandType.SPEEDLIMIT_0: return 'SPEEDLIMIT 0';
      case commandType.LIGHTS_ON: return 'LIGHTS ON';
      case commandType.LIGHTS_OFF: return 'LIGHTS OFF';
      case commandType.SPEEDLIMIT_30: return 'SPEEDLIMIT 30';
      case commandType.SPEEDLIMIT_6: return 'SPEEDLIMIT 6';
      case commandType.GET_MT1: return 'GET MT1';
      case commandType.TRIP_INTERVAL: return 'TRIP INTERVAL';
      case commandType.STATIONARY_INTERVAL: return 'STATIONARY INTERVAL';
      case commandType.SPEEDLIMIT_25: return 'SPEEDLIMIT 25';
      case commandType.GET_MT2: return 'GET MT2';
      case commandType.UNLOCK_TRUNK: return 'UNLOCK TRUNK';
      case commandType.LOCK_BATTERY: return 'LOCK BATTERY';
      case commandType.UNLOCK_BATTERY: return 'UNLOCK BATTERY';
      case commandType.UNAUTHORIZED_AREA: return 'UNAUTHORIZED AREA';
      case commandType.EMERGENCY: return 'EMERGENCY';
      case commandType.UNAVAILABLE_DEVICE: return 'UNAVAILABLE DEVICE';
      default: throw new Error('codice non riconosciuto: valore: ' + cod);
    }
  }

  public getAreaColors() {
    return AREA_COLORS;
  }

}
