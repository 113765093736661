/*
*   WebService Manager: Is the service provider used by the Customer App
*   to manage a rental and the state of the rented vehicle.
*
*/

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { File } from '@ionic-native/file/ngx';

// BS modules
import {
  User,
  Device,
  RentalReport,
  RentalStatus,
  BookingStatus,
  CustomerProfile,
  TripInfo,
  Event,
  Voucher,
  ActiveRentalFlatten,
  Recharge,
  UserDocument,
  Notification,
  Hub,
  PaymentMethod,
  SeasonTicketType,
  SeasonTicket,
  UserSubscription,
  TransactionData,
  Warning,
  Zone,
  City,
  Report,
  ReportStats,
  ChargerInfo,
  BlacklistedCard,
  Statistics,
  Fault,
  UserPromotion,
  Promotion,
  TimeStatistics,
  PricingPlan,
  Setting,
  Operation,
  OperationDevice,
  AlarmActive,
  Booking,
  DeviceLog,
  PenaltyRegistry,
  AlarmType,
  FcmMessage,
  FcmTopic,
  Ente,
  HistoryStatistics,
  Company,
  Position,
  TransactionStatistics,
  ScooterRegistrationRequests,
  Rental,
  RentalActive, Model,
  DocumentRegistrationRequests,
  DocumentTypeItem
} from './../classes';
import{ ConvCode } from './../classes/conv/Conv';

// BS service providers
import { SettingsmanagerService } from './settingsmanager.service';
import {IHub} from '../interfaces/WSInterfaces';
import { ConvDataDefinition } from '../classes/conv/ConvDataDefinition';
import { ConvInvocation } from '../classes/conv/ConvInvocation';
import { ConvUserData } from '../classes/conv/ConvUserData';
import { TimeSlot } from '../classes/TimeSlot';
import {debuglog} from 'util';


const BODY_START_COMMAND =  {
  'deviceId': 0
};

const COMMANDS = {

  'my-profile': {
    'command': 'user/info',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'rental-status': {
    'command': 'rental',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'booking-status': {
    'command': 'booking/own-active',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-all-devices': {
    'command': 'device',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-developers-names': {
    'command': 'config/DEV_NAMES',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-testers-names': {
    'command': 'config/TESTER_NAMES',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-all-operators': {
    'command': 'user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-all-users': {
    'command': 'user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-user-by-id': {
    'command': 'user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-users-by-role-id': {
    'command': 'user/role-id',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-device-data': {
    'command': 'device',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-device-data-filtered': {
    'command': 'device/filtered',
    'method': 'POST',
    'template_body': {},
    'response_type': 'json'
  },

  'get-devices-by-city': {
    'command': 'device/byCity',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-zones-by-city': {
    'command': 'zone/byCity',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-zones': {
    'command': 'zone',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-city': {
    'command': 'city',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-cities': {
    'command': 'city',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-cities-pricing': {
    'command': 'city/pricing',
    'method': 'POST',
    'template_body': {},
    'response_type': 'json'
  },

  'change-city-availability': {
    'command': 'city/availability',
    'method': 'POST',
    'template_body': {},
    'response_type': 'text'
  },

  'get-city-assigned-users': {
    'command': 'city/assigned-users',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'set-city-assigned-users': {
    'command': 'city/assigned-users',
    'method': 'POST',
    'template_body': {
      'userId': null
    },
    'response_type': 'json'
  },

  'delete-city-assigned-users': {
    'command': 'city/assigned-users',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'json'
  },

  'add-zone': {
    'command': 'zone',
    'method': 'POST',
    'template_body': {
      'coordinates': null,
      'name': null,
      'description': null,
      'cityId': null,
      'commandTypeId': null,
      'park_enabled': null,
      'discount_on_total': null,
      'discount_on_fee': null,
      'color': null
    },
    'response_type': 'json'
  },

'edit-zone': {
    'command': 'zone',
    'method': 'PATCH',
    'template_body': {
      'coordinates': null,
      'name': null,
      'description': null,
      'cityId': null,
      'commandTypeId': null,
      'park_enabled': null,
      'discount_on_total': null,
      'discount_on_fee': null,
      'color': null
    },
    'response_type': 'json'
  },

  'add-hub': {
    'command': 'hub',
    'method': 'POST',
    'template_body': {
      'name': null,
      'description': null,
      'cityId': null,
      'longitude': null,
      'latitude': null,
      'devices': null,
      'desired_devices': null,
      'hubType': null,
      'coordinates': null,
    },
    'response_type': 'json'
  },

  'edit-hub': {
    'command': 'hub',
    'method': 'PATCH',
    'template_body': {
      'name': null,
      'description': null,
      'cityId': null,
      'longitude': null,
      'latitude': null,
      'desired_devices': null,
      'hubType': null,
      'coordinates': null,
    },
    'response_type': 'json'
  },

'edit-city': {
    'command': 'city',
    'method': 'POST',
    'template_body': {
      'coordinates': null,
      'code': null,
      'active_fee': null,
      'description': null,
      'break_fee': null,
      'unlock_fee': null,
      'commandTypeId': null,
      'park_enabled': null,
      'color': null,
      'center_latitude': null,
      'center_longitude': null
    },
    'response_type': 'json'
  },

  'add-city': {
    'command': 'city',
    'method': 'POST',
    'template_body': {
      'name': null,
      'description': null,
      'center_latitude': null,
      'center_longitude': null
    },
    'response_type': 'json'
  },

  'reset-city-zone': {
    'command': 'city',
    'method': 'POST',
    'template_body': {
      'coordinates': null,
    },
    'response_type': 'json'
  },

  'delete-zone': {
    'command': 'zone',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'delete-hub': {
    'command': 'hub',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'delete-user': {
    'command': 'user',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'ring-bell': {
    'command': 'command/buzon',
    'method': 'POST',
    'template_body': {},
    'response_type': 'json'
  },

  'start-rental': {
    'command': 'rental',
    'method': 'POST',
    'template_body': {
      'deviceId': null,
      'currentAppVersion': null,
      'currentOS': null
    },
    'response_type': 'text'
  },

  'close-rental': {
    'command': 'rental/stop/zimo/p2p',
    // 'command': 'rental/stop/zimo/nophoto',
    // 'command': 'rental/stop/zimo/test',
    'method': 'POST',
    'template_body': {
      'latitude': null,
      'longitude': null,
      'forcePark': null
    },
    'response_type': 'text'
  },

  'stop-rental-admin': {
    'command': 'rental/stop/zimo/admin',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'close-rental-with-photo': {
    'command': 'rental/stop/zimo/photo',
    'method': 'POST',
    'template_body': {
      'streamfile': null,
      'latitude': null,
      'longitude': null
    },
    'response_type': 'text',
    'request_type': 'multipart/form-data'
  },

  'add-photo': {
    'command': 'rental/add-photo',
    'method': 'POST',
    'template_body': {
      'streamfile': null,
      'rentalId': null
    },
    'response_type': 'text',
    'request_type': 'multipart/form-data'
  },

  'check-position': {
    'command': 'rental/check-position',
    'method': 'POST',
    'template_body': {
      'latitude': null,
      'longitude': null,
    },
    'response_type': 'text',
    'request_type': 'multipart/form-data'
  },

  'get-active-rental-by-device' : {
    'command': 'rental/active/by-device',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'start-break': {
    'command': 'break',
    'method': 'POST',
    'template_body': {},
    'response_type': 'text'
  },

  'stop-break': {
    'command': 'break',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'get-all-trips': {
    'command': 'rental/history',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'add-device': {
    'command': 'device',
    'method': 'POST',
    'template_body': {
      'id': null,
      'imei': null,
      'protocol': null,
      'model': null,
      'stateId': 1,
      'cityId': null,
      'contact': null,
      'blocked': null
    },
    'response_type': 'text'
  },

  'edit-device': {
    'command': 'device',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'json'
  },

  'send-geolocation': {
    'command': 'position',
    'method': 'POST',
    'template_body': {
      'latitude': null,
      'longitude': null,
      'speed': null,
      'altitude': null,
      'satellites': 1,
      'deviceId': null,
      'sig_quality': null,
      'sourceId': null
    },
    'response_type': 'text'
  },

  'delete-device': {
    'command': 'device',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'reset-device': {
    'command': 'device/reset',
    'method': 'POST',
    'template_body': {},
    'response_type': 'text'
  },

  'edit-user': {
    'command': 'user',
    'method': 'PATCH',
    'template_body': {
      // 'blocked': null,
      // 'email_confirmed': null
    },
    'response_type': 'text'
  },

  'user-edit-own-data': {
    'command': 'user/data',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'text'
  },

  'get-last-events': {
    'command': 'event/recent',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-all-vouchers': {
    'command': 'voucher',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-user-vouchers': {
    'command': 'voucher/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'add-voucher': {
    'command': 'voucher',
    'method': 'POST',
    'template_body': {
      'value': null,
      'voucherTypeId': null,
      'expiration_date': null,
      'cityId': null
    },
    'response_type': 'json'
  },

  'invoke-voucher': {
    'command': 'voucher/invoke',
    'method': 'POST',
    'template_body': {
      'voucher_code': null
    },
    'response_type': 'text'
  },

  'delete-voucher': {
    'command': 'voucher',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'get-active-rentals': {
    'command': 'rental/active',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-rental-history': {
    'command': 'rental/history/all',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'become-charger': {
    'command': 'user/become-charger',
    'method': 'PATCH',
    'template_body': {},
    'response_type': 'text'
  },

  'get-active-recharge': {
    'command': 'charger/active/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-history-recharge': {
    'command': 'charger/history/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'start-recharge': {
    'command': 'charger',
    'method': 'POST',
    'template_body': {
      'deviceId': null
    },
    'response_type': 'text'
  },

  'get-chargers-info': {
    'command': 'charger/info',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'enable-charger': {
    'command': 'charger/enable',
    'method': 'POST',
    'template_body': {
      'userId': null
    },
    'response_type': 'text'
  },

  'disable-charger': {
    'command': 'charger/disable',
    'method': 'POST',
    'template_body': {
      'userId': null
    },
    'response_type': 'text'
  },

  'release-device-phase1': {
    'command': 'charger/release',
    'method': 'POST',
    'template_body': {
      'deviceId': null
    },
    'response_type': 'text'
  },

  'release-device-phase2': {
    'command': 'charger/release/end',
    'method': 'POST',
    'template_body': {
      'deviceId': null
    },
    'response_type': 'text'
  },

  'invite-friend': {
    'command': 'mail/invite-friend',
    'method': 'POST',
    'template_body': {
      'friend_email': null,
      'message': null
    },
    'response_type': 'text'
  },

  'send-vehicle-report': {
    'command': 'warning',
    'method': 'POST',
    'template_body': {
      'streamfile': null,
      'data': {
        'deviceId': null,
        'warningTypeId': null,
        'description': null,
      }
    },
    'response_type': 'text',
    'request_type': 'multipart/form-data'
  },

  'get-user-documents': {
    'command': 'document/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'add-document': {
    'command': 'document',
    'method': 'POST',
    'template_body': {
      'streamfile': null,
      'data': {
        'documentTypeId': null,
        'released_by': null,
        'doc_number': null,
        'expiration_date': null,
      }
    },
    'response_type': 'text',
    'request_type': 'multipart/form-data'
  },

  'delete-document': {
    'command': 'document/own',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'download-document': {
    'command': 'document/download/own',
    'method': 'GET',
    'template_body': {},
    'response_type': 'blob'
  },

  'download-document-admin': {
    'command': 'document/download',
    'method': 'GET',
    'template_body': {},
    'response_type': 'blob'
  },

  'get-user-notifications': {
    'command': 'notification/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-hubs': {
    'command': 'hub/test',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-user-payment-methods': {
    'command': 'payment/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'add-user-payment-method': {
    'command': 'payment',
    'method': 'POST',
    'template_body': {
      'card_number': null,
      'owner_firstName': null,
      'owner_lastName': null,
      'expiration_date': null,
      'paymentTypeId': null,
      'cvv2': null
    },
    'response_type': 'json'
  },

  'delete-user-payment-method': {
    'command': 'payment',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'get-season-ticket-types': {
    'command': 'ticket/type',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-all-season-tickets': {
    'command': 'ticket',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-user-season-tickets': {
    'command': 'ticket/user',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'add-season-ticket-type': {
    'command': 'ticket/type',
    'method': 'POST',
    'template_body': {
      'name': null,
      'description': null,
      'isValid': null,
      'price': null,
      'duration': null
    },
    'response_type': 'text'
  },

  'add-season-ticket': {
    'command': 'ticket',
    'method': 'POST',
    'template_body': {
      'ticketTypeId': null
    },
    'response_type': 'text'
  },

  'add-subscription-from-admin': {
    'command': 'ticket/admin',
    'method': 'POST',
    'template_body': {
      'email': null,
      'ticketTypeId': null
    },
    'response_type': 'json'
  },

  'delete-subscription': {
    'command': 'ticket',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'send-command': {
    'command': 'command',
    'method': 'POST',
    'template_body': {
      'deviceId': null,
      'commandTypeId': null
    },
    'response_type': 'text'
  },

  'get-money-transactions': {
    'command': 'transaction/paging',
    'method': 'GET',
    'template_body': {
      'last': null,
      'rows': null
    },
    'response_type': 'json'
  },

  'get-users-paging': {
    'command': 'user/paging',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'get-rental_history-paging': {
    'command': 'rental/history/paging',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'get-log': {
    'command': 'log',
    'method': 'POST',
    'template_body': {
      'deviceId': null,
      't1': null,
      't2': null
    },
    'response_type': 'json'
  },

  'get-warnings': {
    'command': 'warning',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-warnings-paging': {
    'command': 'warning/paging',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'set-warning-managed': {
    'command': 'warning/set',
    'method': 'POST',
    'template_body': {},
    'response_type': 'text'
  },

  'download-warning-file': {
    'command': 'warning/download',
    'method': 'GET',
    'template_body': {},
    'response_type': 'blob'
  },

  'download-rental-file': {
    'command': 'rental/download',
    'method': 'GET',
    'template_body': {},
    'response_type': 'blob'
  },

  'add-report': {
    'command': 'report',
    'method': 'POST',
    'template_body': {
      'rentalId': null,
      'mark': null,
      'description': null,
      'faultId': null
    },
    'response_type': 'text'
  },

  'get-all-reports': {
    'command': 'report',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-report-stats': {
    'command': 'report/stat',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'execute-reversal': {
    'command': 'payment/rectify',
    'method': 'POST',
    'template_body': {
      'parentId': null,
      'transactionId': null,
      'amount': null,
      'reason': null
    },
    'response_type': 'json'
  },

  'execute-charge': {
    'command': 'payment/generate',
    'method': 'POST',
    'template_body': {
      'parentId': null,
      'userId': null,
      'amount': null,
      'reason': null
    },
    'response_type': 'json'
  },

  'get-card-blacklist': {
    'command': 'blacklist/info',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'delete-card-from-blacklist': {
    'command': 'blacklist',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'check-device-availability': {
    'command': 'device/is_available',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'check-device-availability-operation': {
    'command': 'device/is_available/operation',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-statistics': {
    'command': 'statistics',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-statistics-history-time': {
    'command': 'statistics/history/by-time',
    'method': 'POST',
    'template_body': {
      start: '',
      end: '',
      timeUnit: ''
    },
    'response_type': 'json'
  },

  'get-other-txs-statistics-history-time': {
    'command': 'statistics/history/by-time/other',
    'method': 'POST',
    'template_body': {
      start: '',
      end: '',
      timeUnit: ''
    },
    'response_type': 'json'
  },

  'get-statistics-time': {
    'command': 'statistics/by-time',
    'method': 'GET',
    'template_body': {
      't1': null,
      't2': null
    },
    'response_type': 'json'
  },

  'get-statistics-total-rentals': {
    'command': 'statistics/total-rentals',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-faults': {
    'command': 'fault',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  // 'add-fault': {
  //   'command': 'fault',
  //   'method': 'POST',
  //   'template_body': {
  //     'deviceId': null,
  //     'description': null
  //   },
  //   'response_type': 'json'
  // },

  'add-comment-to-fault': {
    'command': 'fault/new-comment',
    'method': 'POST',
    'template_body': {
      'id': null,
      'comment': null
    },
    'response_type': 'json'
  },

  'close-fault': {
    'command': 'fault/close',
    'method': 'POST',
    'template_body': {
      'id': null,
      'price': null
    },
    'response_type': 'json'
  },

  'get-user-promotion': {
    'command': 'promotion/user',
    'method': 'POST',
    'template_body': {
      'prootionCode': null
    },
    'response_type': 'json'
  },

  'get-valid-user-promotion': {
    'command': 'promotion/user/active',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'delete-valid-user-promotion': {
    'command': 'promotion/user',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'get-promotions': {
    'command': 'promotion',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'new-promotion': {
    'command': 'promotion',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'edit-promotion': {
    'command': 'promotion',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'text'
  },

  'get-pricing-plan': {
    'command': 'pricing-plan',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'new-pricing-plan': {
    'command': 'pricing-plan',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'edit-pricing-plan': {
    'command': 'pricing-plan',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'text'
  },

  'assign-plan-to-user': {
    'command': 'pricing-plan/user',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'text'
  },

  'save_terms': {
    'command': 'user/edit-terms',
    'method': 'PATCH',
    'template_body': {
      'privacy_conditions_accepted': null,
      'usage_conditions_accepted': null,
      'unfair_terms_accepted': null
    },
    'response_type': 'json'
  },

  'reset-terms': {
    'command': 'user/reset-terms',
    'method': 'PATCH',
    'template_body': {
    },
    'response_type': 'json'
  },

  'reset-temp-pricing-plan': {
    'command': 'pricing-plan/user/temp',
    'method': 'DELETE',
    'template_body': {
    },
    'response_type': 'json'
  },

  'get-settings': {
    'command': 'config',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-own-operations': {
    'command': 'operation/own',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-operations': {
    'command': 'operation',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-alarms': {
    'command': 'alarm',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-alarms-unmanaged': {
    'command': 'alarm/unmanaged',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-alarms-managed': {
    'command': 'alarm/managed',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'set-alarm-as-seen': {
    'command': 'alarm',
    'method': 'POST',
    'template_body': {},
    'response_type': 'json'
  },

  'set-alarm-as-closed': {
    'command': 'alarm/close',
    'method': 'POST',
    'template_body': {},
    'response_type': 'json'
  },

  'get-device-log-op': {
    'command': 'operation/log',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-device-operation-detail': {
    'command': 'operation/device',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'get-device-data-operator': {
    'command': 'operation/device-data',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'start-operation': {
    'command': 'operation/start',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'stop-operation': {
    'command': 'operation/stop',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  // 'add-operation-request': {
  //   'command': 'operation/new-request',
  //   'method': 'POST',
  //   'template_body': {
  //     'deviceId': null,
  //     'description': null
  //   },
  //   'response_type': 'json'
  // },

  'start-booking': {
    'command': 'booking',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'get-active-bookings' : {
    'command': 'booking',
    'method': 'GET',
    'template_body': {},
    'response_type': 'json'
  },

  'delete-booking': {
    'command': 'booking',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'delete-booking-admin': {
    'command': 'booking/remove/admin',
    'method': 'DELETE',
    'template_body': {},
    'response_type': 'text'
  },

  'check-hub-exists': {
    'command': 'hub/from-coordinates',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'payment-penalty': {
    'command': 'payment/penalty',
    'method': 'POST',
    'template_body': {
    },
    'response_type': 'json'
  },

  'payment-penalty-all': {
    'command': 'payment/penalty/all',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'get-alarm-types-list': {
    'command': 'alarm/types',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'subscription-list': {
    'command': 'packet/type',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },


  /** PUSH NOTIFICATIONS **/

  'send-fcm-message': {
    'command': 'fcmmessage/sendMessage',
    'method': 'POST',
    'template_body': {
      'topicId': null,
      'name': null,
      'description': null
    },
    'response_type': 'json'
  },

  'fetch-fcm-messages': {
    'command': 'fcmmessage',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },

  'fetch-fcm-topics': {
    'command': 'fcmtopic',
    'method': 'GET',
    'template_body': {
    },
    'response_type': 'json'
  },
};

@Injectable({
  providedIn: 'root'
})
export class WsmanagerService {

  token = '';
  tmpBlobFile: any = null;
  formData: any = null;

  constructor(private settingsManager: SettingsmanagerService,
              private httpClient: HttpClient,
              private file: File
  ) {
    this.getToken();
  }

  getToken() {
    return this.settingsManager.getToken();
  }

  getTokenKey() {
    return this.settingsManager.getTokenKey();
  }

  getProfileKey() {
    return this.settingsManager.getProfileKey();
  }

  getBaseWsUrl() {
    return this.settingsManager.getBaseWsUrl();
  }

  getWsHeader(content_type = 'application/json') {
    const tempToken = this.getToken();
    // console.log('@@@@@@@ TOKEN1:', tempToken);
    const headers = {
      'Content-Type': content_type,
      'Accept': 'plain/text',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache',
      'auth': tempToken
    };
    // console.log('@@@HEADERS:', headers);
    return new HttpHeaders(headers);
  }

  getFormMultipartHeader() {
    const tempToken = this.getToken();
    console.log('@@@@@@@ TOKEN:', tempToken);
    const headers = {
      'enctype': 'multipart/form-data;',
      'Accept': 'plain/text',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache',
      'auth': tempToken,
    };
    console.log('@@@HEADERS:', headers);
    return new HttpHeaders(headers);
  }

  getMethodByCommand(command) {
    return COMMANDS[command].command;
  }

  getTemplateBodyByCommand(command) {
    return COMMANDS[command].template_body;
  }

  getResponseTypeByCommand(command) {
    return COMMANDS[command].response_type;
  }

  getRequestTypeByCommand(command) {
    return COMMANDS[command].request_type;
  }

  /*** MAP MANAGEMENT ***/
  fetchDevicesByCityId(cityId = 0): Observable<Device[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchDevicesLastPosition:get-devices CARICO MEZZI ***');
    const commandName = 'get-devices-by-city';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<Device[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchAllDevices(): Observable<Device[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchDevicesLastPosition:get-devices CARICO MEZZI ***');
    const commandName = 'get-device-data';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<Device[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchAllDevicesWithFilters(objFilters: Object): Observable<Device[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-device-data-filtered';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    console.log('@@fetchAllDevicesWithFilters:', objFilters);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post<Device[]>(
      commandUrl,
      objFilters,
      { headers: this.getWsHeader(), responseType: responseType }
    ).pipe(
      map(x => x.map(xx => new Device(xx)))
    );
  }

  /*** ZONES MANAGEMENT ***/
  fetchZonesByCityId(cityId = 0): Observable<Zone[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchZonesByCityId ***', cityId);
    const commandName = 'get-zones-by-city';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<Zone[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchAllZones(): Observable<Zone[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchAllZones ***');
    const commandName = 'get-zones';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<Zone[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchCityData(cityId: number): Observable<City> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchCityData ***', cityId);
    const commandName = 'get-city';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<City>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchCitiesData(): Observable<City[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchCitiesData ***');
    const commandName = 'get-cities';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<City[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchCitiesPricingData(latitude: number, longitude: number): Observable<City[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchCitiesData ***');
    const commandName = 'get-cities-pricing';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.latitude = latitude;
    commandBody.longitude = longitude;

    return this.httpClient.post<City[]>(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchCitiesAssignedUsers(): Observable<City[]> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** fetchCitiesAssignedUsers ***');
    const commandName = 'get-city-assigned-users';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);

    return this.httpClient.get<City[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  getCityAssignedUsers(cityId: number): Observable<City> {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** getCityAssignedUsers ***', cityId);
    const commandName = 'get-city-assigned-users';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);

    return this.httpClient.get<City>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  addCityAssignedUsers(cityId: number, userId: number) {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** setCityAssignedUsers ***', cityId, userId);
    const commandName = 'set-city-assigned-users';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);

    commandBody.userId = userId;

    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  deleteCityAssignedUsers(cityId: number, userId: number) {
    this.settingsManager.incrDebugWsCalls();
    // console.log('*** deleteCityAssignedUsers ***', cityId, userId);
    const commandName = 'delete-city-assigned-users';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId, userId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);

    return this.httpClient.delete(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  changeCityAvailability(cityId: number, availability: boolean) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'change-city-availability';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), cityId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    commandBody.availability = availability;
    const responseType = this.getResponseTypeByCommand(commandName);

    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  // addNewZone(zoneObject) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'add-zone';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const commandBody = this.getTemplateBodyByCommand(commandName);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   commandBody.coordinates = zoneObject.coordinates;
  //   commandBody.name = zoneObject.name;
  //   commandBody.description = zoneObject.description;
  //   commandBody.cityId = zoneObject.cityId;
  //   // console.log('----------->zoneObject.commandTypeId:', zoneObject.commandTypeId);
  //   if ((zoneObject.commandTypeId) && (Number(zoneObject.commandTypeId) !== 0)  ) {
  //     commandBody.commandTypeId = zoneObject.commandTypeId;
  //   } else {
  //     commandBody.commandTypeId = null;
  //   }
  //   commandBody.park_enabled = zoneObject.park_enabled === 'false' ? false : true;
  //   commandBody.free_unlock = zoneObject.free_unlock === 'false' ? false : true;
  //   commandBody.isVisible = zoneObject.isVisible === 'false' ? false : true;
  //   commandBody.warning_chiusura_attivo = zoneObject.warning_chiusura_attivo === 'false' ? false : true;
  //   commandBody.discount_on_total = zoneObject.discount_on_total;
  //   commandBody.discount_on_fee = zoneObject.discount_on_fee;
  //   commandBody.color = zoneObject.color;
  //   commandBody.area = zoneObject.area;
  //   // console.log('addNewZone WS zoneObject:', zoneObject);
  //   // console.log('addNewZone WS commandBody:', commandBody);
  //   return this.httpClient.post(commandUrl,
  //     commandBody,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }


  // editZone(zoneObject) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'edit-zone';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const completeCommandUrl = [commandUrl, zoneObject.id].join('/');
  //   const commandBody = this.getTemplateBodyByCommand(commandName);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   commandBody.coordinates = zoneObject.coordinates;
  //   commandBody.name = zoneObject.name;
  //   commandBody.description = zoneObject.description;
  //   commandBody.cityId = zoneObject.cityId;
  //   commandBody.cityId = zoneObject.cityId;
  //   // console.log('----------->zoneObject.commandTypeId:', zoneObject.commandTypeId);
  //   if ((zoneObject.commandTypeId) && (Number(zoneObject.commandTypeId) !== 0)) {
  //     commandBody.commandTypeId = zoneObject.commandTypeId;
  //   } else {
  //     commandBody.commandTypeId = null;
  //   }
  //   commandBody.park_enabled = zoneObject.park_enabled === 'false' ? false : true;
  //   commandBody.free_unlock = zoneObject.free_unlock === 'false' ? false : true;
  //   commandBody.isVisible = zoneObject.isVisible === 'false' ? false : true;
  //   commandBody.warning_chiusura_attivo = zoneObject.warning_chiusura_attivo === 'false' ? false : true;
  //   commandBody.discount_on_total = zoneObject.discount_on_total;
  //   commandBody.discount_on_fee = zoneObject.discount_on_fee;
  //   commandBody.color = zoneObject.color;
  //   commandBody.area = zoneObject.area;
  //   // console.log('editZone WS:', commandBody);
  //   return this.httpClient.patch(completeCommandUrl,
  //     commandBody,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }

  // addNewHub(hubObject) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'add-hub';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const commandBody = this.getTemplateBodyByCommand(commandName);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   commandBody.name = hubObject.name;
  //   commandBody.description = hubObject.description;
  //   commandBody.latitude = hubObject.latitude;
  //   commandBody.longitude = hubObject.longitude;
  //   commandBody.cityId = hubObject.cityId;
  //   commandBody.desired_devices = hubObject.desired_devices;
  //   commandBody.range_meters = hubObject.range_meters;
  //   commandBody.discount_on_active_fee = hubObject.discount_on_active_fee;
  //   commandBody.discount_on_break_fee = hubObject.discount_on_break_fee;
  //   commandBody.discount_on_unlock_fee = hubObject.discount_on_unlock_fee;
  //   commandBody.isVisible = hubObject.isVisible === 'false' ? false : true;
  //   commandBody.hubType = hubObject.hubType;
  //   commandBody.coordinates = hubObject.coordinates;
  //   commandBody.devices = 0;

  //   console.log('add HUB object', hubObject);

  //   return this.httpClient.post(commandUrl,
  //     commandBody,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }

  // editHub(hubObject) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'edit-hub';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const completeCommandUrl = [commandUrl, hubObject.id].join('/');
  //   const commandBody = this.getTemplateBodyByCommand(commandName);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   commandBody.name = hubObject.name;
  //   commandBody.description = hubObject.description;
  //   commandBody.latitude = hubObject.latitude;
  //   commandBody.longitude = hubObject.longitude;
  //   commandBody.cityId = hubObject.cityId;
  //   commandBody.desired_devices = hubObject.desired_devices;
  //   commandBody.range_meters = hubObject.range_meters;
  //   commandBody.discount_on_active_fee = hubObject.discount_on_active_fee;
  //   commandBody.discount_on_break_fee = hubObject.discount_on_break_fee;
  //   commandBody.discount_on_unlock_fee = hubObject.discount_on_unlock_fee;
  //   commandBody.isVisible = hubObject.isVisible === 'false' ? false : true;
  //   commandBody.hubType = hubObject.hubType;
  //   commandBody.coordinates = hubObject.coordinates;

  //   console.log('edit HUB object', hubObject);

  //   return this.httpClient.patch(completeCommandUrl,
  //     commandBody,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }

  editCity(cityObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-city';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, cityObject.id].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.coordinates = cityObject.coordinates;
    commandBody.area = cityObject.area;
    commandBody.description = cityObject.description;
    commandBody.center_latitude = cityObject.center_latitude;
    commandBody.center_longitude = cityObject.center_longitude;
    commandBody.code = cityObject.code;
    commandBody.discount_on_active_fee = cityObject.discount_on_active_fee;
    commandBody.discount_on_break_fee = cityObject.discount_on_break_fee;
    commandBody.discount_on_unlock_fee = cityObject.discount_on_unlock_fee;
    commandBody.discount_on_booking_fee = cityObject.discount_on_booking_fee;
    // console.log('----------->cityObject.commandTypeId:', cityObject.commandTypeId);
    if ((cityObject.commandTypeId) && (Number(cityObject.commandTypeId) !== 0)) {
      commandBody.commandTypeId = cityObject.commandTypeId;
    } else {
      commandBody.commandTypeId = null;
    }
    commandBody.park_enabled = cityObject.park_enabled === 'false' ? false : true;
    commandBody.isVisible = cityObject.isVisible === 'false' ? false : true;
    commandBody.avviso_sanzioni_attivo = cityObject.avviso_sanzioni_attivo === 'false' ? false : true;
    commandBody.warning_chiusura_attivo = cityObject.warning_chiusura_attivo === 'false' ? false : true;
    commandBody.isDocumentRequired = cityObject.isDocumentRequired === 'false' ? false : true;
    commandBody.isFullSubscriptionRequired = cityObject.isFullSubscriptionRequired === 'false' ? false : true;
    commandBody.color = cityObject.color;
    commandBody.pricingPlanId = cityObject.pricingPlanId;
    commandBody.scooter_coordinates = cityObject.scooter_coordinates;
    commandBody.scooter_area = cityObject.scooter_area;
    commandBody.scooter_area_color = cityObject.scooter_area_color;
    commandBody.scooterauto_parking_enabled = cityObject.scooterauto_parking_enabled === 'false' ? false : true;

    // console.log('editCity WS:', commandBody);
    return this.httpClient.post(completeCommandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  addCity(cityObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-city';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.name = cityObject.name;
    commandBody.description = cityObject.description;
    commandBody.center_latitude = cityObject.center_latitude;
    commandBody.center_longitude = cityObject.center_longitude;
    console.log('WS addCity', completeCommandUrl, commandBody);
    return this.httpClient.post(completeCommandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  // deleteZone(zoneId: number) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'delete-zone';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const completeCommand = [commandUrl, zoneId].join('/');
  //   console.log(completeCommand);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return this.httpClient.delete(completeCommand,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }

  // deleteHub(hubId: number) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'delete-hub';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const completeCommand = [commandUrl, hubId].join('/');
  //   console.log(completeCommand);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return this.httpClient.delete(completeCommand,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }

  resetCityZone(cityId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'reset-city-zone';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, cityId].join('/');
    console.log(completeCommandUrl);
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.coordinates = '';
    commandBody.scooter_coordinates = '';

    return this.httpClient.post(completeCommandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /* DEVICE MANAGEMENT */
  fetchDeviceData(deviceId = 0): Observable<Device> {
    this.settingsManager.incrDebugWsCalls();
    console.log('*** fetchDeviceData1: STATO MEZZO ***');
    const commandName = 'get-device-data';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceId
    ].join('/');
    console.log('fetchDeviceData:', commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<Device>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType}).pipe(
        map(results => new Device(results) )
      );
  }

  ringBellByDeviceId(deviceId = 0): Observable<Device> {
    this.settingsManager.incrDebugWsCalls();
    console.log('*** ringBellByDeviceId ***', deviceId);
    const commandName = 'ring-bell';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceId
    ].join('/');
    // console.log(commandUrl);
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post<Device>(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  setPoliceOnly(deviceId, flagValue) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-device';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, deviceId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.policeOnly = flagValue;
    return this.httpClient.patch(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  checkDeviceAvailability(deviceName) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'check-device-availability';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceName
    ].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  checkDeviceAvailabilityForOperation(deviceId) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'check-device-availability-operation';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceId
    ].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** RENTAL MANAGEMENT ***/
  startRental(startRentalData) {
    const idDevice = startRentalData.deviceId;
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'start-rental';
    const stopRentalUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceId = idDevice;
    commandBody.currentAppVersion = startRentalData.currentAppVersion;
    commandBody.currentOS = startRentalData.currentOS;
    return this.httpClient.post(stopRentalUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  // closeRental(deviceId: number, coordinates: any, forcePark= false) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'close-rental';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const commandBody = this.getTemplateBodyByCommand(commandName);
  //   const responseType = this.getResponseTypeByCommand(commandName);

  //   commandBody.latitude = coordinates.latitude;
  //   commandBody.longitude = coordinates.longitude;
  //   commandBody.forcePark = forcePark;

  //   const HUB_MODE = this.settingsManager.getCloseInHub();

  //   // if (mode && (mode === HUB_MODE)) {
  //   //   commandBody.qrCode = deviceId;
  //   // } else {
  //   //   commandBody.qrCode = null;
  //   // }

  //   console.log('--->closeRental: ', commandBody, forcePark);

  //   return this.httpClient.post(commandUrl,
  //     commandBody,
  //     {headers: this.getWsHeader(), responseType: responseType});
  // }


  checkCurrentPosition(coordinates) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'check-position';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);

    commandBody.latitude = coordinates.latitude;
    commandBody.longitude = coordinates.longitude;

    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }


  startBreakRental(deviceId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'start-break';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  stopBreakRental(deviceId: number): Observable<RentalReport> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'stop-break';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete<RentalReport>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  getRentalStatus() {
    console.log('*** getRentalStatus ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'rental-status';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<RentalStatus>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  getActiveRentalByDevice(deviceId: number) {
    const commandName = 'get-active-rental-by-device';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceId
    ].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<ActiveRentalFlatten>(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchRentalActiveDetail(rentalActiveId: number): Observable<RentalActive> {
    const url = this.getBaseWsUrl() + '/rental/active/' + rentalActiveId;
    return this.httpClient.get<RentalActive>(url, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(result => new RentalActive(result)));
  }


  /*** USERS MANAGEMENT ***/

  getMyProfileInfo() {
    console.log('*** getMyProfileInfo ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'my-profile';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    console.log('getMyProfileInfo', this.getWsHeader());
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<CustomerProfile>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchUsers(): Observable<User[]> {
    console.log('*** fetchUsers ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-users';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<User[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new User(result));
      }));
  }

  fetchUsersByRoleId(roleId: number): Observable<User[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-users-by-role-id';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), roleId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<User[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchUsersPaging(last: number, rowsNumber: number): Observable<User[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-users-paging';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    let params = new HttpParams();
    params = params.append('rows', rowsNumber.toString());
    if (last) {
      params = params.append('last', last.toString());
    }
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<User[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType, params: params})
        .pipe(map(results => {
          return results.map((result) => new User(result));
        }));
  }

  getUser(userId: number): Observable<User> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-by-id';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), userId].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<User>(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(result => {
        return new User(result);
      }));
  }

  deleteUser(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  changeUserRole(userId: number, roleId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(completeCommandUrl,
      { roleId: roleId },
      {headers: this.getWsHeader(), responseType: responseType});
  }

  associateUserCompany(userId: number, companyId: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'user', userId].join('/');

    return this.httpClient.patch(
      commandUrl,
      { companyId: companyId },
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  disassociateUserCompany(userId: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'user', userId].join('/');

    return this.httpClient.patch(
      commandUrl,
      { companyId: null },
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  blockUnlockUser(userId: number, blocked: boolean) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(completeCommandUrl,
      { blocked: blocked },
      {headers: this.getWsHeader(), responseType: responseType});
  }

  confirmUserEmail(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(completeCommandUrl,
        { email_confirmed: true },
        {headers: this.getWsHeader(), responseType: responseType});
  }

  activateUser(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(completeCommandUrl,
        { account_confirmed: true },
        {headers: this.getWsHeader(), responseType: responseType});
  }

  becomeCharger() {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'become-charger';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  editUserOwnData(user) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'user-edit-own-data';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    if (user.firstName) {
      commandBody.firstName = user.firstName;
    }
    if (user.lastName) {
      commandBody.lastName = user.lastName;
    }
    if (user.telephone) {
      commandBody.telephone = user.telephone;
    }
    if (user.birth_date) {
      commandBody.birth_date = user.birth_date;
    }
    if (user.gender) {
      commandBody.gender = user.gender;
    }
    if (user.cap) {
      commandBody.cap = user.cap;
    }
    if (user.appVersion) {
      commandBody.appVersion = user.appVersion;
    }
    console.log('editUserOwnData --arriva: ', user);
    console.log('editUserOwnData --invio: ', commandBody);
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }


  /* Send Geolocation to the server */

  sendGeodataToServer(geodata) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'send-geolocation';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.latitude = geodata.latitude;
    commandBody.longitude = geodata.longitude;
    commandBody.speed = geodata.speed;
    commandBody.altitude = geodata.altitude;
    commandBody.satellites = geodata.satellites;
    commandBody.deviceId = geodata.deviceId;
    commandBody.sig_quality = geodata.sig_quality;
    commandBody.sourceId = geodata.sourceId;
    // console.log('@@@sendGeodataToServer: ', commandBody);
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** DEVICES MANAGEMENT ***/
  fetchDevices(): Observable<Device[]> {
    // console.log('*** fetchDevices ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-devices';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Device[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Device(result));
      }));
  }

  addNewDevice(cityId: number, device: Device) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-device';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    if (device.id) {
      commandBody.id = device.id;
    }
    commandBody.name = device.name;
    commandBody.imei = device.imei;
    commandBody.protocol = device.protocol;
    commandBody.modelId = device.modelId;
    commandBody.deviceCategory = device.deviceCategory;
    commandBody.cityId = cityId;
    // commandBody.contact = device.contact;
    commandBody.blocked = device.blocked;
    return this.httpClient.post(addDeviceUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  editDevice(cityId: number, device: Device) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-device';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, device.id].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    if (device.id){
      commandBody.id = device.id;
    }
    commandBody.name = device.name;
    commandBody.categoryFlags = device.deviceCategory;
    commandBody.imei = device.imei;
    commandBody.protocol = device.protocol;
    commandBody.modelId = device.modelId;
    commandBody.cityId = cityId;
    commandBody.blocked = device.blocked;
    commandBody.stateId = device.stateId;
    return this.httpClient.patch(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  changeDeviceState(deviceId: number, deviceName: string, stateId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-device';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = [commandUrl, deviceId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.stateId = stateId;
    return this.httpClient.patch(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  deleteDevice(deviceId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-device';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, deviceId].join('/');
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  resetDevice(deviceId: number) {
    // console.log('@@@ RESET DEVICE @@@');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'reset-device';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, deviceId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    console.log('##########', completeCommand);
    return this.httpClient.post(completeCommand,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** CONV MANAGEMENT ***/
  getConvCode(code: string): Observable<ConvCode> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/bycode';
    const commandUrl = [this.getBaseWsUrl(), command, code].join('/');

    return this.httpClient.get<ConvCode>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(result => {
        return new ConvCode(result);
      }));
  }

  fetchConv(): Observable<ConvCode[]> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv';
    const commandUrl = [this.getBaseWsUrl(), command ].join('/');

    return this.httpClient
      .get<ConvCode[]>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map((result) => new ConvCode(result));
      }));
  }

  editConv(conv: ConvCode): Observable<ConvCode> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv';
    const commandUrl = [this.getBaseWsUrl(), command, conv.id].join('/');

    return this.httpClient
      .patch<ConvCode>(commandUrl, conv,
        {headers: this.getWsHeader(), responseType: 'json'})
      .pipe( map (val => new ConvCode(val)));
  }

  newConv(values):  Observable<ConvCode> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');

    return this.httpClient
      .post<ConvCode>(commandUrl, values,
        {headers: this.getWsHeader(), responseType: 'json'})
      .pipe( map (val => new ConvCode(val)));
  }

  disableUsers(code: string) {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/disable/conv-users';
    const commandUrl = [this.getBaseWsUrl(), command, code].join('/');

    return this.httpClient.patch<ConvCode>(commandUrl, {},
      {headers: this.getWsHeader(), responseType: 'json'});
  }

  fetchConvDataDefinitions(): Observable<ConvDataDefinition[]>{
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/data-definition';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');

    return this.httpClient
      .get<ConvDataDefinition[]>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map((result) => new ConvDataDefinition(result));
      }));
  }

  getConvDataDefinition(id): Observable<ConvDataDefinition>{
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/data-definition';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient
      .get<ConvDataDefinition>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'});
  }

  editConvDataDefinition(id, values){
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/data-definition';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient.patch<ConvDataDefinition>(commandUrl, values,
      {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(result => {
        return new ConvDataDefinition(result);
      }));
  }

  newConvDataDefinition(values): Observable<ConvDataDefinition>{
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/data-definition';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');

    return this.httpClient.post<ConvDataDefinition>(commandUrl, values,
        {headers: this.getWsHeader(), responseType: 'json'});
  }

  fetchConvInvocation(): Observable<ConvInvocation[]>{
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');

    return this.httpClient
      .get<ConvInvocation[]>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map((result) => new ConvInvocation(result));
      }));
  }

  getConvInvocation(id: number): Observable<ConvInvocation> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient.get<ConvInvocation>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(result => {
        return new ConvInvocation(result);
      }));
  }

  toggleUserConvention(id: number, flag: boolean){
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');
    const body = { enabled: flag};

    return this.httpClient.patch<ConvInvocation>(commandUrl, body,
      {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(result => {
        return new ConvInvocation(result);
      }));
  }

  getUserDatasByInvocationId(id: number): Observable<ConvUserData[]>{
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/user-data';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');
    return this.httpClient
      .get<ConvUserData[]>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map((result) => new ConvUserData(result));
      }));
  }

  verifyConvInvocation(id: number) {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation/verify';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient.post(commandUrl, {}, {headers: this.getWsHeader(), responseType: 'json'});
  }

  downloadConvUserDataPhoto(id: number): Observable<Blob> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation/photo';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient.get(commandUrl, {responseType: 'blob', headers: this.getWsHeader()});
  }

  setInvocationBlock(id: number, isBlocked: boolean) {
    this.settingsManager.incrDebugWsCalls();
    const command = 'conv/invocation/toggle-block';
    const commandUrl = [this.getBaseWsUrl(), command, id].join('/');

    return this.httpClient.post(commandUrl, {blocked: isBlocked}, {responseType: 'json', headers: this.getWsHeader()})
  }

  /*** EVENTS MANAGEMENT ***/
  fetchEvents(): Observable<Event[]> {
    // console.log('*** fetchEvents ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-last-events';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Event[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Event(result));
      }));
  }

  /*** VOUCHERS MANAGEMENT ***/
  fetchVouchers(): Observable<Voucher[]> {
    // console.log('*** fetchVouchers ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-vouchers';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Voucher[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Voucher(result));
      }));
  }

  fetchUserVouchers(): Observable<Voucher[]> {
    // console.log('*** fetchUserVouchers ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-vouchers';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Voucher[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        console.log('pipe');
        return results.map((result) => new Voucher(result));
      }));
  }

  addNewPricingPlanVoucher(pricingPlanId: number, preassignedTo: string, expiration_date: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-voucher';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.preassignedTo = preassignedTo;
    commandBody.pricingPlanId = pricingPlanId;
    commandBody.value = 1;
    commandBody.voucherTypeId = 3;
    commandBody.cityId = 1;
    commandBody.expiration_date = expiration_date;

    console.log(commandBody);
    return this.httpClient.post<Voucher>(
      addDeviceUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return new Voucher(results);
      }));
  }

  deleteVoucher(voucherId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-voucher';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, voucherId].join('/');
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  invokeVoucher(voucher_code: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'invoke-voucher';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.voucher_code = voucher_code;

    console.log(commandBody);
    return this.httpClient.post(addDeviceUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** RENTAL MANAGEMENT ***/
  /***
   * Returns user's rental history (Customer)
   */
  fetchTrips(): Observable<TripInfo[]> {
    // console.log('*** fetchTrips ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-trips';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<TripInfo[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new TripInfo(result));
      }));
  }

  /***
   * Returns entire rental history (Operators only)
   */
  fetchRentalHistory(): Observable<TripInfo[]> {
    // console.log('*** fetchVouchers ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-rental-history';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<TripInfo[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new TripInfo(result));
      }));
  }

  fetchRentalHistoryPaging(last: number, rowsNumber: number): Observable<TripInfo[]> {
    // console.log('*** fetchMoneyTransactions ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-rental_history-paging';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    let params = new HttpParams();
    params = params.append('rows', rowsNumber.toString());
    if (last) {
      params = params.append('last', last.toString());
    }
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<TripInfo[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType, params: params})
        .pipe(map(results => {
          return results.map((result) => new TripInfo(result));
        }));
  }

  fetchMaxRentalId(): Observable<number> {
    this.settingsManager.incrDebugWsCalls();
    const url = this.getBaseWsUrl() + '/rental/history/max-id';
    return this.httpClient.get<number>(url, {headers: this.getWsHeader()});
  }

  fetchRentalsInRangeId(minId: number, maxId: number){
    this.settingsManager.incrDebugWsCalls();
    const url = this.getBaseWsUrl() + '/rental/history/range-id/' + minId + '/' + maxId;

    return this.httpClient
    .get<TripInfo[]>(url, {headers: this.getWsHeader(), responseType: "json"})
    .pipe(map(results => {
      return results.map((result) => new TripInfo(result));
    }));
  }

  fetchActiveRentals(): Observable<ActiveRentalFlatten[]> {
    // console.log('*** fetchActiveRentals ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-active-rentals';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<ActiveRentalFlatten[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new ActiveRentalFlatten(result));
      }));
  }

  downloadRentalFile(rentalId: number): any {
    // console.log('*** downloadRentalFile ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'download-rental-file';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, rentalId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient.get(completeCommand, {responseType: 'arraybuffer', headers: this.getWsHeader()} )
        .subscribe(response => this.downLoadFile(response, 'image/jpeg'));
  }

  fetchRentalFile(rentalId: number): Observable<Blob> {
    this.settingsManager.incrDebugWsCalls();
    const url = this.getBaseWsUrl() + '/rental/download/' + rentalId;
    return this.httpClient.get(url, {headers: this.getWsHeader(), responseType: 'blob'});
  }

  // stopRental(deviceId: number): Observable<RentalReport> {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'stop-rental-admin';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const completeCommandUrl = [commandUrl, deviceId].join('/');
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return this.httpClient.delete<RentalReport>(completeCommandUrl,
  //       {headers: this.getWsHeader(), responseType: responseType});
  // }

  /*** CHARGERS MANAGEMENT ***/
  fetchUserActiveRecharges(): Observable<Recharge[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-active-recharge';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Recharge[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Recharge(result));
      }));
  }

  fetchUserHistoryRecharges(): Observable<Recharge[]> {
    // console.log('*** fetchHistoryRecharges ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-history-recharge';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Recharge[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Recharge(result));
      }));
  }

  releaseDevicePhase1(deviceId: number) {
    // console.log('*** releaseDevicePhase1 ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'release-device-phase1';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceId = deviceId;
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  releaseDevicePhase2(deviceId: number) {
    // console.log('*** releaseDevicePhase2 ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'release-device-phase2';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceId = deviceId;
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  startNewRecharge(deviceId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'start-recharge';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceId = deviceId;
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchChargersInfo(): Observable<ChargerInfo[]> {
    // console.log('*** fetchChargersInfo ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-chargers-info';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<ChargerInfo[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new ChargerInfo(result));
        }));
  }

  enableCharger(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'enable-charger';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.userId = userId;
    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  disableCharger(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'disable-charger';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.userId = userId;
    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  sendFriendInvitation(friend_email: string, message: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'invite-friend';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.friend_email = friend_email;
    commandBody.message = message;
    return this.httpClient.post(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** DOCUMENTS MANAGEMENT ***/
  fetchDocuments(): Observable<UserDocument[]> {
    // console.log('*** fetchUserDocuments ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-documents';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<UserDocument[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new UserDocument(result));
      }));
  }

  addNewDocument(values, fileObject, anonymous = false): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.settingsManager.incrDebugWsCalls();
      const commandName = 'add-document';
      const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
      const responseType = this.getResponseTypeByCommand(commandName);
      let headers = null;
      if (anonymous) {
        headers = {
          'enctype': 'multipart/form-data;',
          'Accept': 'plain/text',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
          'Cache-control': 'no-cache',
          'Expires': '0',
          'Pragma': 'no-cache'
        };
      } else {
        headers = this.getFormMultipartHeader();
      }

      console.log('addNewDocument valori arrivati al ws:', values);

      // 'data' fields
      this.formData = new FormData();
      const dataJson = {
        'documentTypeId': values.documentTypeId,
        'released_by': values.released_by,
        'doc_number': values.doc_number,
        'expiration_date': values.expiration_date,
        'email': values.email ? values.email : ''
      };
      const jsonString = JSON.stringify(dataJson);
      this.formData.append('data', jsonString);
      console.log('formData: ', this.formData.getAll('data'));

      if (fileObject == null) {

        // Sending the form without the file
        return this.sendMultipartForm(commandUrl, headers, responseType, resolve);

      } else {

        // 'streamfile' field
        this.loadTempFile(fileObject)
            .then((res) => {

              // Sending the form with the file
              this.formData.append('streamfile', this.tmpBlobFile, fileObject.filename);
              console.log('streamfile: ', this.formData.getAll('streamfile'));
              return this.sendMultipartForm(commandUrl, headers, responseType, resolve);

            }, (err) => {
              resolve(false);
            });
      }
    });
  }

  deleteDocument(documentId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-document';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, documentId].join('/');
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** PAYMENT METHODS MANAGEMENT ***/
  fetchUserPaymentMethods(): Observable<PaymentMethod> {
    console.log('*** fetchUserPaymentMethods ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-payment-methods';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<PaymentMethod>(commandUrl, {headers: this.getWsHeader(), responseType: responseType});
  }


  addNewPaymentMethod(card_number: string, owner_firstName: string, owner_lastName: string,
                      expiration_date: string, paymentTypeId: number, cvv2: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-user-payment-method';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.card_number = card_number;
    commandBody.owner_firstName = owner_firstName;
    commandBody.owner_lastName = owner_lastName;
    commandBody.expiration_date = expiration_date;
    commandBody.paymentTypeId = paymentTypeId;
    commandBody.cvv2 = cvv2;
    // console.log('@@@@@addNewPaymentMethod', commandBody);
    // console.log('@@@@@addNewPaymentMethod-CVV2', commandBody.cvv2);

    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  deletePaymentMethod(paymentMethodId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-user-payment-method';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, paymentMethodId].join('/');
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /*** NOTIFICATIONS MANAGEMENT ***/
  fetchNotifications(): Observable<Notification[]> {
    // console.log('*** fetchNotifications ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-notifications';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Notification[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results.map((result) => new Notification(result));
      }));
  }

  /*** HUBS MANAGEMENT ***/
  fetchHubs(): Observable<Hub[]> {
    console.log('*** fetchHubs ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-hubs';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient
      .get<Hub[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results  => {
        return  results.map((result) =>  new  Hub(result));
      }));
  }

  // /*** WARNINGS MANAGEMENT ***/
  // fetchWarnings(): Observable<Warning[]> {
  //   // console.log('*** fetchWarnings ***');
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'get-warnings';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return  this.httpClient
  //       .get<Warning[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
  //       .pipe(map(results  => {
  //         return  results.map((result) =>  new  Warning(result));
  //       }));
  // }

  fetchWarningsPaging(last: number, rowsNumber: number): Observable<Warning[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-warnings-paging';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    let params = new HttpParams();
    params = params.append('rows', rowsNumber.toString());
    if (last) {
      params = params.append('last', last.toString());
    }
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Warning[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType, params: params})
        .pipe(map(results => {
          return results.map((result) => new Warning(result));
        }));
  }

  setWarningAsManaged(warningId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'set-warning-managed';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, warningId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post(completeCommand,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  downloadWarning(warningId: number): any {
    // console.log('*** downloadWarning ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'download-warning-file';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, warningId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient.get(completeCommand, {responseType: 'arraybuffer', headers: this.getWsHeader()} )
        .subscribe(response => this.downLoadFile(response, 'image/jpeg'));
  }

  downloadDocumentImage(documentId: number): any {
    // console.log('*** downloadDocumentImage ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'download-document';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, documentId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient.get(completeCommand, {responseType: 'arraybuffer', headers: this.getWsHeader()} )
      .subscribe(response => this.downLoadFile(response, 'image/jpeg'));
  }

  downloadDocumentImageAdmin(documentId: number): any {
    // console.log('*** downloadDocumentImage ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'download-document-admin';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, documentId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient.get(completeCommand, {responseType: 'arraybuffer', headers: this.getWsHeader()} )
        .subscribe(response => this.downLoadFile(response, 'image/jpeg'));
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type: type});
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }


  /*** SEASON TICKETS MANAGEMENT ***/
  fetchSeasonTicketTypes(): Observable<SeasonTicketType[]> {
    // console.log('*** fetchSeasonTicketTypes ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-season-ticket-types';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient
        .get<SeasonTicketType[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results  => {
          return  results.map((result) =>  new  SeasonTicketType(result));
        }));
  }

  fetchAllSeasonTickets(): Observable<SeasonTicket[]> {
    // console.log('*** fetchSeasonTickets ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-season-tickets';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return  this.httpClient
        .get<SeasonTicket[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results  => {
          return  results.map((result) =>  new  SeasonTicket(result));
        }));
  }

  fetchUserSeasonTicket() {
    // console.log('*** fetchUserSeasonTicket ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-season-tickets';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<UserSubscription>(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  addNewSeasonTicketType(name: string, description: string, isValid: boolean, price: number, duration: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-season-ticket-type';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.name = name;
    commandBody.description = description;
    commandBody.isValid = isValid;
    commandBody.price = price;
    commandBody.duration = duration;
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  addNewSeasonTicket(ticketTypeId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-season-ticket';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.ticketTypeId = ticketTypeId;
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  addSubscriptionFromAdmin(email: string, ticketTypeId: number): Observable<SeasonTicket> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-subscription-from-admin';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.email = email;
    commandBody.ticketTypeId = ticketTypeId;
    return this.httpClient
    .post(commandUrl, commandBody, {headers: this.getWsHeader(), responseType: responseType})
    .pipe(map(val => new SeasonTicket(val)));
  }

  deleteSubscription(ticketId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-subscription';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, ticketId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  sendCommand(deviceId: number, commandTypeId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'send-command';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceId = deviceId;
    commandBody.commandTypeId = commandTypeId;

    console.log(commandBody);
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /***
   * Returns money transactions
   */
  fetchMoneyTransactions(last: number, rowsNumber: number): Observable<TransactionData[]> {
    // console.log('*** fetchMoneyTransactions ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-money-transactions';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    let params = new HttpParams();
    params = params.append('rows', rowsNumber.toString());
    if (last) {
        params = params.append('last', last.toString());
    }
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<TransactionData[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType, params: params})
        .pipe(map(results => {
          return results.map((result) => new TransactionData(result));
        }));
  }

  fetchSingleTransaction(id: number): Observable<TransactionData> {
    this.settingsManager.incrDebugWsCalls();
    const url = this.getBaseWsUrl() + '/transaction/' + id;
    return this.httpClient.get<TransactionData>(url, {headers: this.getWsHeader(), responseType: 'json'});
  }

  /***
   * Returns log
   */
  fetchLog(deviceName: string, t1: string, t2: string): Observable<ArrayBuffer> {
    // console.log('*** fetchLog ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-log';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceName = deviceName;
    commandBody.t1 = t1;
    commandBody.t2 = t2;
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results;
        }));
  }


  sendRentalReport(values) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-report';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.rentalId = values.rentalId;
    commandBody.mark = values.mark;
    commandBody.description = values.description;
    commandBody.faultId = values.faultId;
    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  sendVehicleReport(values, fileObject): Promise<boolean> {
    return new Promise((resolve, reject) => {

      this.settingsManager.incrDebugWsCalls();
      const commandName = 'send-vehicle-report';
      const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
      const responseType = this.getResponseTypeByCommand(commandName);
      const headers = this.getFormMultipartHeader();

      // 'data' fields
      this.formData = new FormData();
      const dataJson = {
        'deviceName': values.deviceName,
        'description': values.msg_text,
        'warningTypeId': values.failures
      };
      const jsonString = JSON.stringify(dataJson);
      this.formData.append('data', jsonString);
      console.log('formData: ', this.formData.getAll('data'));

      if (fileObject == null) {

        // Sending the form without the file
        return this.sendMultipartForm(commandUrl, headers, responseType, resolve);

      } else {

        // 'streamfile' field
        this.loadTempFile(fileObject)
            .then((res) => {

              // Sending the form with the file
              this.formData.append('streamfile', this.tmpBlobFile, fileObject.filename);
              console.log('streamfile: ', this.formData.getAll('streamfile'));
              return this.sendMultipartForm(commandUrl, headers, responseType, resolve);

            }, (err) => {
              resolve(false);
            });
      }
    });
  }

  private sendMultipartFormWithPhoto(commandUrl, headers, responseType) {
    return this.httpClient
      .post(commandUrl,
        this.formData,
        {headers: headers, responseType: responseType});
  }


  private sendMultipartForm(commandUrl, headers, responseType, resolve) {
    return this.httpClient
      .post(commandUrl,
        this.formData,
        {headers: headers, responseType: responseType})
      .subscribe(data => {
        resolve(true);
      }, err => {
        console.log('ERR-VEHICLE-REPORT:', err.message);
        resolve(false);
      });
  }

  loadTempFile(fileObject): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.file.readAsArrayBuffer(fileObject.directory, fileObject.filename)
        .then((res) => {

          this.tmpBlobFile = new Blob([res], {type: fileObject.contentType});
          resolve(true);

        }, (err) => {
          reject(false);
        });
    });
  }

  /*** RENTAL REPORTS MANAGEMENT ***/
  fetchReports(): Observable<Report[]> {
    console.log('*** fetchReports ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-all-reports';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Report[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          console.log(results);
          return results.map((result) => new Report(result));
        }));
  }

  fetchReportStats(): Observable<ReportStats> {
    // console.log('*** fetchReportStats ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-report-stats';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<ReportStats>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          console.log(results);
          return new ReportStats(results);
        }));
  }

  executeReversal(values: {parentId: number, transactionId: number, amount: number, reason: string}) {
    // console.log('@@@@@@@@ executeReversal-values:', values);
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'execute-reversal';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.parentId = values.parentId;
    commandBody.transactionId = values.transactionId;
    commandBody.amount = values.amount;
    commandBody.reason = values.reason;
    console.log('@@@@@@@@ executeReversal: ', completeCommandUrl, commandBody);
    return this.httpClient.post(completeCommandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  executeCharge(values: {parentId: number, userId: number, amount: number, reason: string}) {
    // console.log('@@@@@@@@ executeCharge-values:', values);
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'execute-charge';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const responseType = this.getResponseTypeByCommand(commandName);
    const commandBody = {
      parentId: values.parentId,
      userId: values.userId,
      amount: values.amount,
      reason: values.reason
    };

    return this.httpClient.post(completeCommandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  /*
   * Card blacklist management
   */
  fetchBlacklistedCards(): Observable<BlacklistedCard[]> {
    // console.log('*** fetchBlacklistedCards ***');
    const commandName = 'get-card-blacklist';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<BlacklistedCard[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new BlacklistedCard(result));
        }));
  }

  blacklistGetDetails(id: number): Observable<BlacklistedCard> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'blacklist', 'info', id].join('/');
    return this.httpClient.get<BlacklistedCard>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  removeCardFromBlacklist(blackListedCardId: number) {
    const commandName = 'delete-card-from-blacklist';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, blackListedCardId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /*
   * Get system statistics
   */
  fetchStatistics(): Observable<Statistics> {
    // console.log('*** fetchStatistics ***');
    const commandName = 'get-statistics';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Statistics>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          console.log(results);
          return new Statistics(results);
        }));
  }

  /*
   * Get system statistics
   */
  fetchHistoryStatistics(start, end, timeUnit): Observable<HistoryStatistics> {
    // console.log('*** fetchHistoryStatistics ***');
    const commandName = 'get-statistics-history-time';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.start = start;
    commandBody.end = end;
    commandBody.timeUnit = timeUnit;
    return this.httpClient
      .post<HistoryStatistics>(commandUrl, commandBody, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return new HistoryStatistics(results);
      }));
  }

  /*
   * Get system statistics
   */
  fetchOtherTxsHistoryStatistics(start, end, timeUnit): Observable<TransactionStatistics[]> {
    // console.log('*** fetchHistoryStatistics ***');
    const commandName = 'get-other-txs-statistics-history-time';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.start = start;
    commandBody.end = end;
    commandBody.timeUnit = timeUnit;
    return this.httpClient
      .post<TransactionStatistics[]>(commandUrl, commandBody, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        return results;
      }));
  }

  /*
   * Get system statistics
   */
  getTimeStatistics(t1, t2): Observable<TimeStatistics> {
    // console.log('*** fetchStatistics ***');
    const commandName = 'get-statistics-time';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.t1 = t1;
    commandBody.t2 = t2;
    return this.httpClient
        .post<TimeStatistics>(commandUrl, commandBody, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return new TimeStatistics(results);
        }));
  }

  /*
   * Get users rentals statistics
   */
  fetchTotalRentalsPaging(last: number, rowsNumber: number): Observable<User[]> {
    // console.log('*** fetchTotalRentalsPaging ***');
    const commandName = 'get-statistics-total-rentals';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    let params = new HttpParams();
    params = params.append('rows', rowsNumber.toString());
    if (last) {
      params = params.append('last', last.toString());
    }
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<User[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType, params: params})
        .pipe(map(results => {
          return results.map((result) => new User(result));
        }));
  }

  /*
   * Get faults from db
   */
  fetchFaults(): Observable<Fault[]> {
    // console.log('*** fetchFaults ***');
    const commandName = 'get-faults';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Fault[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Fault(result));
        }));
  }

  newFault(faultObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'fault'].join('/');
    const commandBody = {
      deviceName: faultObject.deviceName,
      description: faultObject.description
    };
    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: 'json'});
  }

  addNewCommentToFault(faultId: number, comment: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-comment-to-fault';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.id = faultId;
    commandBody.comment = comment;
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  closeFault(faultId: number, price: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'close-fault';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.id = faultId;
    commandBody.price = price;
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  getUserPromotion(promotionCode: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-user-promotion';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.promotionCode = promotionCode;
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchValidUserPromotion(): Observable<UserPromotion> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-valid-user-promotion';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<UserPromotion>(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  deleteValidUserPromotion(promotionId: number) {
    const commandName = 'delete-valid-user-promotion';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = [commandUrl, promotionId].join('/');
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  getPromotions(): Observable<Promotion[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-promotions';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Promotion[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Promotion(result));
        }));
  }

  newPromotion(promotionObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'new-promotion';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.code = promotionObject.code;
    commandBody.name = promotionObject.name;
    commandBody.cityId = promotionObject.cityId;
    commandBody.startsAt = promotionObject.startsAt;
    commandBody.expiresAt = promotionObject.expiration_date;
    commandBody.description = promotionObject.description;
    commandBody.type = promotionObject.type.value;
    commandBody.value = promotionObject.value;
    commandBody.firstRentalOnly = promotionObject.firstRentalOnly;
    commandBody.allCities = promotionObject.allCities;
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  editPromotion(promotionObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-promotion';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.name = promotionObject.name;
    commandBody.description = promotionObject.description;
    commandBody.promotionId = promotionObject.latitude;
    commandBody.expiresAt = promotionObject.expiresAt;
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  getPricingPlans(): Observable<PricingPlan[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-pricing-plan';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<PricingPlan[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new PricingPlan(result));
        }));
  }

  newPricingPlan(pricingPlanObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'new-pricing-plan';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.name = pricingPlanObject.name;
    commandBody.description = pricingPlanObject.description;
    commandBody.discount_on_active_fee = pricingPlanObject.discount_on_active_fee;
    commandBody.discount_on_break_fee = pricingPlanObject.discount_on_break_fee;
    commandBody.discount_on_unlock_fee = pricingPlanObject.discount_on_unlock_fee;
    commandBody.discount_on_booking_fee = pricingPlanObject.discount_on_booking_fee;
    commandBody.discount_on_season_ticket_fee = pricingPlanObject.discount_on_season_ticket_fee;
    commandBody.is_valid = true;
    commandBody.max_free_unlock_daily = pricingPlanObject.max_free_unlock_daily;
    commandBody.free_unlock_threshold = pricingPlanObject.free_unlock_threshold;
    commandBody.monday = pricingPlanObject.monday;
    commandBody.tuesday = pricingPlanObject.tuesday;
    commandBody.wednesday = pricingPlanObject.wednesday;
    commandBody.thursday = pricingPlanObject.thursday;
    commandBody.friday = pricingPlanObject.friday;
    commandBody.saturday = pricingPlanObject.saturday;
    commandBody.sunday = pricingPlanObject.sunday;
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  editPricingPlan(id, pricingPlanObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'edit-pricing-plan';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), id].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.name = pricingPlanObject.name;
    commandBody.description = pricingPlanObject.description;
    commandBody.discount_on_active_fee = pricingPlanObject.discount_on_active_fee;
    commandBody.discount_on_break_fee = pricingPlanObject.discount_on_break_fee;
    commandBody.discount_on_unlock_fee = pricingPlanObject.discount_on_unlock_fee;
    commandBody.discount_on_booking_fee = pricingPlanObject.discount_on_booking_fee;
    commandBody.discount_on_season_ticket_fee = pricingPlanObject.discount_on_season_ticket_fee;
    commandBody.is_valid = true;
    commandBody.max_free_unlock_daily = pricingPlanObject.max_free_unlock_daily;
    commandBody.free_unlock_threshold = pricingPlanObject.free_unlock_threshold;
    commandBody.monday = pricingPlanObject.monday;
    commandBody.tuesday = pricingPlanObject.tuesday;
    commandBody.wednesday = pricingPlanObject.wednesday;
    commandBody.thursday = pricingPlanObject.thursday;
    commandBody.friday = pricingPlanObject.friday;
    commandBody.saturday = pricingPlanObject.saturday;
    commandBody.sunday = pricingPlanObject.sunday;
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  getPricingPlanTimeSlot(id): Observable<TimeSlot[]> {
    this.settingsManager.incrDebugWsCalls();
    const command = [this.getBaseWsUrl(), 'time-slot/by-pricing-plan', id].join('/');

    return this.httpClient
      .get<TimeSlot[]>( command, {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map( result => new TimeSlot(result))}));
  }

  deleteTimeSlot(id) {
    this.settingsManager.incrDebugWsCalls();
    const command = [this.getBaseWsUrl(), 'time-slot', id].join('/');
    return this.httpClient
      .delete( command, {headers: this.getWsHeader(), responseType: 'json'});
  }

  newTimeSlot(timeslot): Observable<TimeSlot> {
    this.settingsManager.incrDebugWsCalls();
    const command = [this.getBaseWsUrl(), 'time-slot'].join('/');
    return this.httpClient
      .post<TimeSlot>( command, timeslot, {headers: this.getWsHeader(), responseType: 'json'});
  }

  assignPlanToUser(pricingPlanName, pricingPlanId) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'assign-plan-to-user';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    console.log('Assign plan to user', commandUrl, pricingPlanName);
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.email = pricingPlanName;
    commandBody.pricingPlanId = pricingPlanId;
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  confirmTerms(termsObject) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'save_terms';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.privacy_conditions_accepted = termsObject.privacy_conditions_accepted;
    commandBody.usage_conditions_accepted = termsObject.usage_conditions_accepted;
    commandBody.unfair_terms_accepted = termsObject.unfair_terms_accepted;
    return this.httpClient.patch(commandUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  resetUsersTerms() {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'reset-terms';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  resetUserTermsById(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'reset-terms';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), userId].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.patch(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  resetUserTempPricingPlanById(userId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'reset-temp-pricing-plan';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), userId].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(commandUrl, {headers: this.getWsHeader(), responseType: responseType});
  }

  getSystemSettings(): Observable<Setting[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-settings';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Setting[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Setting(result));
        }));
  }

  closeRentalWithPhoto(deviceId: number, coordinates: any, fileObject: any): Observable <any> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'close-rental-with-photo';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    const headers = this.getFormMultipartHeader();
    const context = this;
    context.tmpBlobFile = null;
    // 'data' fields
    this.formData = new FormData();

    const cords = {
      'latitude': coordinates.latitude,
      'longitude': coordinates.longitude,
    };
    const jsonCoords = JSON.stringify(cords);
    this.formData.append('data', jsonCoords);
    console.log('formData: ', this.formData.getAll('data'));

    return from(context.file.readAsArrayBuffer(fileObject.directory, fileObject.filename))
      .pipe(mergeMap(dataFile => {
        console.log('lettura file 2');
        context.tmpBlobFile = new Blob([dataFile], {type: fileObject.contentType});

        // Sending the form with the file
        context.formData.append('streamfile', context.tmpBlobFile, fileObject.filename);

        return  context.httpClient.post(commandUrl, context.formData,
          {headers: headers,
            responseType: responseType});
      }));


  }


  sendRentalPhoto(rentalId: number, fileObject: any): Observable <any> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'add-photo';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    const headers = this.getFormMultipartHeader();
    const context = this;
    context.tmpBlobFile = null;
    // 'data' fields
    this.formData = new FormData();
    // console.log('lettura file 1-fileObject', fileObject);

    const cords = {
      'rentalId': rentalId,
    };
    const jsonCoords = JSON.stringify(cords);
    this.formData.append('data', jsonCoords);

    return from(context.file.readAsArrayBuffer(fileObject.directory, fileObject.filename))
        .pipe(mergeMap(dataFile => {
          context.tmpBlobFile = new Blob([dataFile], {type: fileObject.contentType});

          // Sending the form with the file
          context.formData.append('streamfile', context.tmpBlobFile, fileObject.filename);

          return  context.httpClient.post(commandUrl, context.formData,
              {headers: headers,
                responseType: responseType});
        }));


  }

  fetchOperations(): Observable<Operation[]> {
    // console.log('*** fetchTrips ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-own-operations';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Operation[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Operation(result));
        }));
  }

  fetchOperationsAdmin(): Observable<Operation[]> {
    // console.log('*** fetchTrips ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-operations';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<Operation[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Operation(result));
        }));
  }

  fetchDeviceDataOperator(deviceName): Observable<OperationDevice> {
    this.settingsManager.incrDebugWsCalls();
    console.log('*** fetchDeviceData: STATO MEZZO ***');
    const commandName = 'get-device-data-operator';
    const commandUrl = [this.getBaseWsUrl(),
      this.getMethodByCommand(commandName),
      deviceName
    ].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<OperationDevice>(commandUrl,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchDeviceLog(deviceName: string): Observable<DeviceLog[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-device-log-op';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), deviceName].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<DeviceLog[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new DeviceLog(result));
        }));
  }

  startOperation(deviceName: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'start-operation';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceName = deviceName;

    console.log(commandBody);
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  stopOperation(deviceName: string, stateId: number, latitude: number, longitude: number, comment: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'stop-operation';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceName = deviceName;
    commandBody.stateId = stateId;
    commandBody.latitude = latitude;
    commandBody.longitude = longitude;
    commandBody.comment = comment;

    console.log(commandBody);
    return this.httpClient.post(addDeviceUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  newOperationRequest(faultObject) {
    const commandUrl = [this.getBaseWsUrl(), 'operation/new-request'].join('/');
    const commandBody =  {
      'deviceName': faultObject.deviceName,
      'description':faultObject.description,
    };
    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: 'json'});
  }

  /*  FETCH Developers and Testers */

  fetchDevelopersNames() {
    console.log('*** fetchDevNames ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-developers-names';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchTestersNames() {
    console.log('*** fetchTestersNames ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-testers-names';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get(commandUrl,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  // fetchUnmanagedAlarms(): Observable<AlarmActive[]> {
  //   // console.log('*** fetchTrips ***');
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'get-alarms-unmanaged';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   // console.log(commandUrl);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return this.httpClient
  //       .get<AlarmActive[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
  //       .pipe(map(results => {
  //         console.log(results);
  //         return results.map((result) => new AlarmActive(result));
  //       }));
  // }

  // fetchManagedAlarms(): Observable<AlarmActive[]> {
  //   // console.log('*** fetchTrips ***');
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandName = 'get-alarms-managed';
  //   const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
  //   // console.log(commandUrl);
  //   const responseType = this.getResponseTypeByCommand(commandName);
  //   return this.httpClient
  //       .get<AlarmActive[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
  //       .pipe(map(results => {
  //         console.log(results);
  //         return results.map((result) => new AlarmActive(result));
  //       }));
  // }

  fetchAlarms(): Observable<AlarmActive[]> {
    // console.log('*** fetchTrips ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-alarms';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<AlarmActive[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          console.log(results);
          return results.map((result) => new AlarmActive(result));
        }));
  }

  setAlarmAsSeen(alarmId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'set-alarm-as-seen';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), alarmId].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  setAlarmAsClosed(alarmId: number, note: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'set-alarm-as-closed';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), alarmId].join('/');
    const completeCommandUrl = commandUrl;
    const commandBody = this.getTemplateBodyByCommand(commandName);
    commandBody.note = note;
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post(completeCommandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  /****************************
   * Bookings
   */
  bookDevice(deviceName: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'start-booking';
    const addDeviceUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.deviceName = deviceName;
    // console.log(commandBody);
    return this.httpClient.post(addDeviceUrl,
      commandBody,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchActiveBookings(): Observable<Booking[]> {
    // console.log('*** fetchActiveRentals ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-active-bookings';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
      .get<Booking[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
      .pipe(map(results => {
        console.log(results);
        return results.map((result) => new Booking(result));
      }));
  }

  deleteBooking(deviceName: string) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-booking';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = commandUrl;
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  deleteBookingAdmin(bookingId: number) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'delete-booking-admin';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName), bookingId].join('/');
    const completeCommand = commandUrl;
    console.log(completeCommand);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.delete(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  getBookingStatus(deviceId: string) {
    console.log('*** getBookingStatus ***');
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'booking-status';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const completeCommand = commandUrl;
    console.log('--->', commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<BookingStatus>(completeCommand,
      {headers: this.getWsHeader(), responseType: responseType});
  }

  checkHubExistence(cityId, latitude, longitude): Observable<Hub>  {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'check-hub-exists';
    const apiUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.latitude = latitude;
    commandBody.longitude = longitude;
    commandBody.cityId = cityId;

    console.log(commandBody);
    return this.httpClient
      .post<Hub>(apiUrl, commandBody, {headers: this.getWsHeader(), responseType: responseType});
  }

  listAllPenalties(): Observable<PenaltyRegistry[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'payment-penalty-all';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<PenaltyRegistry[]>(
      commandUrl, {
        headers: this.getWsHeader(),
        responseType: responseType
      }
    );
  }

  getListSubscription(): Observable<SeasonTicketType[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'subscription-list';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<SeasonTicketType[]>(commandUrl, { headers: this.getWsHeader(), responseType: responseType});
  }

  makeDebitSanctions(obj: any): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'payment-penalty';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.post(
      commandUrl, obj, {
        headers: this.getWsHeader(),
        observe: 'response',
        responseType: responseType
      }
    );
  }

  getListAlarmTypes(): Observable<AlarmType[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'get-alarm-types-list';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient.get<AlarmType[]>(
      commandUrl, {
        headers: this.getWsHeader(),
        responseType: responseType
      }
    );
  }

  subscriptionEnable(id: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'packet', id, 'enable'].join('/');
    return this.httpClient.put(
      commandUrl,
      null,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  subscriptionDisable(id: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'packet', id, 'disable'].join('/');
    return this.httpClient.put(
      commandUrl,
      null,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }


  /** PUSH NOTIFICATIONS **/

  sendFcmNotification(notification: FcmMessage) {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'send-fcm-message';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    const commandBody = this.getTemplateBodyByCommand(commandName);
    const responseType = this.getResponseTypeByCommand(commandName);
    commandBody.topic = notification.topicId;
    commandBody.title = notification.title;
    commandBody.description = notification.description;

    return this.httpClient.post(commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType});
  }

  fetchFcmNotifications(): Observable<FcmMessage[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'fetch-fcm-messages';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<FcmMessage[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new FcmMessage(result));
        }));
  }

  fetchFcmTopics(): Observable<FcmTopic[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandName = 'fetch-fcm-topics';
    const commandUrl = [this.getBaseWsUrl(), this.getMethodByCommand(commandName)].join('/');
    // console.log(commandUrl);
    const responseType = this.getResponseTypeByCommand(commandName);
    return this.httpClient
        .get<FcmTopic[]>(commandUrl, {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new FcmTopic(result));
        }));
  }

  subscriptionCreate(obj: SeasonTicketType): Observable<SeasonTicketType> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'packet'].join('/');
    return this.httpClient.post<SeasonTicketType>(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  subscriptionEdit(id: number, obj: SeasonTicketType): Observable<SeasonTicketType> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'packet', id].join('/');
    return this.httpClient.put<SeasonTicketType>(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteGetAll(): Observable<Ente[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente'].join('/');
    return this.httpClient.get<Ente[]>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteGetDetails(id: number): Observable<Ente> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id].join('/');
    return this.httpClient.get<Ente>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteCreate(obj: Ente): Observable<Ente>  {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente'].join('/');
    return this.httpClient.post<Ente>(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteSave(id: number, obj: Ente): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id].join('/');
    return this.httpClient.put(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteDelete(id: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id].join('/');
    return this.httpClient.delete<any>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  enteAssignUser(id: number, emailUser: string): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id, 'assign-user'].join('/');
    return this.httpClient.post<any>(
      commandUrl,
      {
        email: emailUser
      },
      { headers: this.getWsHeader(), responseType: 'json', observe: 'response' }
    );
  }

  enteAssignDevice(id: number, nameDevice: string): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id, 'assign-device'].join('/');
    return this.httpClient.post<any>(
      commandUrl,
      {
        name: nameDevice,
      },
      { headers: this.getWsHeader(), responseType: 'json', observe: 'response' }
    );
  }

  enteDisassociateUser(id: number, idUser: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id, 'disassociate-user'].join('/');
    return this.httpClient.post<any>(
      commandUrl,
      {
        userId: idUser
      },
      { headers: this.getWsHeader(), responseType: 'json', observe: 'response' }
    );
  }

  enteDisassociateDevice(id: number, idDevice: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'ente', id, 'disassociate-device'].join('/');
    return this.httpClient.post<any>(
      commandUrl,
      {
        deviceId: idDevice,
      },
      { headers: this.getWsHeader(), responseType: 'json', observe: 'response' }
    );
  }

  companyGetAll(): Observable<Company[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company'].join('/');
    return this.httpClient.get<Company[]>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyCreate(obj: Company): Observable<Company> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company'].join('/');
    return this.httpClient.post<Company>(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyEdit(id: number, obj: Company): Observable<Company> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id].join('/');
    return this.httpClient.put<Company>(
      commandUrl,
      obj,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyDelete(id: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id].join('/');
    return this.httpClient.delete(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyGetDetails(id: number): Observable<Company> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id].join('/');
    return this.httpClient.get<Company>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyGetUsers(id: number): Observable<User[]> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id, 'users'].join('/');
    return this.httpClient.get<User[]>(
      commandUrl,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyDisassociateUsers(id: number, listUserId: number[]): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id, 'disassociateUsers'].join('/');
    return this.httpClient.post(
      commandUrl,
      { users: listUserId },
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  companyDisassociateAllUsers(id: number): Observable<any> {
    this.settingsManager.incrDebugWsCalls();
    const commandUrl = [this.getBaseWsUrl(), 'company', id, 'disassociateAllUsers'].join('/');
    return this.httpClient.post(
      commandUrl,
      null,
      { headers: this.getWsHeader(), responseType: 'json' }
    );
  }

  addNewVoucher(value: number,
                voucherTypeId: number,
                cityId: number,
                expiration_date: string,
                numVouchers: number,
                customization: string): Observable<Voucher[]> {
    this.settingsManager.incrDebugWsCalls();
    const command = 'voucher/create-multiple';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    const commandBody =  {
      value: value,
      voucherTypeId: voucherTypeId,
      cityId: cityId,
      expiration_date: new Date(expiration_date),
      counter: numVouchers,
      customization: customization
    };
    const responseType = 'json';

    return this.httpClient.post<Voucher[]>(
        commandUrl,
        commandBody,
        {headers: this.getWsHeader(), responseType: responseType})
        .pipe(map(results => {
          return results.map((result) => new Voucher(result));
        }));
  }

  fetchPositionsByCity(cityId: number): Observable<Position[]> {
    const command = 'position/by-city';
    const commandUrl = [this.getBaseWsUrl(), command, cityId].join('/');
    return this.httpClient.get<Position[]>(
      commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'})
      .pipe(map(results => {
        return results.map((result) => new Position(result));
      }))
  }

  getAssignedCities(): Observable<City[]> {
    const command = 'user/assigned-cities';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    return this.httpClient.get<City[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json'});
  }

  scooterRegistrationGetAll(loadAll: boolean = false): Observable<ScooterRegistrationRequests[]> {
    const command = 'driver-data';
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    let objParams: any = null;
    if (!loadAll) {
      objParams = { rows: 2000 };
    }
    return this.httpClient.get<ScooterRegistrationRequests[]>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'json', params: objParams}).pipe(
        map(results => results.map((result) => new ScooterRegistrationRequests(result)))
      );
  }

  scooterRegistrationEnable(idRequest: number): Observable<void> {
    const command = `driver-data/accept/${idRequest}`;
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    return this.httpClient.post<void>(commandUrl, null,
      {headers: this.getWsHeader(), responseType: 'json'}
    );
  }

  scooterRegistrationDisable(idRequest: number): Observable<void> {
    const command = `driver-data/reject/${idRequest}`;
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    return this.httpClient.post<void>(commandUrl, null,
      {headers: this.getWsHeader(), responseType: 'json'}
    );
  }

  scooterRegistrationShowImage(idRequest: number, type: string): Observable<Blob> {
    const command = `driver-data/${idRequest}/image/${type}`;
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    return this.httpClient.get<Blob>(commandUrl,
      {headers: this.getWsHeader(), responseType: 'blob' as 'json'}
    );
  }

  getRentalPoisitions(rentalId: number): Observable<Position[]> {
    const command = 'position/getByRentalId/' + rentalId;
    const commandUrl = [this.getBaseWsUrl(), command].join('/');
    return this.httpClient.get<Position[]>(commandUrl,
        {headers: this.getWsHeader(), responseType: 'json'});
  }

  importCustomers(file: any, importType: string): Observable<any> {
    const commandUrl = [this.getBaseWsUrl(), 'importsystem/customers'].join('/');

    const formData = new FormData();
    formData.append('streamfile', file, file.name);
    // Se vuoi aggiungere altri dati nel corpo:
    const bodyData = {
      importType: importType
    };
    const jsonString = JSON.stringify(bodyData);
    formData.append('data', jsonString);

    return this.httpClient.post<any>(commandUrl,  formData,
        {headers: this.getFormMultipartHeader(), responseType: 'json'});
  }

  saveDriverClasses(documentid: number, driverLicenseClasses: number) {
    const commandUrl = [this.getBaseWsUrl(), 'driver-metadata/class'].join('/');

    const commandBody =  {
      documentId: documentid,
      convertedEuropeanClass: driverLicenseClasses
    };

    return this.httpClient.post<any>(commandUrl, commandBody, {headers: this.getFormMultipartHeader(), responseType: 'json'});
  }

  // fetchModels(): Observable<Model[]> {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandUrl = [this.getBaseWsUrl(), 'model'].join('/');
  //   return this.httpClient
  //       .get<Model[]>(commandUrl, {headers: this.getWsHeader(), responseType: 'json'})
  //       .pipe(map(results => {
  //         return results.map((result) => new Model(result));
  //       }));
  // }

  // downloadModelImage(modelId: number): any {
  //   const command = `model/image/${modelId}`;
  //   const commandUrl = [this.getBaseWsUrl(), command].join('/');
  //   return  this.httpClient.get(commandUrl, {responseType: 'arraybuffer', headers: this.getWsHeader()} )
  //       .subscribe(response => this.downLoadFile(response, 'image/jpeg'));
  // }

  // downloadModelImageAsBlob(modelId: number): Observable<Blob> {
  //   const command = `model/image/${modelId}`;
  //   const commandUrl = [this.getBaseWsUrl(), command].join('/');
  //   return this.httpClient.get<Blob>(commandUrl,
  //       {headers: this.getWsHeader(), responseType: 'blob' as 'json'}
  //   );
  // }

  // addNewModel(model: Model, file:any): Observable<any> {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandUrl = [this.getBaseWsUrl(), 'model'].join('/');

  //   const formData = new FormData();
  //   if (file){
  //     formData.append('streamfile', file, file.name);
  //   }
  //   const commandBody = {
  //     name: model.name,
  //     description: model.description,
  //     active_fee: model.active_fee,
  //     break_fee: model.break_fee,
  //     unlock_fee: model.unlock_fee,
  //     booking_fee: model.booking_fee,
  //   };
  //   formData.append('data', JSON.stringify(commandBody));

  //   return this.httpClient.post<any>(commandUrl, formData,
  //       {headers: this.getFormMultipartHeader(), responseType: 'json'});
  // }

  // editModel(model: Model, file=null): Observable<any> {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandUrl = [this.getBaseWsUrl(), 'model', model.id].join('/');

  //   const formData = new FormData();
  //   if (file) {
  //     formData.append('streamfile', file, file.name);
  //   }
  //   const commandBody = {
  //     name: model.name,
  //     description: model.description,
  //     active_fee: model.active_fee,
  //     break_fee: model.break_fee,
  //     unlock_fee: model.unlock_fee,
  //     booking_fee: model.booking_fee,
  //   };
  //   formData.append('data', JSON.stringify(commandBody));

  //   return this.httpClient.patch(commandUrl, formData,
  //       {headers: this.getFormMultipartHeader(), responseType: 'json'});
  // }

  // deleteModel(zoneId: number) {
  //   this.settingsManager.incrDebugWsCalls();
  //   const commandUrl = [this.getBaseWsUrl(), 'model'].join('/');
  //   const completeCommand = [commandUrl, zoneId].join('/');
  //   return this.httpClient.delete(completeCommand,
  //       {headers: this.getWsHeader(), responseType: 'json'});
  // }

}
