import { Component, Input } from '@angular/core';
import { LicenseClassFlags } from 'src/app/classes/ClassesManager';
import { WsmanagerService } from 'src/app/services/wsmanager.service';

@Component({
  selector: 'app-license-class-form',
  templateUrl: './license-class-form.component.html',
  styleUrls: ['./license-class-form.component.scss'],
})
export class LicenseClassFormComponent {

  @Input() classFlags: number;

  @Input() documentId: number;

  classOptions: Array<{ key: number, label: string, selected: boolean }> = [];

  classRes: number = 0;

  constructor(private ws: WsmanagerService) { }

  ngOnChanges() {
    this.initializeClassFlags();
    this.loadSelectedClasses();
  }

  updateClasses(inputArray: number[]): void {
    this.classRes = inputArray.reduce((accumulator, currentValue) => {
      return accumulator | currentValue;
    }, 0);
  }

  loadSelectedClasses(): void {
    this.classOptions.forEach(category => {
      category.selected = (this.classFlags & category.key) === category.key;
    });
  }

  initializeClassFlags(): void {
    this.classOptions = Object.keys(LicenseClassFlags)
      .filter(Number)
      .map(key => ({
        key: Number(key),
        label: LicenseClassFlags[key],
        selected: false
      }));
  }

  saveClasses(): void {
    this.ws.saveDriverClasses(this.documentId ,this.classRes).subscribe();
  }

}
