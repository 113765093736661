import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CategoryFlags } from 'src/app/classes/DeviceCategoryFlags';

@Component({
  selector: 'app-categories-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesFormComponent implements OnChanges, OnDestroy {

  @Input() categoryFlags: FormControl;

  categoryOptions: Array<{key: number,label: string, selected: boolean}> = [];

  ngOnChanges() {
    this.initializeCategoryFlags();
    this.loadSelectedCategories();
  }

  ngOnDestroy() {
    this.initializeCategoryFlags();
  }

  updateCategories(inputArray: number[]): void{
    const flagsArray = inputArray;

    let flaggedEnumVal = 0;
    flagsArray.forEach(flag => {
      flaggedEnumVal += +flag;
    });
    this.categoryFlags.patchValue(flaggedEnumVal)
  }

  loadSelectedCategories(): void {
    this.categoryOptions.forEach(category => {
      category.selected = (this.categoryFlags.value & category.key) > 0;
    });
  }

  initializeCategoryFlags(): void {
    this.categoryOptions = Object.keys(CategoryFlags)
    .filter(Number)
    .map(key => ({
      key: Number(key),
      label: CategoryFlags[key],
      selected: false
    }));
  }

}
